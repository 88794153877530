import styled, { css } from "styled-components";
import {
  Col as DefaultCol
} from "../Styles/default";

import { Sizes } from "./../Styles/config";

const NAV_HEIGHT = Sizes.Header.NAV_HEIGHT;
const PUBLIC_URL = process.env.PUBLIC_URL;

export const ButtonBudget = styled.div`
  width: 100%;
  margin: auto;
  padding: 30px 15px;
  border-radius: 5px;
  a,
  button {
    width: 100% !important;
    font-size: 28px !important;
    font-weight: bold !important;
  }
`;

export const Row = styled.div`
  padding: 0;
`;

export const RowBox = styled.div`
  padding: 30px;
  margin: 25px 0;
  background: ${props => props.background || "#fff"};
  border-radius: 30px;
`;

export const Col = styled.div`
  padding: 0;
  margin: 0 auto;
  @media screen and (max-width: ${Sizes.md}px) {
    margin-bottom: 15px;
  }
`;

export const ColBox = styled.div`
  padding: 15px;
  margin: auto;
  @media screen and (max-width: ${Sizes.md}px) {
    margin-bottom: 15px;
  }
`;

export const ColFlex = styled(DefaultCol)`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100%;
  @media screen and (max-width: ${Sizes.md}px) {
    margin-bottom: 15px;
  }
`;

export const ColTitle = styled.div`
  padding: 100px 0 0 0;
  margin:  0 0 15px 0;
  @media screen and (max-width: ${Sizes.lg}px) {
    padding: 80px 0 0 0;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    padding: 64px 0 0 0;
  }
`;

export const ColSketchfab = styled.div`
  padding: 0;
  height: min(calc(75vh - ${NAV_HEIGHT}px), 624px);
  position: relative;
  overflow: hidden;
`;

export const SectionImac = styled.div`
  width: 1892px;
  height: 1511px;
  pointer-events: none;
  will-change: transform;
  display: block;
  z-index: 1;

  ${props => {
    const align = props.align || "left";
    if (align === "left") {
      return css`
        float: right;
        transform: translateX(${Sizes.Imac.large.alignRight}px) scale(${Sizes.Imac.large.scale});
      `;
    }
    else if (align === "right") {
      return css`
        float: left;
        transform: translateX(${Sizes.Imac.large.alignLeft}px) scale(${Sizes.Imac.large.scale});
      `;
    }
    else {
      return css`
        transform: translateX(0) scale(1);
      `;
    }
  }}

  & div,
  &:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &:before {
    content: "";
    width: 2124px;
    height: 1809px;
    background-image: url("${PUBLIC_URL}/images/mac/imac/large/shadow.png");
    background-size: 2124px 1809px;
    background-repeat: no-repeat;
    top: -30px;
    left: -15px;
  }

  @media screen and (max-width: ${Sizes.lg}px) {
    width: 1496px;
    height: 1195px;

    ${props => {
      const align = props.align || "left";
      if (align === "left") {
        return css`
          transform: translateX(${Sizes.Imac.medium.alignRight}px) scale(${Sizes.Imac.medium.scale});
        `;
      }
      else if (align === "right") {
        return css`
          transform: translateX(${Sizes.Imac.medium.alignLeft}px) scale(${Sizes.Imac.medium.scale});
        `;
      }
      else {
        return css`
          transform: translateX(0) scale(1);
        `;
      }
    }}

    &:before {
      width: 1728px;
      height: 1472px;
      background-image: url("${PUBLIC_URL}/images/mac/imac/medium/shadow.png");
      background-size: 1728px 1472px;
      top: -60px;
      left: -50px;
    }
  }

  @media screen and (max-width: ${Sizes.md}px) {
    width: 770px;
    height: 615px;

    ${props => {
      const align = props.align || "left";
      if (align === "left") {
        return css`
          transform: translateX(${Sizes.Imac.small.alignRight}px) scale(${Sizes.Imac.small.scale});
        `;
      }
      else if (align === "right") {
        return css`
          transform: translateX(${Sizes.Imac.small.alignLeft}px) scale(${Sizes.Imac.small.scale});
        `;
      }
      else {
        return css`
          transform: translateX(0) scale(1);
        `;
      }
    }}

    &:before {
      background-image: none !important;
    }
  }
`;

export const SectionImacBg = styled.div`
  pointer-events: none;
  z-index: 3 !important;
  width: 1892px;
  height: 1511px;
  background-image: url("${PUBLIC_URL}/images/mac/imac/large/bg.jpg");
  background-size: 1892px 1511px;
  background-repeat: no-repeat;
  background-position: center;
  mask-image: url("${PUBLIC_URL}/images/mac/imac/large/mask.svg");
  mask-size: 1892px 1511px;
  mask-repeat: no-repeat;
  mask-position: center;

  @media screen and (max-width: ${Sizes.lg}px) {
    width: 1496px;
    height: 1195px;
    background-image: url("${PUBLIC_URL}/images/mac/imac/medium/bg.jpg");
    background-size: 1496px 1195px;
    mask-image: url("${PUBLIC_URL}/images/mac/imac/medium/mask.svg");
    mask-size: 1496px 1195px;
  }

  @media screen and (max-width: ${Sizes.md}px) {
    width: 770px;
    height: 615px;
    background-image: url("${PUBLIC_URL}/images/mac/imac/small/bg.jpg");
    background-size: 770px 615px;
    mask-image: none !important;
  };
`;

export const SectionImacWindow = styled.div`
  width: 945px;
  height: 531px;
  background-image: ${props => props.bg || "transparent"};
  background-size: 945px 531px;
  background-repeat: no-repeat;
  z-index: 4 !important;
  margin-left: 44px;
  margin-top: 45px;

  @media screen and (max-width: ${Sizes.lg}px) {
    width: 636px;
    height: 357px;
    background-image: ${props => props.bg || "transparent"};
    background-size: 636px 357px;
    margin-left: 29px;
    margin-top: 30px;
  }

  @media screen and (max-width: ${Sizes.md}px) {
    width: 308px;
    height: 174px;
    background-image: ${props => props.bg || "transparent"};
    background-size: 308px 174px;
    margin-left: 15px;
    margin-top: 15px;
  }
`;

export const Title = styled.h2`
  font-size: 64px;
  line-height: 1.0625;
  font-weight: 600;
  letter-spacing: -.009em;
  color: ${props => props.color || "#3078e3"};
  text-align: ${props => props.align || "left"};
  @media screen and (max-width: ${Sizes.lg}px) {
    font-size: 51.2px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    font-size: 40.96px;
  }
`;

export const SubTitle = styled.h3`
  font-size: 32px;
  line-height: 1.0625;
  font-weight: 600;
  letter-spacing: -.009em;
  color: ${props => props.color || "#3078e3"};
  text-align: ${props => props.align || "left"};
  @media screen and (max-width: ${Sizes.lg}px) {
    font-size: 25.6px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    font-size: 20.48px;
  }
`;

export const Description = styled.p`
  font-size: 28px;
  line-height: 1.28583;
  font-weight: 400;
  letter-spacing: .007em;
  color: ${props => props.color || "#1d1d1f"};
  text-align: ${props => props.align || "left"};

  &:not(:last-child) {
    margin: 0 0 20px 0;
  }

  @media screen and (max-width: ${Sizes.lg}px) {
    font-size: 22.4px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    font-size: 17.92px;
  }
`;

export const DescriptionIcon = styled.div`
  font-size: 120px;
  line-height: 1.0625;
  font-weight: 600;
  letter-spacing: -.009em;
  color: ${props => props.color || "#3078e3"};
  text-align: ${props => props.align || "center"};
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: ${Sizes.lg}px) {
    font-size: 80px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    font-size: 60px;
  }
`;