import styled, { css } from "styled-components";

import { Sizes } from "../Styles/config";

export const Col = styled.div`
  padding: ${props => props.padding || "0"};
  margin: ${props => props.margin || "0"};
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: ${props => props.height || "auto"};

  @media screen and (max-width: ${Sizes.md}px) {
    &.col-info {
      min-height: 165px;
      max-height: 165px;
    }
  }
`;

export const Row = styled.div`
  padding: 0;

  ${props => {
    const align = props.align || "left";
    if (align === "left") {
      return css`
        flex-direction: row;
      `;
    }
    else if (align === "right") {
      return css`
        flex-direction: row-reverse;
      `;
    }
    else if (align === "center") {
      return css`
        flex-direction: row;
      `;
    }
  }}

  @media screen and (max-width: ${Sizes.md}px) {
    flex-direction: column-reverse;
  }

  ${props => {
    const theme = props.theme || "dark";
    if (theme === "light") {
      return css`
        background: #f5f5f7;
      `;
    }
    else if (theme === "dark-silver") {
      return css`
        background: #1f1f1f;
      `;
    }
    else {
      return css`
        background: #000;
      `;
    }
  }}

  height: 760px;

  @media screen and (max-width: ${Sizes.lg}px) {
    height: 617px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    height: 550px;
  }
  overflow: hidden;
  position: relative;
`;

export const Info = styled.div`
  position: absolute;
  padding: 40px;
  margin: auto;
  z-index: 3;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: visible;

  ${props => {
    const align = props.align || "top-center";
    let alignCss = "";
    if (align === "top-left" || align === "top-center" || align === "top-right") {
      alignCss +=`
        top: 0;
      `;
    }
    if (align === "top-left" || align === "center-left" || align === "bottom-left") {
      alignCss += `
        left: 0;
      `;
    }
    if (align === "top-right" || align === "center-right" || align === "bottom-right") {
      alignCss += `
        right: 0;
      `;
    }
    if (align === "bottom-left" || align === "bottom-center" || align === "bottom-right") {
      alignCss += `
        bottom: 0;
      `;
    }
    if (align === "top-center" || align === "center-center" || align === "bottom-center") {
      alignCss += `
        left: 0;
        right: 0;
      `;
    }
    if (align === "center-left" || align === "center-center" || align === "center-right") {
      alignCss += `
        top: 0;
        bottom: 0;
      `;
    }
    return css`${alignCss}`;
  }}

  @media only screen and (max-width: ${Sizes.md}px) {
    padding: 0;
  }

  h2,
  h3,
  div {
    padding: 0;
    margin: 0;
    display: flex;
  }

  h2,
  h3 {
    ${props => {
      const theme = props.theme || "dark";
      if (theme === "light") {
        return css`
          color: #1d1d1f;
        `;
      }
      else if (theme === "dark-silver") {
        return css`
          color: #ffffff;
        `;
      }
      else {
        return css`
          color: #f2f2f5;
        `;
      }
    }}
    margin: 0 0 10px 0;
  }

  h2 {
    line-height: 1.1;
    font-weight: 600;
    letter-spacing: 0;
    text-align: center;
    ${props => {
      const size = props.size || "half";
      if (size === "full") {
        return css`
          font-size: 45px;
        `;
      }
      else {
        return css`
          font-size: 40px;
        `;
      }
    }}
  }
  @media only screen and (max-width: ${Sizes.lg}px) {
    h2 {
      ${props => {
        const size = props.size || "half";
        if (size === "full") {
          return css`
            font-size: 40px;
          `;
        }
        else {
          return css`
            font-size: 35px;
          `;
        }
      }}
    }
  }
  @media only screen and (max-width: ${Sizes.md}px) {
    h2 {
      ${props => {
        const size = props.size || "half";
        if (size === "full") {
          return css`
            font-size: 35px;
          `;
        }
        else {
          return css`
            font-size: 35px;
          `;
        }
      }}
    }
  }

  h3 {
    line-height: 1.2;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
    ${props => {
      const size = props.size || "half";
      if (size === "full") {
        return css`
          font-size: 25px;
        `;
      }
      else {
        return css`
          font-size: 20px;
        `;
      }
    }}
  }
  @media only screen and (max-width: ${Sizes.lg}px) {
    h3 {
      ${props => {
        const size = props.size || "half";
        if (size === "full") {
          return css`
            font-size: 20px;
          `;
        }
        else {
          return css`
            font-size: 15px;
          `;
        }
      }}
    }
  }
  @media only screen and (max-width: ${Sizes.md}px) {
    h3 {
      ${props => {
        const size = props.size || "half";
        if (size === "full") {
          return css`
            font-size: 15px;
          `;
        }
        else {
          return css`
            font-size: 15px;
          `;
        }
      }}
    }
  }

  div > a {
    padding: 5px;
    margin: 0 5px;
    font-size: 20px;
    line-height: 1.10722;
    font-weight: 400;
    letter-spacing: .004em;
    color: #06c;
    text-align: center;
    z-index: 4;
    position: relative;
    ${props => {
      const size = props.size || "half";
      if (size === "full") {
        return css`
          font-size: 25px;
        `;
      }
      else {
        return css`
          font-size: 20px;
        `;
      }
    }}
  }
  @media only screen and (max-width: ${Sizes.lg}px) {
    div > a {
      padding: 4px;
      ${props => {
        const size = props.size || "half";
        if (size === "full") {
          return css`
            font-size: 20px;
          `;
        }
        else {
          return css`
            font-size: 15px;
          `;
        }
      }}
    }
  }

  @media only screen and (max-width: ${Sizes.md}px) {
    div > a {
      padding: 3.2px;
      ${props => {
        const size = props.size || "half";
        if (size === "full") {
          return css`
            font-size: 15px;
          `;
        }
        else {
          return css`
            font-size: 15px;
          `;
        }
      }}
    }
  }

  div > a:hover {
    text-decoration: underline;
  }
`;

export const BackgroundLink = styled.a`
  position: absolute;
  top: 0;
  left: -100%;
  bottom: 0;
  margin: auto;
  z-index: 3;

  ${props => {
    const size = props.size || "half";
    if (size === "full") {
      return css`
        width: 3010px;
        height: 624px;
      `;
    }
    else {
      return css`
        width: 1262px;
        height: 580px;
      `;
    }
  }}

  @media screen and (max-width: ${Sizes.lg}px) {
    ${props => {
      const size = props.size || "half";
      if (size === "full") {
        return css`
          width: 1068px;
          height: 617px;
        `;
      }
      else {
        return css`
          width: 516px;
          height: 490px;
        `;
      }
    }}
  }
  @media screen and (max-width: ${Sizes.md}px) {
    ${props => {
      const size = props.size || "half";
      if (size === "full") {
        return css`
          width: 734px;
          height: 548px;
        `;
      }
      else {
        return css`
          width: 734px;
          height: 548px;
        `;
      }
    }}
    top: 100%;
    left: 0;
    bottom: 0;
  }
`;