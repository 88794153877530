export const Sizes = {
  xs: 320,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  Header: {
    NAV_HEIGHT: 44
  },
  HeaderProduct: {
    NAV_HEIGHT: 52
  }
};

export const ContainerSizes = {
  sm: 540,
  md: 720,
  lg: 960,
  xl: 1140,
  xxl: 1320
};