import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  NavDropdown
} from "react-bootstrap";
import { Nav, NavContainer, NavList, NavListLogo, NavListMenu, NavListMobile } from "./styles";

const Header = ({ position }) => {
  const [menu, setMenu] = useState(false);

  const handleMenu = (e) => {
    e.preventDefault();
    setMenu(!menu);
  };

  return (
    <Nav className={`${menu ? "active": ""}`} position={position}>
      <NavContainer className="container">
        <NavListMobile>
          <li>
            <NavListMenu className={`${menu ? "active": ""}`} onClick={handleMenu}>
              <div><div /></div>
              <div><div /></div>
            </NavListMenu>
          </li>
          <li><NavListLogo as={Link} to="/" mobile="true"/></li>
          <li></li>
        </NavListMobile>
        <NavList>
          <li><NavListLogo as={Link} to="/"><span>Página inicial</span></NavListLogo></li>
          <li><Link to="/3pixels">3Pixels</Link></li>
          <li><Link to="/niple-eletronico">Niple</Link></li>
          <li><Link to="/rastreamento">Rastreamento</Link></li>
          <li>
            <NavDropdown align="end" title="Produtos">
              <div className="nav-dropdown-caret" />
              <NavDropdown.Item as={Link} to={`/agendamentos`}>Agendamentos</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={`/rdv`}>Registro de despesas de viagem (RDV)</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={`/retirada-de-senhas`}>Retirada de senhas</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={`/streaming`}>Streaming</NavDropdown.Item>
            </NavDropdown>
          </li>
          <li><Link to="/contato">Contato</Link></li>
        </NavList>
      </NavContainer>
    </Nav>
  );
};

export default Header;