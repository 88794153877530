import styled, { css } from "styled-components";
import { Sizes } from "../Styles/config";

export const ButtonContainer = styled.div`
  @media (max-width: ${Sizes.md}px) {
    width: 100%;
  }
  & > a {
    padding: 5px 15px;
    border-radius: 5px;
    background-color: var(--blue-color);
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    display: inline-block;
    color: var(--white-color);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    cursor: pointer;
    user-select: none;
    &:not(:disabled),
    &:not(:disabled) {
      appearance: button;
      cursor: pointer;
    }
    &:hover {
      background-color: var(--blue-dark-color);
    }
    &:focus {
      box-shadow: 0 0 0 3px rgb(var(--blue-color-rgb) / 50%);
      outline: 0;
    }
    @media (max-width: ${Sizes.md}px) {
      width: 100%;
    }

    ${props => {
      const variant = props.variant || null;
      switch(variant) {
        case "light":
          return css`
            background-color: var(--white-color);
            color: var(--blue-color);
            &:hover {
              background-color: var(--white-dark-color);
            }
            &:focus {
              box-shadow: 0 0 0 3px rgb(var(--white-color-rgb) / 50%);
            }
          `;
        default:
          break;
      }
    }}
  }
`;