import React from "react";
import { Switch } from "react-router-dom";
import ScrollToTopRoute from "../../ScrollToTopRoute";
import { DfFooter, DfHeader, DfMain, GlobalStyle } from "../../components/Styles/default";

import MainEletronicNiple from "../../components/EletronicNiple";
import MainEletronicNipleLegal from "../../components/EletronicNiple/Legal";
import MainEletronicNipleBudget from "../../components/EletronicNiple/Budget";
import MainFooter from "../../components/Footer";
import MainHeader from "../../components/Header";
import MainHeaderProduct from "../../components/HeaderProduct";
import MainNotFound from "../../components/NotFound";

const EletronicNiple = ({ match }) => {
  const path = match.path;
  return (
    <>
      <GlobalStyle />
      <DfHeader>
        <MainHeader />
      </DfHeader>
      <MainHeaderProduct
        navList={[
          {
            title: "Lei 17.082/2017",
            href: `${process.env.PUBLIC_URL}/niple-eletronico/legal`
          },
          {
            title: "Autorização",
            href: `${process.env.PUBLIC_URL}/pdf/autorizacao.pdf`,
            isBlank: true
          },
          {
            title: "Orçamento",
            href: `${process.env.PUBLIC_URL}/niple-eletronico/orcamento`,
            isButton: true
          }
        ]}
        title={{
          title: "Niple Eletrônico",
          href: `${process.env.PUBLIC_URL}/niple-eletronico`
        }}
      />
      <DfMain>
        <Switch>
          <ScrollToTopRoute exact path={path} component={MainEletronicNiple} />
          <ScrollToTopRoute exact path={`${path}/legal`} component={MainEletronicNipleLegal} />
          <ScrollToTopRoute exact path={`${path}/orcamento`} component={MainEletronicNipleBudget} />
          <ScrollToTopRoute component={MainNotFound} />
        </Switch>
      </DfMain>
      <DfFooter>
        <MainFooter />
      </DfFooter>
    </>
  );
};

export default EletronicNiple;
