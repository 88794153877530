import { replaceColor } from "lottie-colorify";

import Animation from "./animation.json";
import {
  ClockCalendarBox as ClockCalendarBoxHTML,
  ClockCalendar as ClockCalendarHTML
} from "./style";

export const ClockCalendar = (props) => {
  let animationData = Animation;
  if (props.replaceColors) {
    props.replaceColors.forEach(({ originalColor, newColor }) => {
      animationData = replaceColor(originalColor, newColor, animationData);
    });
  }
  return (
    <ClockCalendarBoxHTML className={props.className} height={props.height} width={props.width}>
      <ClockCalendarHTML
        height={props.height}
        isClickToPauseDisabled={true}
        options={{
          autoplay: props.autoplay ?? true,
          animationData,
          loop: props.loop ?? true,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
          }
        }}
        speed={props.speed ?? 1}
        width={props.width}
      />
    </ClockCalendarBoxHTML>
  );
};

export default ClockCalendar;
