import React from "react";
import { Link } from "react-router-dom";
import { Nav, NavContainer, NavList, NavTitle } from "./styles";

const HeaderProduct = ({ navList, title }) => {
  return (
    <Nav>
      <NavContainer className="container">
        <NavTitle>
          <li>
            <Link to={title.href}>{title.title}</Link>
          </li>
        </NavTitle>
        <NavList>
          {
            typeof navList !== "undefined" && Array.isArray(navList) ? (
              <>
                {
                  navList.map((list, index) => {
                    const classList = [];
                    if (typeof list.isButton !== "undefined" && list.isButton === true) {
                      classList.push("hp-button");
                    }
                    if (typeof list.isBlank !== "undefined" && list.isBlank === true) {
                      return (
                        <li key={`header-product-${index}`}><a className={classList.join(" ")} href={list.href} target="_blank" rel="noopener noreferrer">{list.title}</a></li>
                      );
                    }
                    return (
                      <li key={`header-product-${index}`}><Link className={classList.join(" ")} to={list.href}>{list.title}</Link></li>
                    );
                  })
                }
              </>
            ) : null
          }
        </NavList>
      </NavContainer>
    </Nav>
  );
};

export default HeaderProduct;