import styled, { createGlobalStyle, css } from "styled-components";
import { ContainerSizes, Sizes } from "./config";

export const Col = styled.div`
  padding: ${props => props.padding || "0"};
  display: block;
  max-width: 100%;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
  width: 100%;
  text-align: ${props => props.textAlign || "left"};

  ${props => {
    const col = props.col || -1;
    if (col !== -1) {
      const calc = (col * 100) / 12;
      return css`
        flex: 0 0 ${calc}%;
        max-width: ${calc}%;
      `;
    }
  }}

  ${props => {
    const _col = props._col || -1;
    if (_col !== -1) {
      return css`
        flex: 0 0 ${_col};
        max-width: ${_col};
      `;
    }
  }}

  ${props => {
    const colMD = props.colMD || -1;
    if (colMD !== -1) {
      const calc = (colMD * 100) / 12;
      return css`
        @media (min-width: ${Sizes.md}px) {
          flex: 0 0 ${calc}%;
          max-width: ${calc}%;
        }
      `;
    }
  }}

  ${props => {
    const _colMD = props._colMD || -1;
    if (_colMD !== -1) {
      return css`
        @media (min-width: ${Sizes.md}px) {
          flex: 0 0 ${_colMD};
          max-width: ${_colMD};
        }
      `;
    }
  }}

  ${props => {
    const colLG = props.colLG || -1;
    if (colLG !== -1) {
      const calc = (colLG * 100) / 12;
      return css`
        @media (min-width: ${Sizes.lg}px) {
          flex: 0 0 ${calc}%;
          max-width: ${calc}%;
        }
      `;
    }
  }}

  ${props => {
    const _colLG = props._colLG || -1;
    if (_colLG !== -1) {
      return css`
        @media (min-width: ${Sizes.lg}px) {
          flex: 0 0 ${_colLG};
          max-width: ${_colLG};
        }
      `;
    }
  }}

  ${props => {
    const colXL = props.colXL || -1;
    if (colXL !== -1) {
      const calc = (colXL * 100) / 12;
      return css`
        @media (min-width: ${Sizes.xl}px) {
          flex: 0 0 ${calc}%;
          max-width: ${calc}%;
        }
      `;
    }
  }}

  ${props => {
    const _colXL = props._colXL || -1;
    if (_colXL !== -1) {
      return css`
        @media (min-width: ${Sizes.xl}px) {
          flex: 0 0 ${_colXL};
          max-width: ${_colXL};
        }
      `;
    }
  }}

  ${props => {
    const colXXL = props.colXXL || -1;
    if (colXXL !== -1) {
      const calc = (colXXL * 100) / 12;
      return css`
        @media (min-width: ${Sizes.xxl}px) {
          flex: 0 0 ${calc}%;
          max-width: ${calc}%;
        }
      `;
    }
  }}

  ${props => {
    const _colXXL = props._colXXL || -1;
    if (_colXXL !== -1) {
      return css`
        @media (min-width: ${Sizes.xxl}px) {
          flex: 0 0 ${_colXXL};
          max-width: ${_colXXL};
        }
      `;
    }
  }}
`;

export const Container = styled.div`
  padding: ${props => props.padding || "0 15px"};
  margin: ${props => props.margin || "0 auto"};
  width: 100%;

  ${props => {
    const fluid = props.fluid || false;
    if (fluid === false) {
      return css`
        @media (min-width: ${Sizes.md}px) {
          max-width: ${ContainerSizes.md}px;
        }
        @media (min-width: ${Sizes.lg}px) {
          max-width: ${ContainerSizes.lg}px;
        }
        @media (min-width: ${Sizes.xl}px) {
          max-width: ${ContainerSizes.xl}px;
        }
        @media (min-width: ${Sizes.xxl}px) {
          max-width: ${ContainerSizes.xxl}px;
        }
      `;
    }
  }}
`;

export const DfFooter = styled.footer`
  padding: ${props => props.padding || "0"};
  background: ${props => props.background || "#f5f5f7"};
  margin: 0;
  width: 100vw;
  flex: 1;
  display: flex;
`;

export const DfHeader = styled.header`
  padding: ${props => props.padding || "0"};
  background: ${props => props.background || "#f5f5f7"};
  margin: 0;
  width: 100vw;
  flex: 1;
  display: flex;
`;

export const DfMain = styled.main`
  padding: ${props => props.padding || "0"};
  background: ${props => props.background || "#f5f5f7"};
  margin: auto;
  width: 100vw;
  max-width: 100vw;
  flex: 1;
  display: flex;
  overflow: hidden;

  @media print {
    padding: 0 !important;
  }
`;

export const GlobalStyle = createGlobalStyle`
  html {
    --black-color: #212529;
    --black-dark-color: #000000;
    --gray-color: #eeeeee;
    --gray-color-rgb: 238 238 238;
    --gray-dark-color: #ced4da;
    --gray-dark-color-rgb: 206 212 218;
    --blue-color: #3078e3;
    --blue-color-rgb: 48 120 227;
    --blue-light-color: #4e8fed;
    --blue-dark-color: #2560b7;
    --white-color: #ffffff;
    --white-color-rgb: 255 255 255;
    --white-dark-color: #eeeeee;
  }
  body {
    padding: 0;
    margin: 0;
    width: 100vw;
    min-height: ${props => props.minHeight || "auto"};
  }
  strong {
    font-weight: bold;
  }
`;

export const Row = styled.div`
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
`;