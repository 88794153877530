import Api from "./../Api";

export async function send({ data }) {
  const response = await Api.post("/send", data).then(response => {
    return response.data;
  }).catch(error => {
    try {
      if (error.response.data === "Unauthorized") {
        return {
          success: false,
          error: {
            type: "UnauthorizedError",
            details: [{
              message: "UnauthorizedError"
            }]
          }
        };
      }
      return error.response.data;
    }
    catch(error) {
      return {
        success: false,
        error: {
          type: "NetworkError",
          details: [{
            message: "NetworkError"
          }]
        }
      };
    }
  });

  if (response.success) {
    return true;
  }
  return false;
}
