import React from "react";
import { Container } from "react-bootstrap";
import { ModalGlobalStyle, ModalBackdrop, ModalBody, ModalContainer, ModalContent, ModalHeader, ModalHeaderAction, ModalHeaderTitle } from "./styles";

const Modal = React.forwardRef(({ children, handleClose, open, title, ...props }, ref) => {
  return (
    <>
      <ModalGlobalStyle open={open} />
      <ModalContainer open={open} ref={ref} {...props}>
        <ModalContent>
          <ModalHeader>
            <Container>
              <ModalHeaderTitle>
                {
                  (title && (
                    <h2>{title}</h2>
                  ))
                }
              </ModalHeaderTitle>
              <ModalHeaderAction>
                <button type="button" onClick={handleClose}>
                  <i className="fa fa-times" />
                </button>
              </ModalHeaderAction>
            </Container>
          </ModalHeader>
          <ModalBody>{children}</ModalBody>
        </ModalContent>
        <ModalBackdrop />
      </ModalContainer>
    </>
  );
});

export default Modal;