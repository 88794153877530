import styled, { css } from "styled-components";
import { Sizes } from "./../Styles/config";

const NAV_HEIGHT = Sizes.Header.NAV_HEIGHT;
const MIN_WIDTH_HIDE_MENU_MOBILE = Sizes.md;
const MAX_WIDTH_SHOW_MENU_MOBILE = (Sizes.md - 1);
const PUBLIC_URL = process.env.PUBLIC_URL;

export const Nav = styled.nav`
  width: 100vw;
  height: ${NAV_HEIGHT}px;
  background: rgba(0,0,0,.8);
  backdrop-filter: saturate(180%) blur(20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: ${props => props.position || "relative"};
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
  transition: background .36s cubic-bezier(0.32, 0.08, 0.24, 1), height .56s cubic-bezier(0.52, 0.16, 0.24, 1);

  @media print {
    display: none !important;
  }

  @media screen and (max-width: ${MAX_WIDTH_SHOW_MENU_MOBILE}px) {
    justify-content: flex-start;
    overflow: hidden;
    &.active {
      height: 100vh;
      background: rgba(0,0,0,1);
      position: fixed;
    }
  }
`;

export const NavContainer = styled.div`
  height: ${NAV_HEIGHT}px;
  @media screen and (max-width: ${MAX_WIDTH_SHOW_MENU_MOBILE}px) {
    max-width: 100vw;
    padding: 0;
  }
`;

export const NavList = styled.ul`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${MAX_WIDTH_SHOW_MENU_MOBILE}px) {
    padding: 20px 40px 8px 40px;
    width: 100vw;
    align-items: flex-start;
    flex-direction: column;
  }

  li,
  li > a,
  li > .dropdown > .dropdown-toggle,
  li > .dropdown > .dropdown-menu > .dropdown-item {
    height: ${NAV_HEIGHT}px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  li > .dropdown > .dropdown-menu > .dropdown-item {
    justify-content: flex-start;
  }
  li > .dropdown > .dropdown-menu {
    padding: 5px;
  }

  li > .dropdown > .dropdown-toggle:after {
    display: none;
  }
  li > .dropdown > .dropdown-menu {
    min-width: 200px !important;
    max-width: 200px !important;
    left: 50% !important;
    margin-left: -100px !important;
    border: 1px solid #d2d2d7;
    background: #fff;
    z-index: 1;
  }
  @media screen and (max-width: ${MAX_WIDTH_SHOW_MENU_MOBILE}px) {
    li > .dropdown > .dropdown-menu {
      padding: 0 0 0 20px;
      inset: auto !important;
      transform: translate(0, 0) !important;
      margin-left: 0 !important;
      position: relative !important;
      background: transparent !important;
      border: 0 !important;
    }
  }

  .nav-dropdown-caret {
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    margin: auto;
    width: 20px;
    height: 10px;
    overflow: hidden;
  }
  .nav-dropdown-caret:after {
    content: "";
    border: 1px solid #d2d2d7;
    background: #fff;
    position: absolute;
    top: 4px;
    left: 0;
    right: 0;
    margin: auto;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
  }

  li > a,
  li > .dropdown > .dropdown-toggle,
  li > .dropdown > .dropdown-menu > .dropdown-item {
    padding: 5px  10px;
    font-size: 16px;
    letter-spacing: -0.374px;
    opacity: .8;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
  }
  li > .dropdown > .dropdown-menu > .dropdown-item {
    align-items: center;
    justify-content: flex-start;
    white-space: normal;
    text-align: left;
  }
  li > a,
  li > .dropdown > .dropdown-toggle {
    color: #fff;
  }
  li > .dropdown > .dropdown-menu > .dropdown-item {
    color: #333;
  }
  li > .dropdown > .dropdown-menu > .dropdown-item:not(:last-child) {
    border-bottom: 1px solid #d2d2d7;
  }

  li > a:hover,
  li > .dropdown > .dropdown-toggle:hover,
  li > .dropdown > .dropdown-menu > .dropdown-item:hover {
    opacity: 1;
  }
  li > .dropdown > .dropdown-menu > .dropdown-item:focus,
  li > .dropdown > .dropdown-menu > .dropdown-item:hover {
    background: transparent !important;
  }

  @media screen and (max-width: ${MAX_WIDTH_SHOW_MENU_MOBILE}px) {
    li,
    li > a,
    li > .dropdown,
    li > .dropdown > .dropdown-toggle,
    li > .dropdown > .dropdown-menu,
    li > .dropdown > .dropdown-menu > .dropdown-item {
      min-height: ${NAV_HEIGHT}px;
      height: auto;
      width: 100% !important;
      min-width: 100% !important;
      max-width: 100% !important;
      justify-content: flex-start;
    }
    li > .dropdown > .dropdown-menu > .dropdown-item {
      color: #fff;
    }
    .nav-dropdown-li {
      height: auto;
    }
    li > .dropdown > .dropdown-menu .nav-dropdown-caret {
      display: none;
    }
    li:not(:last-child),
    li > .dropdown > .dropdown-menu > .dropdown-item:not(:last-child) {
      border-bottom: 1px solid #424245;
    }
    li > a,
    li > .dropdown > .dropdown-toggle,
    li > .dropdown > .dropdown-menu > .dropdown-item {
      padding: 0 15px;
      font-size: 17px;
    }
  }
`;

export const NavListMenu = styled.a`
  width: 44px;
  height: 44px;
  position: relative;

  & > div {
    width: 44px;
    height: 44px;
  }

  & > div,
  & > div > div  {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
  }

  & > div {
    top: 0;
    bottom: 0;
  }

  & > div > div {
    left: 2px;
    width: 15px;
    height: 1px;
    background: #fff;
    border-radius: 0.5px;
  }
  & > div:first-child > div {
    top: 18px;
  }
  & > div:last-child > div {
    bottom: 18px;
  }

  &.active > div:first-child {
    transform: rotate(45deg);
  }
  &.active > div:first-child > div {
    top: 20px;
  }
  &.active > div:last-child {
    transform: rotate(-45deg);
  }
  &.active > div:last-child > div {
    top: 20px;
  }
`;

export const NavListMobile = styled.ul`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #424245;

  li,
  li > a {
    height: ${NAV_HEIGHT}px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  li > a {
    padding: 0 10px;
    font-size: 16px;
    letter-spacing: -0.374px;
    color: #fff;
    opacity: .8;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
  }
  li > a:hover {
    opacity: 1;
  }

  @media screen and (min-width: ${MIN_WIDTH_HIDE_MENU_MOBILE}px) {
    display: none;
  }
`;

export const NavListLogo = styled.a`
  display: flex;
  flex: 1;
  width: 44px;
  height: ${NAV_HEIGHT}px;
  background: url(${PUBLIC_URL}/images/logo-crop-pixels-44.png) no-repeat center center;
  background-size: auto 20px;

  @media screen and (min-width: ${MIN_WIDTH_HIDE_MENU_MOBILE}px) {
    & > span {
      display: none;
    }
  }
  @media screen and (max-width: ${MAX_WIDTH_SHOW_MENU_MOBILE}px) {
    ${props => {
      const mobile = props.mobile || false;
      if (mobile === false) {
        return css`
          background: none;
        `;
      }
    }}
  }
`;