import React from "react";
import { Switch } from "react-router-dom";
import ScrollToTopRoute from "../../ScrollToTopRoute";
import { DfFooter, DfHeader, DfMain, GlobalStyle } from "../../components/Styles/default";

import MainFooter from "../../components/Footer";
import MainHeader from "../../components/Header";
import MainHeaderProduct from "../../components/HeaderProduct";
import MainNotFound from "../../components/NotFound";
import MainRdv from "../../components/Rdv";
import MainRdvBudget from "../../components/Rdv/Budget";

const Rdv = ({ match }) => {
  const path = match.path;
  return (
    <>
      <GlobalStyle />
      <DfHeader>
        <MainHeader />
      </DfHeader>
      <MainHeaderProduct
        navList={[
          {
            title: "Orçamento",
            href: "https://api.whatsapp.com/send?phone=5549989237375&text=Olá, tenho interesse no sistema para registro de despesas de viagem (RDV).",
            isButton: true,
            isBlank: true
          }
        ]}
        title={{
          title: "Registro de despesas de viagem (RDV)",
          href: `${process.env.PUBLIC_URL}/rdv`
        }}
      />
      <DfMain>
        <Switch>
          <ScrollToTopRoute exact path={path} component={MainRdv} />
          <ScrollToTopRoute exact path={`${path}/orcamento`} component={MainRdvBudget} />
          <ScrollToTopRoute component={MainNotFound} />
        </Switch>
      </DfMain>
      <DfFooter>
        <MainFooter />
      </DfFooter>
    </>
  );
};

export default Rdv;
