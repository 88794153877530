import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import {
  Iframe,
  Waiting
} from "./styles";

import SEO from "../../SEO";
import Modal from "../../Modal";

const Newsletter = () => {
  const dispatch = useDispatch();
  const [running, setRunning] = useState(true);
  window.location="https://api.whatsapp.com/send?phone=5549989237375&text=Olá, tenho interesse em receber dicas grátis de como reduzir os custos da frota.";
  return (
    <Container fluid>
      <SEO options={{
        tags: {
          title: `Dicas de rastreamento - ${process.env.REACT_APP_APP_TITLE}`,
          description: `Rastreamento veícular para você e para sua empresa, rastreamento em chapecó.`,
          ogDescription: `Rastreamento veícular para você e para sua empresa, rastreamento em chapecó.`
        }
      }} />
      <Modal
        handleClose={() => dispatch(push("/rastreamento"))}
        open={true}
        title="Dicas de rastreamento"
      >
        {
          (running && (
            <Waiting>Carregando...</Waiting>
          ))
        }
        {/* <Iframe
          src="https://api.whatsapp.com/send?phone=5549989237375&text=Ol%C3%A1"
          title="Dicas de rastreamento"
          onLoad={() => setRunning(false)}
        /> */}
      </Modal>
    </Container>
  )
};

export default Newsletter;
