import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Iframe, IframeProgressBar, IframePlayPause, IframeBlock } from "./styles";

import { Creators as EletronicNipleInstallationActions } from "./../../../store/ducks/eletronicNipleInstallation";

const MODEL_UID = "51207889d6bd4adba76402afc1b52e24";
const MODEL_ANNOTATION_DELAY = 3000;

var pause = false;

const EletronicNipleInstallation = ({
  props: {
    annotation,
    annotationPlay,
    annotationPlayBar,
    annotationPause,
    annotations,
    api,
    cameraMoving,
    ready
  },
  funcs: {
    setAnnotation,
    setAnnotationPlay,
    setAnnotationPlayBar,
    setAnnotationPause,
    setAnnotations,
    setApi,
    setCameraMoving,
    setReady
  }
}) => {
  const iframeRef = useRef(null);

  const resetAnnotation = () => {
    setAnnotation(0);
    api.gotoAnnotation(0);
  };

  const nextAnnotation = () => {
    setAnnotationPlayBar(0);
    let annotationAux = annotation;
    if (annotationAux === false && annotations.length > 0) {
      annotationAux = 0;
    }
    else if (annotationAux !== true && annotationAux !== false) {
      const next = annotationAux + 1;
      if (next < annotations.length) {
        annotationAux = next;
      }
      else {
        annotationAux = true;
      }
    }
    setAnnotation(annotationAux);
    if (annotationAux !== false && annotationAux !== true) {
      api.gotoAnnotation(annotationAux);
    }
  };

  const nextAnnotationBar = (countMax) => {
    setAnnotationPlayBar(1);
    let count = 0;
    let countRound = 100;
    let countPer = 0;
    const interval = setInterval(() => {
      if (pause === false) {
        count = count + countRound;
        if (count > countMax) {
          count = countMax;
        }
        countPer = parseInt((count * 100) / countMax);
        setAnnotationPlayBar(countPer);
        if (countPer === 100) {
          setTimeout(() => {
            setAnnotationPlayBar(-1);
            clearInterval(interval);
          }, 10);
        }
      }
    }, countRound);
  };

  useEffect(() => {
    if (api) {
      api.addEventListener("viewerready", () => setReady(true));
      api.addEventListener("camerastart", () => setCameraMoving(true));
      api.addEventListener("camerastart", () => setCameraMoving(true));
      api.addEventListener("camerastop", () => setCameraMoving(false));
      // api.addEventListener("nodeMouseEnter", () => setAnnotationPause(true), { pick: "fast" });
      // api.addEventListener("nodeMouseLeave", () => setAnnotationPause(false), { pick: "fast" });

      setTimeout(() => {
        api.getAnnotationList((err, annotations) => {
          if (err || !Array.isArray(annotations)) return;
          if (annotations.length > 0) {
            setAnnotation(0);
            setAnnotations(annotations);
          }
        });
      }, 1000);
    }
  }, [api]); //eslint-disable-line

  useEffect(() => {
    if (ready) {
      iframeRef.current.classList.remove("hidden");
      if (annotations.length > 0) {
        setAnnotationPlay(true);
      }
    }
  }, [ready]); //eslint-disable-line

  useEffect(() => {
    if (annotationPlay && annotation !== false && annotation !== true && annotationPlayBar === 0 && cameraMoving === false) {
      nextAnnotationBar(MODEL_ANNOTATION_DELAY);
    }
  }, [annotationPlay, annotation, annotationPlayBar, cameraMoving]); //eslint-disable-line

  useEffect(() => {
    if (annotationPlayBar === -1) {
      nextAnnotation();
    }
  }, [annotationPlayBar]); //eslint-disable-line

  useEffect(() => {
    pause = annotationPause;
  }, [annotationPause]); //eslint-disable-line

  useEffect(() => {
    let client = new window.Sketchfab(iframeRef.current);
    client.init(MODEL_UID, {
      annotation: 1,
      annotation_tooltip_visible: 1,
      annotations_visible: 1,
      ucontinuousRender: 1,
      autospin: 0,
      autostart: 1,
      cardboard: 0,
      camera: 1,
      preload: 0,
      ui_stop: 0,
      transparent: 0,
      ui_animations: 0,
      ui_annotations: 1,
      ui_ar: 0,
      ui_controls: 0,
      ui_fullscreen: 0,
      ui_general_controls: 0,
      ui_help: 0,
      ui_hint: 1,
      ui_infos: 1,
      ui_inspector: 0,
      ui_loading: 0,
      ui_settings: 0,
      ui_vr: 0,
      ui_watermark_link: 0,
      ui_watermark: 0,
      background: {
        enable: "color",
        color: [0.918, 0.918, 0.918, 1]
      },
      success: (api) => {
        api.load();
        api.start();
        setApi(api);
      },
      error: () => {
        console.log("Viewer error");
      }
    });
  }, []); //eslint-disable-line

  return (
    <IframeBlock>
      <Iframe
        id="iframe-eletronic-niple-installation"
        className="hidden"
        ref={iframeRef}
        title="Niple Eletrônico - Instalação"
        sandbox="allow-scripts allow-same-origin allow-popups allow-forms" allow="autoplay; fullscreen; vr"
        allowvr
        allowfullscreen
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
      />
      {
        annotationPlay ? (
          <>
            <IframePlayPause pause={annotationPause} onClick={() => {
              if (annotation === true) {
                return resetAnnotation();
              }
              return setAnnotationPause(!annotationPause);
            }}>
              {
                annotation === true ? (
                  <i className="fas fa-redo-alt" />
                ) : (
                  <>
                    {
                      annotationPause ? <i className="fas fa-play" /> : <i className="fas fa-pause" />
                    }
                  </>
                )
              }
            </IframePlayPause>
            <IframeProgressBar width={`${annotationPlayBar}%`} />
          </>
        ) : null
      }
    </IframeBlock>
  );
};

const mapStateToProps = state => ({
  props: {
    annotation: state.eletronicNipleInstallation.annotation,
    annotationPlay: state.eletronicNipleInstallation.annotationPlay,
    annotationPlayBar: state.eletronicNipleInstallation.annotationPlayBar,
    annotationPause: state.eletronicNipleInstallation.annotationPause,
    annotations: state.eletronicNipleInstallation.annotations,
    api: state.eletronicNipleInstallation.api,
    cameraMoving: state.eletronicNipleInstallation.cameraMoving,
    ready: state.eletronicNipleInstallation.ready
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(
    Object.assign({}, EletronicNipleInstallationActions),
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(EletronicNipleInstallation);