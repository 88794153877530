import styled, { css } from "styled-components";

import { Sizes } from "./../Styles/config";

const PUBLIC_URL = process.env.PUBLIC_URL;

const ImacSizes = {
  large: {
    alignCenter: -387.5,
    alignLeft: -380,
    alignRight: 380,
    scale: 0.7165
  },
  medium: {
    alignCenter: -387.5,
    alignLeft: -300,
    alignRight: 300,
    scale: 0.7165
  },
  small: {
    alignCenter: 0,
    alignLeft: -150,
    alignRight: 150,
    scale: 0.7165
  }
};

export const Row = styled.div`
  padding: 0;

  ${props => {
    const align = props.align || "left";
    if (align === "left") {
      return css`
        flex-direction: row;
      `;
    }
    else if (align === "right") {
      return css`
        flex-direction: row-reverse;
      `;
    }
    else if (align === "center") {
      return css`
        flex-direction: row;
      `;
    }
  }}

  @media screen and (max-width: ${Sizes.md}px) {
    padding: 15px 0;
    flex-direction: column-reverse;
  }
`;

export const Col = styled.div`
  padding: 0;
  margin: auto;
  @media screen and (max-width: ${Sizes.md}px) {
    margin-bottom: 15px;
  }
`;

export const Imac = styled.div`
  width: 1892px;
  height: 1084px;
  pointer-events: none;
  will-change: transform;
  display: block;
  z-index: 1;

  ${props => {
    const align = props.align || "left";
    if (align === "left") {
      return css`
        float: right;
        transform: translateX(${ImacSizes.large.alignRight}px) scale(${ImacSizes.large.scale});
      `;
    }
    else if (align === "right") {
      return css`
        float: left;
        transform: translateX(${ImacSizes.large.alignLeft}px) scale(${ImacSizes.large.scale});
      `;
    }
    else if (align === "center") {
      return css`
        float: none;
        transform: translateX(${ImacSizes.large.alignCenter}px) scale(${ImacSizes.large.scale});
      `;
    }
  }}

  & div,
  &:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  @media screen and (max-width: ${Sizes.lg}px) {
    width: 1496px;
    height: 857px;

    ${props => {
      const align = props.align || "left";
      if (align === "left") {
        return css`
          transform: translateX(${ImacSizes.medium.alignRight}px) scale(${ImacSizes.medium.scale});
        `;
      }
      else if (align === "right") {
        return css`
          transform: translateX(${ImacSizes.medium.alignLeft}px) scale(${ImacSizes.medium.scale});
        `;
      }
      else if (align === "center") {
        return css`
          transform: translateX(${ImacSizes.medium.alignCenter}px) scale(${ImacSizes.medium.scale});
        `;
      }
    }}
  }

  @media screen and (max-width: ${Sizes.md}px) {
    width: 770px;
    height: 524px;

    ${props => {
      const align = props.align || "left";
      if (align === "left") {
        return css`
          transform: translateX(${ImacSizes.small.alignRight}px) scale(${ImacSizes.small.scale});
        `;
      }
      else if (align === "right") {
        return css`
          transform: translateX(${ImacSizes.small.alignLeft}px) scale(${ImacSizes.small.scale});
        `;
      }
      else if (align === "center") {
        return css`
          transform: translateX(${ImacSizes.small.alignCenter}px) scale(${ImacSizes.small.scale});
        `;
      }
    }}
  }
`;

export const ImacBackground = styled.div`
  pointer-events: none;
  z-index: 3 !important;
  width: 1892px;
  height: 1084px;
  background-image: url("${PUBLIC_URL}/images/mac/imac/large/bg.jpg");
  background-size: 1892px 1084px;
  background-repeat: no-repeat;
  background-position: center;
  mask-image: url("${PUBLIC_URL}/images/mac/imac/large/mask.svg");
  mask-size: 1892px 1084px;
  mask-repeat: no-repeat;
  mask-position: center;

  @media screen and (max-width: ${Sizes.lg}px) {
    width: 1496px;
    height: 857px;
    background-image: url("${PUBLIC_URL}/images/mac/imac/medium/bg.jpg");
    background-size: 1496px 857px;
    mask-image: url("${PUBLIC_URL}/images/mac/imac/medium/mask.svg");
    mask-size: 1496px 857px;
  }

  @media screen and (max-width: ${Sizes.md}px) {
    width: 770px;
    height: 524px;
    background-image: url("${PUBLIC_URL}/images/mac/imac/small/bg.jpg");
    background-size: 770px 524px;
    mask-image: url("${PUBLIC_URL}/images/mac/imac/medium/mask.svg");
    mask-size: 770px 524px;
  };
`;

export const ImacWindow = styled.div`
  width: 1282px;
  height: 728px;
  background-image: ${props => props.background.lg || "transparent"};
  background-size: 1282px 728px;
  background-repeat: no-repeat;
  z-index: 4 !important;
  margin-left: 306px;
  margin-top: 40px;

  @media screen and (max-width: ${Sizes.lg}px) {
    width: 1015px;
    height: 582px;
    background-image: ${props => props.background.md || "transparent"};
    background-size: 1015px 582px;
    margin-left: 240px;
    margin-top: 30px;
  }

  @media screen and (max-width: ${Sizes.md}px) {
    width: 626px;
    height: 360px;
    background-image: ${props => props.background.xs || "transparent"};
    background-size: 626px 360px;
    margin-left: 71px;
    margin-top: 16px;
  }
`;

export const Title = styled.h2`
  padding: ${props => props.padding || "0"};
  font-size: 64px;
  line-height: 1.0625;
  font-weight: 600;
  letter-spacing: -.060em;
  color: ${props => props.color || "#3078e3"};
  text-align: ${props => props.align || "left"};
  @media screen and (max-width: ${Sizes.lg}px) {
    font-size: 51.2px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    padding: 0;
    font-size: 40.96px;
  }
`;

export const Description = styled.p`
  padding: ${props => props.padding || "0"};
  font-size: 28px;
  line-height: 1.28583;
  font-weight: 400;
  letter-spacing: .007em;
  color: ${props => props.color || "#1d1d1f"};
  text-align: ${props => props.align || "left"};
  @media screen and (max-width: ${Sizes.lg}px) {
    font-size: 22.4px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    padding: 0;
    font-size: 17.92px;
  }
`;