import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  // NavDropdown,
  OverlayTrigger,
  Row,
  Tooltip
} from "react-bootstrap";
import { isMobile } from "react-device-detect";
import {
  FooterBox,
  SectionLegal,
  SectionLegalCopy,
  // SectionLegalNav,
  SectionLogo,
  SectionMap,
  SectionMapNav
} from "./styles";

import { copyTextFromElement } from "./../../utils/Copy";

const Footer = () => {
  const [copiedTellphone, setCopiedTellphone] = useState(false);
  const [copiedWhatsapp, setCopiedWhatsapp] = useState(false);
  const [copiedEmail, setCopiedEmail] = useState(false);

  const copyTellphone = (elm) => {
    copyTextFromElement("footer-tellphone");
    setCopiedTellphone(true);
    setTimeout(() => {
      setCopiedTellphone(false);
    }, 1000);
  };

  const copyWhatsapp = (elm) => {
    copyTextFromElement("footer-whatsapp");
    setCopiedWhatsapp(true);
    setTimeout(() => {
      setCopiedWhatsapp(false);
    }, 1000);
  };

  const copyEmail = (elm) => {
    copyTextFromElement("footer-email");
    setCopiedEmail(true);
    setTimeout(() => {
      setCopiedEmail(false);
    }, 1000);
  };

  return (
    <FooterBox className="container-fluid">
      <Row>
        <SectionMap className="col">
          <Container>
            <Row>
              <SectionMapNav className="col-12 col-md-2">
                <nav>
                  <ul>
                    <li>Menu</li>
                    <li><Link to={"/"}>Página inicial</Link></li>
                    {/* <li><Link to={`/3pixels`}>3Pixels</Link></li> */}
                    <li><Link to={"/niple-eletronico"}>Niple</Link></li>
                    {/* <li><Link to={`/rastreamento`}>Rastreamento</Link></li>
                    <li className="nav-dropdown-li">
                      <NavDropdown align="end" title="Aplicativos">
                        <div className="nav-dropdown-caret" />
                        <NavDropdown.Item as={Link} to={`/retirada-de-senhas`}>Retirada de senhas</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={`/agendamentos`}>Agendamentos</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={`/cafe`}>Café</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to={`/sob-medida`}>Sob medida</NavDropdown.Item>
                      </NavDropdown>
                    </li> */}
                    <li><Link to={"/contato"}>Contato</Link></li>
                  </ul>
                </nav>
              </SectionMapNav>
              <SectionMapNav className="section-map-nav col-12 col-md-3">
                <nav>
                  <ul>
                    <li>Contatos</li>
                    <li>
                      <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip>{!copiedTellphone ? "Copiar telefone" : "Copiado!"}</Tooltip>}
                        trigger={isMobile ? null : ["hover", "focus"]}
                      >
                        <button
                          type="button"
                          onClick={copyTellphone}
                        >
                          <div id="footer-tellphone" className="mf-description"><i className="fa-solid fa-phone" /> (49) 2049-4353</div>
                        </button>
                      </OverlayTrigger>
                    </li>
                    <li>
                      <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip>{!copiedWhatsapp ? "Copiar whatsapp" : "Copiado!"}</Tooltip>}
                        trigger={isMobile ? null : ["hover", "focus"]}
                      >
                        <button
                          type="button"
                          onClick={copyWhatsapp}
                        >
                          <div id="footer-whatsapp" className="mf-description"><i className="fab fa-whatsapp" /> (49) 9 8923-7375</div>
                        </button>
                      </OverlayTrigger>
                    </li>
                    <li>
                      <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip>{!copiedEmail ? "Copiar email" : "Copiado!"}</Tooltip>}
                        trigger={isMobile ? null : ["hover", "focus"]}
                      >
                        <button
                          type="button"
                          onClick={copyEmail}
                        >
                          <div id="footer-email" className="mf-description"><i className="fas fa-envelope" /> contato@threepixels.com.br</div>
                        </button>
                      </OverlayTrigger>
                    </li>
                  </ul>
                </nav>
              </SectionMapNav>
              <SectionMapNav className="section-map-nav col-12 col-md-3">
                <nav>
                  <ul>
                    <li>Área do cliente</li>
                    <li><a href="https://www.threepixels.com.br/octsat" target="_blank" rel="noopener noreferrer">OCTSat</a></li>
                    <li><a href="https://ntracker.threepixels.com.br" target="_blank" rel="noopener noreferrer">N-Tracker</a></li>
                    <li><a href="https://cloct.threepixels.com.br" target="_blank" rel="noopener noreferrer">C L O C T</a></li>
                  </ul>
                </nav>
              </SectionMapNav>
              <SectionMapNav className="section-map-nav col-12 col-md-2">
                <nav>
                  <ul>
                    <li>Siga-nos</li>
                    <li><a href="https://www.instagram.com/threepixels.sistemas" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" /> Instagram</a></li>
                    <li><a href="https://www.facebook.com/threePixelSistemas" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook" /> Facebook</a></li>
                  </ul>
                </nav>
              </SectionMapNav>
              <SectionLogo className="col-12 col-md-2">
                <Link to={"/"}>
                  <img src={`${process.env.PUBLIC_URL}/images/logo-header.png`} alt="Logo 3Pixels Sistemas" />
                </Link>
              </SectionLogo>
            </Row>
          </Container>
        </SectionMap>
      </Row>
      <Row>
        <SectionLegal className="col">
          <Container>
            {/* <Row>
              <SectionLegalNav className="col">
                <nav>
                  <ul>
                    <li><Link to={`/juridico`}>Jurídico</Link></li>
                    <li className="separator">|</li>
                    <li><Link to={`/juridico/politica-de-privacidade`}>Política de Privacidade</Link></li>
                    <li className="separator">|</li>
                    <li><Link to={`/juridico/politica-de-cookies`}>Política de Cookies</Link></li>
                    <li className="separator">|</li>
                    <li><Link to={`/juridico/termos-de-servico`}>Termos de Serviço</Link></li>
                  </ul>
                </nav>
              </SectionLegalNav>
            </Row> */}
            <Row>
              <SectionLegalCopy className="col">
                <p>Copyright © 2017 - {(new Date()).getFullYear()} Three Pixels Sistemas.<br />Todos os direitos reservados.</p>
              </SectionLegalCopy>
            </Row>
          </Container>
        </SectionLegal>
      </Row>
    </FooterBox>
  );
};

export default Footer;
