import styled from "styled-components";
import { Sizes } from "../Styles/config";
import {
  Col as DefaultCol,
  Row as DefaultRow
} from "../Styles/default";

export const ButtonBudget = styled.div`
  width: 100%;
  margin: auto;
  padding: 30px 15px;
  border-radius: 5px;
  a,
  button {
    width: 100% !important;
  }
`;

export const Row = styled(DefaultRow)`
  padding: 0;
`;

export const RowBox = styled.div`
  padding: 30px;
  margin: 25px 0;
  background: #fff;
  border-radius: 30px;
`;

export const Col = styled(DefaultCol)`
  padding: 0;
  margin: auto;
  @media screen and (max-width: ${Sizes.md}px) {
    margin-bottom: 15px;
  }

  &.col-more-functions {
    padding: 0 0 0 30px;
    @media screen and (max-width: ${Sizes.md}px) {
      padding: 0;
    }
  }
  &.col-more-functions.right {
    padding: 0 30px 0 0;
    @media screen and (max-width: ${Sizes.md}px) {
      padding: 0;
    }
  }
`;

export const ColTitle = styled.div`
  padding: 100px 0 0 0;
  margin:  0 0 10px 0;
  @media screen and (max-width: ${Sizes.lg}px) {
    padding: 80px 0 0 0;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    padding: 64px 0 0 0;
  }
`;

export const Title = styled.h2`
  font-size: 45px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: -.009em;
  color: ${props => props.color || "#1d1d1f"};
  text-align: ${props => props.align || "left"};
  @media screen and (max-width: ${Sizes.lg}px) {
    font-size: 40px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    font-size: 35px;
  }
`;

export const SubTitle = styled.h3`
  font-size: 35px;
  line-height: 1.0625;
  font-weight: 600;
  letter-spacing: -.009em;
  color: ${props => props.color || "#1d1d1f"};
  text-align: ${props => props.align || "left"};
  @media screen and (max-width: ${Sizes.lg}px) {
    font-size: 30px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    font-size: 25px;
  }
`;

export const Description = styled.p`
  font-size: 25px;
  line-height: 1.28583;
  font-weight: 400;
  letter-spacing: .007em;
  color: ${props => props.color || "#1d1d1f"};
  text-align: ${props => props.align || "justify"};
  &:not(:last-child) {
    margin: 0 0 20px 0;
  }
  @media screen and (max-width: ${Sizes.lg}px) {
    font-size: 20px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    font-size: 15px;
  }

  & > strong {
    font-weight: 900;
  }
  & > span {
    color: #1f7c1a;
    font-weight: 900;
    text-decoration: underline;
  }
`;

export const SubDescription = styled.p`
  font-size: 17px;
  line-height: 1.28583;
  font-weight: 400;
  letter-spacing: .007em;
  color: ${props => props.color || "#1d1d1f"};
  text-align: ${props => props.align || "left"};
  &:not(:last-child) {
    margin: 0 0 10px 0;
  }
  @media screen and (max-width: ${Sizes.lg}px) {
    font-size: 16px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    font-size: 15px;
  }
`;