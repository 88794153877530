import Merge from "lodash.merge";

export function clearObject({ data }) {
  var line = 0;
  var lineUndefined = 0;
  for(var col in data) {
    line++;
    if(typeof data[col] !== "undefined") {
      if(data[col] === "") {
        data[col] = undefined;
        lineUndefined++;
      }
      else if(typeof data[col] === "object") {
        data[col] = clearObject({ data: data[col] });
      }
    }
  }
  if(line === lineUndefined) {
    return undefined;
  }
  return data;
}

export function mergeObject(...args) {
  return Merge(...args);
}

export function setObject(obj, path, value) {
  var schema = obj;
  var pList = path.split(".");
  var len = pList.length;
  for(var i = 0; i < len-1; i++) {
    var elem = pList[i];
    if( !schema[elem] ) schema[elem] = {};
    schema = schema[elem];
  }
  schema[pList[len-1]] = value;
}