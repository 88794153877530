import React from "react";
import SEO from "./../SEO";
import {
  Container
} from "react-bootstrap";
import {
  ButtonBudget,
  Row,
  RowBox,
  RowFlex,
  Col,
  ColFlex,
  SubTitle,
  Title,
  Description,
  DescriptionIcon
} from "./styles";

import AnimationBudget from "../Animation/Budget";
import AnimationCarDriver from "../Animation/CarDriver";
import AnimationTruckMoney from "../Animation/TruckMoney";
import ButtonLink from "../ButtonLink";
import FeatureIcon from "./../FeatureIcon";
import MockupIphone from "../MockupIphone";
import MockupMacbook from "../MockupMacbook";
import TitleRow from "./../TitleRow";

const Rdv = () => {
  return (
    <Container className="max-w-3000" fluid>
      <SEO options={{
        tags: {
          title: `Registro de despesas de viagem (RDV) - ${process.env.REACT_APP_APP_TITLE}`,
          description: `Registro de despesas de viagem, ferramentas para gestão financeira da sua empresa, motoristas e veículos.`,
          ogDescription: `Registro de despesas de viagem, ferramentas para gestão financeira da sua empresa, motoristas e veículos.`
        }
      }} />

      <TitleRow
        title={
          <>
            Registro de despesas de viagem (RDV)
          </>
        }
        description={
          <>
            Ferramentas para gestão financeira da sua empresa, motoristas e veículos.
          </>
        }
      />

      <Container>
        <RowBox>
          <Row className="row">
            <Col>
              <Title>Automatize o lançamento de despesas de viagem.</Title>
              <Description>
                Receba os despesas da sua empresa de forma ágil e segura, elimine o processo manual e recibos em papel.
              </Description>
            </Col>
          </Row>
        </RowBox>
      </Container>

      <Container>
        <RowBox background="transparent">
          <Row className="row">
            <ColFlex col={8}>
              <Description>
                O procedimento funciona de forma muito simples através de um aplicativo personalizado com a logomarca da sua empresa que será disponibilizado para Android e iOS.<br /><br />
                Após o acesso, o colaborador poderá lançar gastos de abastecimento e manutenção de acordo com as diretrizes pré estabelecidas pela sua empresa.
              </Description>
            </ColFlex>
            <ColFlex col={4}>
              <DescriptionIcon>
                <AnimationCarDriver />
              </DescriptionIcon>
            </ColFlex>
          </Row>
        </RowBox>
      </Container>

      <Container>
        <RowBox>
          <Row className="row">
            <ColFlex col={4}>
              <DescriptionIcon>
                <AnimationTruckMoney />
              </DescriptionIcon>
            </ColFlex>
            <ColFlex col={8}>
              <Description>
                Em uma completa Plataforma Web, o gestor recebe os lançamentos realizados pelos motoristas e também pode lançar manualmente outras despesas e receitas da empresa.<br /><br />
                Aplicativo personalizado e de fácil manuseio no dia a dia. Nele são cadastrados gastos com manutenções e abastecimentos dos veículos.
              </Description>
            </ColFlex>
          </Row>
        </RowBox>
      </Container>

      <Container>
        <Row>
          <ColFlex>
            <ButtonLink as={ButtonBudget} href="https://api.whatsapp.com/send?phone=5549989237375&text=Ol%C3%A1" target="_blank" rel="noopener noreferrer">Orçamento</ButtonLink>
          </ColFlex>
        </Row>
      </Container>

      <Container>
        <MockupIphone
          align="right"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/rdv/iphone/large/1.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/rdv/iphone/medium/1.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/rdv/iphone/small/1.jpg)`
          }}
          title={
            <>
              Garanta o cumprimento das políticas de gastos em abastecimento!
            </>
          }
          description={
            <>
              Em caso de abastecimento o motorista incluirá data, tipo do combustível, odômetro atual do veículo, valor, litros, forma de pagamento,
              foto da nota fiscal e também poderá incluir algum relato escrito caso queira.
            </>
          }
        />
      </Container>

      <Container>
        <MockupMacbook
          align="left"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/rdv/macbook/large/1.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/rdv/macbook/medium/1.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/rdv/macbook/small/1.jpg)`
          }}
          title={
            <>
              Analise seus gastos com manutenções com facilidade!
            </>
          }
          description={
            <>
              A cada manutenção, o motorista incluirá data, local, qual e serviço foi realizado, odômetro, custo, forma de pagamento, foto da nota fiscal e também poderá incluir algum relato escrito caso queira.
            </>
          }
        />
      </Container>

      <TitleRow
        title={
          <>
            <i className="fa-solid fa-calendar-days" /> Plataforma WEB
          </>
        }
        description={
          <>
            Receba os gastos referentes aos seus veículos em uma plataforma Web, assim você conseguirá saber onde e como o dinheiro da empresa está sendo gasto e realizar as análises necessárias para promover melhorias na frota.
          </>
        }
      />

      <Container>
        <MockupMacbook
          align="right"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/rdv/macbook/large/3.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/rdv/macbook/medium/3.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/rdv/macbook/small/3.jpg)`
          }}
          title={
            <>
              Cadastre receitas e despesas de forma simples!
            </>
          }
          description={
            <>
              Mantenha o seu fluxo de caixa sempre atualizado, cadastre suas despesas e receitas, realize uma análise detalhada por intermédio das ferramentas disponíveis para sua gestão financeira.
            </>
          }
        />
      </Container>

      <Container style={{ marginBottom: "100px" }}>
        <MockupMacbook
          align="left"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/rdv/macbook/large/2.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/rdv/macbook/medium/2.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/rdv/macbook/small/2.jpg)`
          }}
          title={
            <>
              Segurança e transparência sobre seu faturamento!
            </>
          }
          description={
            <>
              Acompanhe seus gastos em tempo real, obtenha as médias dos veículos e garanta mais visibilidade das despesas e receitas de toda sua empresa com o dashboard gerencial.
              Ganhe mais tempo para focar nas tarefas que geram valor para o negócio.
            </>
          }
        />
      </Container>

      <Container style={{ margin: "100px auto auto auto" }}>
        <RowBox>
          <RowFlex>
            <Col>
              <SubTitle align="center">Facilidade para sua gestão financeira.</SubTitle>
            </Col>
          </RowFlex>
          <RowFlex>
            <Col style={{ margin: "0 0 auto 0" }} col={12} colMD={3}>
              <FeatureIcon
                align="center"
                paddingDescription="0 15px"
                iconFree={<img src="/images/rdv/app.webp" alt="App Personalizado" />}
                title={"App Personalizado."}
                description={
                  <>
                    Aplicativo personalizado com a logomarca da sua empresa para ser instalado no celular dos motoristas.
                  </>
                }
              />
            </Col>
            <Col style={{ margin: "0 0 auto 0" }} col={12} colMD={3}>
              <FeatureIcon
                align="center"
                paddingDescription="0 15px"
                iconFree={<img src="/images/rdv/fuel.webp" alt="Abastecimento" />}
                title={"Abastecimento."}
                description={
                  <>
                    Tenha controle dos abastecimentos de seus veículos através dos registros das despesas realizados pelos motoristas.
                  </>
                }
              />
            </Col>
            <Col style={{ margin: "0 0 auto 0" }} col={12} colMD={3}>
              <FeatureIcon
                align="center"
                paddingDescription="0 15px"
                iconFree={<img src="/images/rdv/maintenance.webp" alt="Manutenções" />}
                title={"Manutenções."}
                description={
                  <>
                    Tenha relatórios das manutenções de seus veículos, informações de custo e odômetro e também foto da nota fiscal do serviço.
                  </>
                }
              />
            </Col>
            <Col style={{ margin: "0 0 auto 0" }} col={12} colMD={3}>
              <FeatureIcon
                align="center"
                paddingDescription="0 15px"
                iconFree={<img src="/images/rdv/web.webp" alt="WEB" />}
                title={"WEB."}
                description={
                  <>
                    Gerencie os gastos referentes a sua empresa e seus veículos.
                    Cadastre suas receitas e realize uma análise detalhada por intermédio das ferramentas disponíveis para sua gestão financeira.
                  </>
                }
              />
            </Col>
          </RowFlex>
        </RowBox>
      </Container>

      <Container style={{ margin: "15px auto auto auto" }}>
        <RowBox>
          <RowFlex>
            <ColFlex>
              <SubTitle align="center">Passo a Passo.</SubTitle>
            </ColFlex>
          </RowFlex>
          <RowFlex>
            <Col style={{ margin: "0 0 auto 0" }} col={12} colMD={4}>
              <FeatureIcon
                align="center"
                paddingDescription="0 15px"
                iconFree={<img src="/images/rdv/1.webp" alt="Lançamentos pelo App" />}
                title={"Lançamentos pelo App."}
                description={
                  <>
                    Cada motorista terá em seu celular um App Personalizado para lançamentos de despesas com abastecimento e manutenções,
                    estas informações chegam de forma automática para o gestor financeiro.
                  </>
                }
              />
            </Col>
            <Col style={{ margin: "0 0 auto 0" }} col={12} colMD={4}>
              <FeatureIcon
                align="center"
                paddingDescription="0 15px"
                iconFree={<img src="/images/rdv/2.webp" alt="Plataforma Web" />}
                title={"Plataforma Web."}
                description={
                  <>
                    Em uma completa Plataforma Web, o gestor recebe os lançamentos realizados pelos motoristas e também pode lançar manualmente outras despesas e receitas da empresa.
                  </>
                }
              />
            </Col>
            <Col style={{ margin: "0 0 auto 0" }} col={12} colMD={4}>
              <FeatureIcon
                align="center"
                paddingDescription="0 15px"
                iconFree={<img src="/images/rdv/3.webp" alt="Relatórios" />}
                title={"Relatórios."}
                description={
                  <>
                    Através de gráficos e relatórios você poderá acompanhar os gastos e ganhos da sua empresa, filtrando por veículos, motoristas, linhas cadastradas ou pelos tipos de despesa/receita.
                  </>
                }
              />
            </Col>
          </RowFlex>
        </RowBox>
      </Container>

      <Container>
        <RowBox>
          <Row>
            <Col style={{ margin: "0 0 auto 0" }}>
             <FeatureIcon
                align="center"
                paddingDescription="0 15px"
                title={
                  <>
                    Temos a solução para facilitar a sua gestão financeira.
                  </>
                }
              />
              <Row>
                <Col>
                  <AnimationBudget height="500px" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <ButtonLink as={ButtonBudget} href="https://api.whatsapp.com/send?phone=5549989237375&text=Olá, tenho interesse no sistema para registro de despesas de viagem (RDV)." target="_blank" rel="noopener noreferrer">Orçamento</ButtonLink>
                </Col>
              </Row>
            </Col>
          </Row>
        </RowBox>
      </Container>

    </Container>
  );
};

export default Rdv;
