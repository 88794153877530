import styled from "styled-components";

import { Sizes } from "./../Styles/config";

export const SectionFullRow = styled.div`
  padding: 0;
  margin: ${props => props.margin || "0"};
  & > div:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const SectionSharedRow = styled.div`
  padding: 5px 0;
  background: #fff;
  @media screen and (max-width: ${Sizes.md}px) {
    padding: 0;
  }

  & > div:nth-child(odd) {
    padding: 5px 5px 5px 0;
    @media screen and (max-width: ${Sizes.md}px) {
      padding: 0;
    }
  }
  & > div:nth-child(even) {
    padding: 5px 0 5px 5px;
    @media screen and (max-width: ${Sizes.md}px) {
      padding: 0;
    }
  }
  @media screen and (max-width: ${Sizes.md}px) {
    & > div:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;

export const SectionSharedCol = styled.div`
  padding: 0;
`;

// export const RowSection = styled.div`
//   padding: ${props => props.padding || "0"};
//   margin: ${props => props.margin || "auto auto 15px auto"};
//   background: ${props => props.bg || "transparent"};
//   @media screen and (min-width: ${Sizes.xl}px) {
//     ${props => {
//       const paddingLG = props.paddingLG || "0 15px";
//       return css`
//         padding: ${paddingLG};
//       `;
//     }}
//   }
//   @media screen and (max-width: ${Sizes.md}px) {
//     flex-direction: ${props => props.flexDirectionXS || "column"};
//   }
// `;

// export const Section = styled.div`
//   padding: ${props => props.padding || "100px 0 0 0"};
//   background: ${props => props.bg || "transparent"};
//   margin: ${props => props.margin || "auto"};
//   @media screen and (max-width: ${Sizes.lg}px) {
//     padding: ${props => props.paddingLG || props.padding || "80px 0 0 0"};
//   }
//   @media screen and (max-width: ${Sizes.md}px) {
//     padding: ${props => props.paddingMD || props.padding || "64px 0 0 0"};
//   }
// `;

// export const SectionContainer = styled.div`
//   padding: ${props => props.padding || "0"};
//   display: block;
//   @media screen and (min-width: 3000px) {
//     & > div {
//       padding: ${props => props.padding || "0"};
//       width: 100%;
//       max-width: ${props => props.maxWidth || "100%"};
//       float: ${props => props.float || "right"};
//     }
//   }
// `;

// export const SectionInfo = styled.div`
//   padding: ${props => props.padding || "50px 15px"};
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
//   height: ${props => props.height || "200px"};

//   @media only screen and (max-width: ${Sizes.lg}px) {
//     padding: ${props => props.padding || "40px 15px"};
//   }
//   @media only screen and (max-width: ${Sizes.md}px) {
//     padding: ${props => props.padding || "32px 15px"};
//   }

//   h2,
//   h3,
//   .details {
//     padding: 0;
//     margin: 0 0 15px 0;
//     display: flex;
//     flex: 1;
//   }

//   h2,
//   h3 {
//     color: ${props => props.color || "#1d1d1f"};
//   }

//   h2 {
//     font-size: 56px;
//     line-height: 1.07143;
//     font-weight: 600;
//     letter-spacing: -.005em;
//     text-align: center;
//   }
//   @media only screen and (max-width: ${Sizes.lg}px) {
//     h2 {
//       font-size: 44.8px;
//     }
//   }
//   @media only screen and (max-width: ${Sizes.md}px) {
//     h2 {
//       font-size: 35.84px;
//     }
//   }

//   h3 {
//     font-size: 28px;
//     line-height: 1.10722;
//     font-weight: 400;
//     letter-spacing: .004em;
//     text-align: center;
//   }
//   @media only screen and (max-width: ${Sizes.lg}px) {
//     h3 {
//       font-size: 22.4px;
//     }
//   }
//   @media only screen and (max-width: ${Sizes.md}px) {
//     h3 {
//       font-size: 17.92px;
//     }
//   }

//   a {
//     padding: 5px;
//     margin: 0 5px;
//     font-size: 25px;
//     line-height: 1.10722;
//     font-weight: 400;
//     letter-spacing: .004em;
//     color: #06c;
//     text-align: center;
//   }
//   @media only screen and (max-width: ${Sizes.lg}px) {
//     a {
//       padding: 4px;
//       font-size: 20px;
//     }
//   }
//   @media only screen and (max-width: ${Sizes.md}px) {
//     a {
//       padding: 3.2px;
//       font-size: 16px;
//     }
//   }

//   a:hover {
//     text-decoration: underline;
//   }
// `;

// export const SectionInfoNiple = styled.div`
//   @media only screen and (max-width: ${Sizes.md}px) {
//     padding: ${props => props.padding || "50px 15px"};
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;
//     height: ${props => props.height || "auto"};
//   }
// `;

// export const SectionImage = styled.div`
//   margin: ${props => props.margin || "50px auto auto auto"};
//   width: 100%;
//   height: 400px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   /* overflow: hidden; */
//   background: ${props => props.bg || "transparent"};
//   background-size: ${props => props.bgSize || "fixed"};
//   background-position: ${props => props.bgPos || "center"};

//   div {
//     display: flex;
//   }
//   .image {
//     padding: 0;
//   }
//   .description {
//     padding: 15px 30px;
//     flex: 1;
//     font-size: 25px;
//     line-height: 1.5;
//     font-weight: 400;
//     letter-spacing: -0.374px;
//     color: #86868b;
//     text-align: justify;
//   }
// `;

// /*
//   1235x842 - 100
//   width: 1235px;
//   height: 842px;

//   988x674 - 80
//   width: 988px;
//   height: 674px;

//   494x337 - 40
//   width: 494px;
//   height: 337px;
// */
// export const SectionNiple = styled.div`
//   width: 1235px;
//   height: 842px;
//   pointer-events: none;
//   will-change: transform;
//   display: block;
//   z-index: 1;

//   ${props => {
//     const align = props.align || "left";
//     if (align === "left") {
//       return css`
//         float: right;
//         transform: translateX(${Sizes.Niple.large.alignRight}px) scale(${Sizes.Niple.large.scale});
//       `;
//     }
//     if (align === "right") {
//       return css`
//         float: left;
//         transform: translateX(${Sizes.Niple.large.alignLeft}px) scale(${Sizes.Niple.large.scale});
//       `;
//     }
//   }}

//   & div,
//   &:before {
//     display: block;
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: 1;
//   }

//   /* &:before {
//     content: "";
//     width: 2338px;
//     height: 1584px;
//     background-image: url("${PUBLIC_URL}/images/niple/");
//     background-size: 2338px 1584px;
//     background-repeat: no-repeat;
//     top: -5px;
//     left: -3px;
//   } */

//   @media only screen and (max-width: ${Sizes.lg}px) {
//     width: 988px;
//     height: 674px;

//     ${props => {
//       const align = props.align || "left";
//       if (align === "left") {
//         return css`
//           transform: translateX(${Sizes.Niple.medium.alignRight}px) scale(${Sizes.Niple.medium.scale});
//         `;
//       }
//       if (align === "right") {
//         return css`
//           transform: translateX(${Sizes.Niple.medium.alignLeft}px) scale(${Sizes.Niple.medium.scale});
//         `;
//       }
//     }}

//     /* &:before {
//       width: 1846px;
//       height: 1248px;
//       background-image: url("${PUBLIC_URL}/images/mac/macbook/shadow_medium.png");
//       background-size: 1846px 1248px;
//     } */
//   }

//   @media only screen and (max-width: ${Sizes.md}px) {
//     width: 494px;
//     height: 337px;
//     /* float: none !important;
//     flex: 1 !important;
//     display: flex !important;
//     align-items: center !important;
//     justify-content: center !important;
//     text-align: center; */

//     ${props => {
//       const align = props.align || "left";
//       if (align === "left") {
//         return css`
//           transform: translateX(${Sizes.Niple.small.alignRight}px) scale(${Sizes.Niple.small.scale});
//         `;
//       }
//       if (align === "right") {
//         return css`
//           transform: translateX(${Sizes.Niple.small.alignLeft}px) scale(${Sizes.Niple.small.scale});
//         `;
//       }
//     }}

//     /* &:before {
//       background-image: none !important;
//     } */
//   }
// `;

// export const SectionNipleBg = styled.div`
//   pointer-events: none;
//   z-index: 3 !important;
//   width: 1235px;
//   height: 842px;
//   background-image: url("${PUBLIC_URL}/images/niple/niple-front-back-target-shadow-crop.jpg");
//   background-size: 1235px 842px;
//   background-repeat: no-repeat;
//   background-position: center;
//   /* mask-image: url("${PUBLIC_URL}/images/mac/macbook/mask_large.svg");
//   mask-size: 1892px 1084px;
//   mask-repeat: no-repeat;
//   mask-position: center; */

//   @media only screen and (max-width: ${Sizes.lg}px) {
//     width: 988px;
//     height: 674px;
//     background-image: url("${PUBLIC_URL}/images/niple/niple-front-back-target-shadow-crop.jpg");
//     background-size: 988px 674px;
//     /* mask-image: url("${PUBLIC_URL}/images/mac/macbook/mask_medium.svg");
//     mask-size: 1496px 857px; */
//   }

//   @media only screen and (max-width: ${Sizes.md}px) {
//     width: 494px;
//     height: 337px;
//     background-image: url("${PUBLIC_URL}/images/niple/niple-front-back-target-shadow-crop.jpg");
//     background-size: 494px 337px;
//     /* mask-image: none !important; */
//   };
// `;