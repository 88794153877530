import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  setAnnotation: ["annotation"],
  setAnnotationPlay: ["annotationPlay"],
  setAnnotationPlayBar: ["annotationPlayBar"],
  setAnnotationPause: ["annotationPause"],
  setAnnotations: ["annotations"],
  setApi: ["api"],
  setCameraMoving: ["cameraMoving"],
  setReady: ["ready"]
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  annotation: false,
  annotationPlay: false,
  annotationPlayBar: 0,
  annotationPause: false,
  annotations: [],
  api: false,
  cameraMoving: false,
  ready: false
};

const setAnnotation = (state = INITIAL_STATE, action) => {
  const { annotation } = action;
  return {
    ...state,
    annotation
  };
};

const setAnnotationPlay = (state = INITIAL_STATE, action) => {
  const { annotationPlay } = action;
  return {
    ...state,
    annotationPlay
  };
};

const setAnnotationPlayBar = (state = INITIAL_STATE, action) => {
  const { annotationPlayBar } = action;
  return {
    ...state,
    annotationPlayBar
  };
};

const setAnnotationPause = (state = INITIAL_STATE, action) => {
  const { annotationPause } = action;
  return {
    ...state,
    annotationPause
  };
};

const setAnnotations = (state = INITIAL_STATE, action) => {
  const { annotations } = action;
  return {
    ...state,
    annotations
  };
};

const setApi = (state = INITIAL_STATE, action) => {
  const { api } = action;
  return {
    ...state,
    api
  };
};

const setCameraMoving = (state = INITIAL_STATE, action) => {
  const { cameraMoving } = action;
  return {
    ...state,
    cameraMoving
  };
};

const setReady = (state = INITIAL_STATE, action) => {
  const { ready } = action;
  return {
    ...state,
    ready
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.SET_ANNOTATION]: setAnnotation,
  [Types.SET_ANNOTATION_PLAY]: setAnnotationPlay,
  [Types.SET_ANNOTATION_PLAY_BAR]: setAnnotationPlayBar,
  [Types.SET_ANNOTATION_PAUSE]: setAnnotationPause,
  [Types.SET_ANNOTATIONS]: setAnnotations,
  [Types.SET_API]: setApi,
  [Types.SET_CAMERA_MOVING]: setCameraMoving,
  [Types.SET_READY]: setReady
});