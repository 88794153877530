import styled from "styled-components";
import { Sizes } from "../Styles/config";

const NAV_HEIGHT = Sizes.HeaderProduct.NAV_HEIGHT;
const MIN_WIDTH_HIDE_MENU_MOBILE = Sizes.md;
const MAX_WIDTH_SHOW_MENU_MOBILE = (Sizes.md - 1);

const NAV_BACKGROUND = "rgba(255,255,255,.8)";
const NAV_BACKGROUND_MOB = "rgba(255,255,255,1)";
const NAV_COLOR = "#000000";

export const Nav = styled.nav`
  width: 100vw;
  height: ${NAV_HEIGHT}px;
  background: ${NAV_BACKGROUND};
  border-bottom: 1px solid rgba(0,0,0,.16);
  backdrop-filter: saturate(180%) blur(20px);
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transition: background .36s cubic-bezier(0.32, 0.08, 0.24, 1), height .56s cubic-bezier(0.52, 0.16, 0.24, 1);

  @media screen and (max-width: ${MAX_WIDTH_SHOW_MENU_MOBILE}px) {
    justify-content: flex-start;
    overflow: hidden;
    &.active {
      height: 100vh;
      background: ${NAV_BACKGROUND_MOB};
    }
  }
`;

export const NavContainer = styled.div`
  height: ${NAV_HEIGHT}px;
  display: flex;
  @media screen and (max-width: ${MAX_WIDTH_SHOW_MENU_MOBILE}px) {
    max-width: 100vw;
    padding: 0;
  }
`;

export const NavTitle = styled.ul`
  display: flex;
  max-width: 50%;
  align-items: flex-start;
  justify-content: flex-start;

  li,
  li > a {
    height: ${NAV_HEIGHT}px;
    display: flex;
    align-items: center;
    text-align: center;
  }
  li > a {
    padding: 0 10px;
    font-size: 21px;
    letter-spacing: 0.250px;
    opacity: .8;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    transition: background .36s cubic-bezier(0.32, 0.08, 0.24, 1), opacity .56s cubic-bezier(0.52, 0.16, 0.24, 1);
    color: ${NAV_COLOR};
  }
  @media screen and (max-width: ${Sizes.lg}px) {
    li > a {
      font-size: 17px;
    }
  }
  @media screen and (max-width: ${Sizes.md}px) {
    li > a {
      font-size: 17px;
    }
  }

  li > a:hover {
    opacity: 1;
  }
`;

export const NavList = styled.ul`
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;

  li {
    margin: 0 0 0 15px;
  }
  @media screen and (max-width: ${MAX_WIDTH_SHOW_MENU_MOBILE}px) {
    li {
      margin: 0 5px;
    }
  }

  li,
  li > a {
    height: ${NAV_HEIGHT}px;
    display: flex;
    align-items: center;
    text-align: center;
  }
  li > a {
    padding: 0 10px;
    font-size: 16px;
    letter-spacing: 0.250px;
    opacity: .8;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    transition: background .36s cubic-bezier(0.32, 0.08, 0.24, 1), opacity .56s cubic-bezier(0.52, 0.16, 0.24, 1);
    color: ${NAV_COLOR};
  }
  @media screen and (max-width: ${Sizes.lg}px) {
    li > a {
      font-size: 15px;
    }
  }
  @media screen and (max-width: ${Sizes.md}px) {
    li > a {
      font-size: 14px;
    }
  }

  li > a.hp-button {
    background: #0071e3;
    border: 1px solid rgba(0,0,0,.16);
    border-radius: 15px;
    height: 30px;
    color: #fff;
    font-weight: 900;
  }
  li > a:hover {
    opacity: 1;
  }
`;

export const NavListMenu = styled.a`
  width: 44px;
  height: 44px;
  position: relative;

  & > div {
    width: 44px;
    height: 44px;
  }

  & > div,
  & > div > div  {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
  }

  & > div {
    top: 0;
    bottom: 0;
  }

  & > div > div {
    left: 2px;
    width: 15px;
    height: 1px;
    background: #fff;
    border-radius: 0.5px;
  }
  & > div:first-child > div {
    top: 18px;
  }
  & > div:last-child > div {
    bottom: 18px;
  }

  &.active > div:first-child {
    transform: rotate(45deg);
  }
  &.active > div:first-child > div {
    top: 20px;
  }
  &.active > div:last-child {
    transform: rotate(-45deg);
  }
  &.active > div:last-child > div {
    top: 20px;
  }
`;

export const NavListMobile = styled.ul`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #424245;

  li,
  li > a {
    height: ${NAV_HEIGHT}px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  li > a {
    padding: 0 10px;
    font-size: 16px;
    letter-spacing: -0.374px;
    color: ${NAV_COLOR};
    opacity: .8;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
  }
  li > a:hover {
    opacity: 1;
  }

  @media screen and (min-width: ${MIN_WIDTH_HIDE_MENU_MOBILE}px) {
    display: none;
  }
`;