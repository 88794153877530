import React from "react";
import {
  Container
} from "react-bootstrap";
import {
  Col,
  Description,
  Row,
  Title
} from "./styles";

const TitleRow = ({
  title = "Niple Eletrônico",
  description = "Consiste em um equipamento de alta resistência capaz de indicar momento e local exato de carga ou descarga, suas ações são autônomas checando a passagem do fluxo, resistência testada e aprovada nos ambientes mais agressivos.",
  effect = "blue"
}) => {
  return (
    <Container fluid>
      <Row className="row" effect={effect}>
        <Col>
          <Container>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default TitleRow;