import styled from "styled-components";

import { Sizes } from "./../Styles/config";

const MAX_WIDTH_SHOW_MENU_MOBILE = (Sizes.md - 1);
const MAX_WIDTH_SHOW_FONT_RESPONSIVE = Sizes.sm;

export const FooterBox = styled.div`
  padding: 0;
  width: 100vw;
  background: #f5f5f7;
`;

export const SectionMap = styled.div`
  padding: 15px;
`;

export const SectionLogo = styled.div`
  padding: 15px;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  & > img {
    max-width: 150px;
    display: flex;
    flex: 1;
  }
`;

export const SectionMapNav = styled.div`
  padding: 15px;

  nav {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  nav > ul {
    display: flex;
    flex: 1;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
  @media screen and (max-width: ${MAX_WIDTH_SHOW_MENU_MOBILE}px) {
    border: 0;
    nav > ul {
      padding: 0 15px;
      width: 100vw;
    }
  }

  nav > ul > li {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 0;
    max-width: 100%;
  }
  nav > ul > li,
  nav > ul > li > a,
  nav > ul > li > .dropdown > .dropdown-toggle,
  nav > ul > li > .dropdown > .dropdown-menu > .dropdown-item {
    padding: 0;
    font-size: 13px;
    line-height: 2;
    color: #515154;
    font-weight: 300;
  }
  nav > ul > li > a:hover,
  nav > ul > li > .dropdown > .dropdown-toggle:hover,
  nav > ul > li > .dropdown > .dropdown-menu > .dropdown-item:hover {
    text-decoration: underline;
  }

  nav > ul > li > .dropdown > .dropdown-menu > .dropdown-item {
    justify-content: flex-start;
  }
  nav > ul > li > .dropdown > .dropdown-menu {
    padding: 5px;
  }
  nav > ul > li > .dropdown > .dropdown-toggle:after {
    display: none;
  }
  nav > ul > li > .dropdown > .dropdown-menu {
    min-width: 200px !important;
    max-width: 200px !important;
    left: 0 !important;
    margin-left: 0 !important;
    border: 1px solid #d2d2d7;
    background: #fff;
    z-index: 1;
  }
  @media screen and (max-width: ${MAX_WIDTH_SHOW_MENU_MOBILE}px) {
    nav > ul > li > .dropdown > .dropdown-menu {
      padding: 0 0 0 20px;
      inset: auto !important;
      transform: translate(0, 0) !important;
      margin-left: 0 !important;
      position: relative !important;
      background: transparent !important;
      border: 0 !important;
    }
  }

  .nav-dropdown-caret {
    position: absolute;
    top: -10px;
    left: 10px;
    margin: auto;
    width: 20px;
    height: 10px;
    overflow: hidden;
  }
  [data-popper-placement="top-start"] .nav-dropdown-caret {
    top: auto;
    bottom: -10px;
  }
  .nav-dropdown-caret:after {
    content: "";
    border: 1px solid #d2d2d7;
    background: #fff;
    position: absolute;
    top: 6px;
    left: 0;
    right: 0;
    margin: auto;
    width: 20px;
    height: 20px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  [data-popper-placement="top-start"] .nav-dropdown-caret:after {
    top: auto;
    bottom: 6px;
  }

  nav > ul > li > .dropdown > .dropdown-menu > .dropdown-item:focus,
  nav > ul > li > .dropdown > .dropdown-menu > .dropdown-item:hover {
    background: transparent !important;
  }

  nav > ul > li > .dropdown > .dropdown-menu > .dropdown-item:not(:last-child) {
    border-bottom: 1px solid #d2d2d7;
  }

  @media screen and (max-width: ${MAX_WIDTH_SHOW_MENU_MOBILE}px) {
    nav > ul > li,
    nav > ul > li > a,
    nav > ul > li > .dropdown,
    nav > ul > li > .dropdown > .dropdown-toggle,
    nav > ul > li > .dropdown > .dropdown-menu,
    nav > ul > li > .dropdown > .dropdown-menu > .dropdown-item {
      width: 100%;
      justify-content: flex-start;
      text-align: left;
    }
    .nav-dropdown-li {
      height: auto;
    }
    nav > ul > li > .dropdown > .dropdown-menu .nav-dropdown-caret {
      display: none;
    }
    nav > ul > li:first-child,
    nav > ul > li > .dropdown > .dropdown-menu > .dropdown-item:not(:last-child) {
      border-bottom: 1px solid #d2d2d7;
    }
    nav > ul > li {
      padding: 0;
    }
    nav > ul > li:not(:first-child) {
      padding: 0 10px;
    }
  }

  @media screen and (max-width: ${MAX_WIDTH_SHOW_FONT_RESPONSIVE}px) {
    nav > ul > li,
    nav > ul > li > a,
    nav > ul > li > .dropdown > .dropdown-toggle,
    nav > ul > li > .dropdown > .dropdown-menu > .dropdown-item {
      font-size: 3vw;
    }
  }

  nav > ul > li:first-child {
    font-weight: 400;
  }
  nav > ul > li > a {
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  nav > ul > li:last-child > a {
    border: 0;
  }
`;

export const SectionLegal = styled.div`
  padding: 5px 15px;
`;

export const SectionLegalCopy = styled.div`
  padding: 5px;
  border-top: 1px solid #d2d2d7;

  p {
    font-size: 13px;
    line-height: 2;
    font-weight: 300;
    letter-spacing: .004em;
    color: #515154;
    text-align: center;
  }
  @media screen and (max-width: ${MAX_WIDTH_SHOW_FONT_RESPONSIVE}px) {
    p {
      font-size: 3vw;
    }
  }
`;

export const SectionLegalNav = styled.div`
  padding: 5px;
  border-top: 1px solid #d2d2d7;

  nav > ul {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  @media screen and (max-width: ${MAX_WIDTH_SHOW_MENU_MOBILE}px) {
    nav > ul {
      justify-content: space-between;
    }
    nav > ul > li {
      width: calc(50% - 5px);
      min-width: calc(50% - 5px);
      max-width: calc(50% - 5px);
    }
    nav > ul > li.separator {
      width: 5px;
      min-width: 5px;
      max-width: 5px;
    }
    nav > ul > li:nth-child(4) {
      display: none;
    }
  }

  nav > ul > li,
  nav > ul > li > a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 13px;
    line-height: 2;
    font-weight: 400;
    letter-spacing: .004em;
  }

  nav > ul > li {
    color: #d2d2d7;
  }
  nav > ul > li > a {
    padding: 0 15px;
    color: #515154;
    white-space: nowrap;
  }
  @media screen and (max-width: ${MAX_WIDTH_SHOW_FONT_RESPONSIVE}px) {
    nav > ul > li > a {
      padding: 0 5px;
      font-size: 3vw;
    }
  }

  nav > ul > li:last-child > a {
    border: 0;
  }
`;