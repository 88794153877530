import React from "react";
import { Switch } from "react-router-dom";
import ScrollToTopRoute from "../../ScrollToTopRoute";
import { DfFooter, DfHeader, DfMain, GlobalStyle } from "./../../components/Styles/default";

import MainFooter from "./../../components/Footer";
import MainHeader from "./../../components/Header";
import MainHome from "./../../components/Home";
import MainNotFound from "../../components/NotFound";

const Home = ({ match }) => {
  const path = match.path;
  return (
    <>
      <GlobalStyle />
      <DfHeader background={"#fff"}>
        <MainHeader position={"fixed"} />
      </DfHeader>
      <DfMain padding={"44px 0 0 0"} background={"#fff"}>
        <Switch>
          <ScrollToTopRoute exact path={path} component={MainHome} />
          <ScrollToTopRoute component={MainNotFound} />
        </Switch>
      </DfMain>
      <DfFooter background={"#fff"}>
        <MainFooter />
      </DfFooter>
    </>
  );
};

export default Home;
