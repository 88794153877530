import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { ButtonContainer } from "./styles";

const ButtonLink = React.forwardRef(({ as, children, style, to, variant, href, ...props }, ref) => {
  return (
    <ButtonContainer as={as} style={style} variant={variant}>
      {href ? <a ref={ref} target={props.target} href={href} rel={props.rel}>{children}</a> : null}
      {!href ? <Link ref={ref} to={to} {...props}>{children}</Link> : null}
    </ButtonContainer>
  );
});

ButtonLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  to: PropTypes.string.isRequired
};

export default ButtonLink;