import i18n from "i18next";
import i18nextXHRBackend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(i18nextXHRBackend)
  .use(initReactI18next)
  .init({
    load: "currentOnly",
    lng: "pt-BR",
    backend: {
      loadPath: "/assets/i18n/{{ns}}/{{lng}}.json"
    },
    fallbackLng: "pt-BR",
    debug: process.env.REACT_APP_ENVIRONMENT === "production" ? false : true,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ","
    },
    react: {
      wait: true
    },
    supportedLngs: ["pt-BR"]
  });

export default i18n;
