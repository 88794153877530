import { replaceColor } from "lottie-colorify";

import Animation from "./animation.json";
import {
  TruckMoneyBox as TruckMoneyBoxHTML,
  TruckMoney as TruckMoneyHTML
} from "./style";

export const TruckMoney = (props) => {
  let animationData = Animation;
  if (props.replaceColors) {
    props.replaceColors.forEach(({ originalColor, newColor }) => {
      animationData = replaceColor(originalColor, newColor, animationData);
    });
  }
  return (
    <TruckMoneyBoxHTML className={props.className} height={props.height} width={props.width}>
      <TruckMoneyHTML
        height={props.height}
        isClickToPauseDisabled={true}
        options={{
          autoplay: props.autoplay ?? true,
          animationData,
          loop: props.loop ?? true,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
          }
        }}
        speed={props.speed ?? 1}
        width={props.width}
      />
    </TruckMoneyBoxHTML>
  );
};

export default TruckMoney;
