import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { useIntersection } from "react-use";
import SEO from "./../SEO";
import {
  Container
} from "react-bootstrap";
// import {
//   RowSection,
//   Section,
//   SectionContainer,
//   SectionInfo,
//   SectionInfoNiple,
//   SectionImage,
//   SectionNiple,
//   SectionNipleBg
// } from "./styles";
import {
  SectionSharedCol,
  SectionFullRow,
  SectionSharedRow
} from "./styles";

import SectionProduct from "./../SectionProduct";
import SectionProductMockup from "./../SectionProductMockup";
import Mockup3Pixels from "../Mockup3Pixels";
import MockupEletronicNiple from "./../MockupEletronicNiple";

const Home = () => {
  const urbanMobilityRef = useRef(null);
  const urbanMobilityIntersection = useIntersection(urbanMobilityRef, {
    root: null,
    rootMargin: "0px",
    thresold: 1.0
  });

  return (
    <Container className="max-w-3000" fluid>
      <SEO options={{
        tags: {
          title: process.env.REACT_APP_APP_TITLE
        }
      }} />
      <SectionFullRow className="row">
        <SectionSharedCol className="col-12">
          <SectionProductMockup
            align="center-center"
            background={
              <Mockup3Pixels
                align="right"
              />
            }
            description={
              <>
                Desenvolvimento de sistemas para as mais diversas soluções.
              </>
            }
            details={
              <>
                <Link to={"/3pixels"}>Ler mais</Link>
              </>
            }
            href={"/3pixels"}
            size="full"
            title={
              <>
                3Pixels
              </>
            }
            theme="dark-silver"
          />
        </SectionSharedCol>
        <SectionSharedCol className="col-12">
          <SectionProductMockup
            align="center-center"
            background={
              <MockupEletronicNiple
                align="left"
              />
            }
            description={
              <>
                Identificação de carga e descarga.
              </>
            }
            details={
              <>
                <Link to={"/niple-eletronico"}>Ler mais</Link>
                <Link to={"/niple-eletronico/orcamento"}>Orçamento</Link>
                <Link to={"/pdf/autorizacao.pdf"} target="_blank" rel="noopener noreferrer">Autorização</Link>
              </>
            }
            href={"/niple-eletronico"}
            size="full"
            title={
              <>
                Niple Eletrônico
              </>
            }
            theme="light"
          />
        </SectionSharedCol>
        <SectionSharedCol className="col-12">
          <SectionProduct
            background={{
              lg: `url(${process.env.PUBLIC_URL}/images/tracker/large/bg.jpg)`,
              md: `url(${process.env.PUBLIC_URL}/images/tracker/medium/bg.jpg)`,
              xs: `url(${process.env.PUBLIC_URL}/images/tracker/small/bg.jpg)`
            }}
            description={
              <>
                Tenha segurança na hora de gerenciar sua frota.
              </>
            }
            details={
              <>
                <Link to={"/rastreamento"}>Ler mais</Link>
                <Link to={"/rastreamento/orcamento"}>Orçamento</Link>
                <Link to={"/rastreamento/dicas"}>Dicas</Link>
              </>
            }
            href={"/rastreamento"}
            size="full"
            title={
              <>
                Rastreamento
              </>
            }
            theme="dark"
          />
        </SectionSharedCol>
        <SectionSharedCol className="col-12">
          <SectionProduct
            align="top-center"
            description={
              <>
                Tenha praticidade no seu deslocamento.
              </>
            }
            details={
              <>
                <Link to={"/contato"}>Orçamento</Link>
              </>
            }
            href={"/contato"}
            intersection={urbanMobilityIntersection}
            ref={urbanMobilityRef}
            size="full"
            title={
              <>
                Mobilidade urbana
              </>
            }
            theme="dark"
            video={{
              lg: `${process.env.PUBLIC_URL}/images/taxi/large/bg.mp4`,
              md: `${process.env.PUBLIC_URL}/images/taxi/medium/bg.mp4`,
              xs: `${process.env.PUBLIC_URL}/images/taxi/small/bg.mp4`
            }}
          />
        </SectionSharedCol>
      </SectionFullRow>

      <SectionSharedRow className="row">
        <SectionSharedCol className="col-12 col-md-6">
          <SectionProduct
            background={{
              lg: `url(${process.env.PUBLIC_URL}/images/password-removal/large/bg.jpg)`,
              md: `url(${process.env.PUBLIC_URL}/images/password-removal/medium/bg.jpg)`,
              xs: `url(${process.env.PUBLIC_URL}/images/password-removal/small/bg.jpg)`
            }}
            description={
              <>
                Esqueça as filas!
              </>
            }
            details={
              <>
                <Link to={"/retirada-de-senhas"}>Ler mais</Link>
                <Link to={"/retirada-de-senhas/orcamento"}>Orçamento</Link>
              </>
            }
            href={"/retirada-de-senhas"}
            title={
              <>
                Retirada de senhas
              </>
            }
            theme="light"
          />
        </SectionSharedCol>
        <SectionSharedCol className="col-12 col-md-6">
          <SectionProduct
            background={{
              lg: `url(${process.env.PUBLIC_URL}/images/schedules/large/bg.jpg)`,
              md: `url(${process.env.PUBLIC_URL}/images/schedules/medium/bg.jpg)`,
              xs: `url(${process.env.PUBLIC_URL}/images/schedules/small/bg.jpg)`
            }}
            description={
              <>
                Organize seus horários!
              </>
            }
            details={
              <>
                <Link to={"/agendamentos"}>Ler mais</Link>
                <Link to={"/agendamentos/orcamento"}>Orçamento</Link>
              </>
            }
            href={"/agendamentos"}
            title={
              <>
                Agendamentos
              </>
            }
            theme="dark"
          />
        </SectionSharedCol>
        <SectionSharedCol className="col-12 col-md-6">
          <SectionProduct
            background={{
              lg: `url(${process.env.PUBLIC_URL}/images/streaming/large/bg.jpg)`,
              md: `url(${process.env.PUBLIC_URL}/images/streaming/medium/bg.jpg)`,
              xs: `url(${process.env.PUBLIC_URL}/images/streaming/small/bg.jpg)`
            }}
            description={
              <>
                Desenvolvimento de plataforma de streaming incluindo aplicativos para SmartTV!
              </>
            }
            details={
              <>
                <Link to={"/streaming"}>Ler mais</Link>
                <Link to={"/streaming/orcamento"}>Orçamento</Link>
              </>
            }
            href={"/streaming"}
            title={
              <>
                Streaming
              </>
            }
            theme="dark"
          />
        </SectionSharedCol>
        <SectionSharedCol className="col-12 col-md-6">
          <SectionProduct
            background={{
              lg: `url(${process.env.PUBLIC_URL}/images/development/large/bg.jpg)`,
              md: `url(${process.env.PUBLIC_URL}/images/development/medium/bg.jpg)`,
              xs: `url(${process.env.PUBLIC_URL}/images/development/small/bg.jpg)`
            }}
            description={
              <>
                Desenvolvemos as soluções para você!
              </>
            }
            details={
              <>
                <Link to={"/contato"}>Orçamento</Link>
              </>
            }
            href={"/contato"}
            title={
              <>
                Sob medida
              </>
            }
            theme="light"
          />
        </SectionSharedCol>
      </SectionSharedRow>

    </Container>
  );
};

export default Home;