import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  Background,
  BackgroundLink,
  BackgroundVideo,
  BackgroundVideoBackfloor,
  ButtonVideo,
  Box,
  Info
} from "./styles";

import { Sizes } from "./../Styles/config";

const SectionProduct = forwardRef((props, ref) => {
  const {
    align = "top-center",
    background = false,
    description = false,
    details = false,
    href = false,
    intersection = false,
    size = "half",
    title = false,
    theme = "dark",
    video = false
  } = props;

  const videoRef = useRef();
  const [videoPlay, setVideoPlay] = useState(false);
  const [videoPlayManual, setVideoPlayManual] = useState(false);
  const [videoSource, setVideoSource] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const play = () => {
    if (typeof videoRef.current !== "undefined") {
      videoRef.current.muted = true;
      videoRef.current.play();
    }
  };

  const pause = () => {
    if (typeof videoRef.current !== "undefined") {
      videoRef.current.muted = true;
      videoRef.current.pause();
    }
  };

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (video) {
      if ((windowSize.width > Sizes.lg) && (videoSource !== video.lg)) {
        setVideoSource(video.lg);
      }
      else if ((windowSize.width > Sizes.md && windowSize.width <= Sizes.lg) && (videoSource !== video.md)) {
        setVideoSource(video.md);
      }
      else if ((windowSize.width <= Sizes.md) && (videoSource !== video.xs)) {
        setVideoSource(video.xs);
      }
    }
  }, [windowSize]); //eslint-disable-line

  useEffect(() => {
    if (video) {
      if (videoPlay) {
        play();
      }
      else {
        pause();
      }
    }
  }, [videoPlay]); //eslint-disable-line

  useEffect(() => {
    if (intersection) {
      if (video && !videoPlayManual) {
        if (intersection.isIntersecting && !videoPlay) {
          setVideoPlay(true);
        }
        else if(videoPlay) {
          setVideoPlay(false);
        }
      }
    }
  }, [intersection]); //eslint-disable-line

  const renderVideo = ({ size, src }) => (
    <>
      <BackgroundVideoBackfloor />
      <BackgroundVideo loop playsInline ref={videoRef} size={size} src={src}>
        <source src={src} type="video/mp4" />
      </BackgroundVideo>
      <ButtonVideo
        onClick={e => {
          setVideoPlayManual(true);
          setVideoPlay(!videoPlay);
        }}
        theme={theme}
      >
        {
          videoPlay ? (
            <i className="fas fa-pause" />
          ) : (
            <i className="fas fa-play" />
          )
        }
      </ButtonVideo>
    </>
  );

  return (
    <Box ref={ref} size={size} theme={theme}>
      <Info align={align} size={size} theme={theme}>
        {title !== false && <h2>{title}</h2>}
        {description !== false && <h3>{description}</h3>}
        <div>{details}</div>
        {href && <BackgroundLink as={Link} to={href} size={size} />}
      </Info>
      {background !== false && <Background background={background} size={size} />}
      {video !== false && videoSource !== false && renderVideo({ size, src: videoSource })}
    </Box>
  );
});

export default SectionProduct;