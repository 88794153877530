const schema = {
  canonical: window.location.href,
  description: "A 3Pixels é uma empresa que desenvolve sistemas inteligentes proprietários para rastreamento veicular, rastreamento de ativos, detecção de carga e descarga de caminhões tanque, retirada de senhas online, agendamentos de horários, entre outos e também sob demanda.",
  ogLocale: "pt_BR",
  ogType: "website",
  ogTitle: `${process.env.REACT_APP_APP_TITLE}`,
  ogDescription: "A 3Pixels é uma empresa que desenvolve sistemas inteligentes proprietários para rastreamento veicular, rastreamento de ativos, detecção de carga e descarga de caminhões tanque, retirada de senhas online, agendamentos de horários, entre outos e também sob demanda.",
  ogUrl: window.location.href,
  ogSiteName: `${process.env.REACT_APP_APP_TITLE}`,
  ogImage: `${process.env.PUBLIC_URL}/images/logo.png`,
  title: `${process.env.REACT_APP_APP_TITLE}`
};

export default schema;