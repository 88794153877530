import React from "react";
import {
  Background,
  Box
} from "./styles";

const MockupEletronicNiple = ({
  align="left"
}) => {
  return (
    <Box align={align}>
      <Background />
    </Box>
  );
};

export default MockupEletronicNiple;