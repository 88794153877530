import React from "react";
import { Link } from "react-router-dom";
import {
  Container
} from "react-bootstrap";
import {
  BackgroundLink,
  Col,
  Info,
  Row,
} from "./styles";

const SectionProduct = ({
  align = "top-center",
  background = false,
  description = false,
  details = false,
  href = false,
  size = "half",
  title = false,
  theme = "dark"
}) => {
  return (
    <Container fluid>
      <Row className="row" size={size} theme={theme}>
        <Col className="col-12 col-md-6">{background}</Col>
        <Col className="col-info col-12 col-md-6">
          <Info align={align} size={size} theme={theme}>
            {title !== false && <h2>{title}</h2>}
            {description !== false && <h3>{description}</h3>}
            <div>{details}</div>
            {href && <BackgroundLink as={Link} to={href} size={size} />}
          </Info>
        </Col>
      </Row>
    </Container>
  );
};

export default SectionProduct;