import React from "react";
import {
  Container
} from "react-bootstrap";
import {
  Col,
  Description,
  Macbook,
  MacbookBackground,
  MacbookWindow,
  Row,
  Title
} from "./styles";

const MockupMacbook = ({
  align = "left",
  color = "#3078e3",
  background = {
    lg: `url(${process.env.PUBLIC_URL}/images/niple/ntracker/macbook/large/1.jpg)`,
    md: `url(${process.env.PUBLIC_URL}/images/niple/ntracker/macbook/medium/1.jpg)`,
    xs: `url(${process.env.PUBLIC_URL}/images/niple/ntracker/macbook/small/1.jpg)`
  },
  description = "Teste",
  title = "Teste"
}) => {
  const MacbookRender = () => (
    <Macbook align={align}>
      <MacbookBackground />
      <MacbookWindow
        background={background}
      />
    </Macbook>
  );

  const DescriptionRender = ({ color, padding }) => (
    <>
      <Title color={color} padding={padding}>{title}</Title>
      <Description padding={padding}>{description}</Description>
    </>
  );

  return (
    <Container fluid>
      <Row className="row" align={align}>
        <Col className="col-12 col-md-6">
          <MacbookRender />
        </Col>
        <Col className="col-12 col-md-6">
          <DescriptionRender color={color} padding={"0 15px"} />
        </Col>
      </Row>
    </Container>
  );
};

export default MockupMacbook;