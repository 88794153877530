import styled, { createGlobalStyle, css } from "styled-components";

export const ModalGlobalStyle = createGlobalStyle`
  html,
  body {
    ${props => {
      const open = props.open || false;
      if (open) {
        return css`
          overflow: hidden;
        `;
      }
    }}
  }
`;

export const ModalBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.5);
  z-index: 1;
`;

export const ModalBody = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 44px;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: calc(100% - 44px);
  z-index: 100;
  display: none;
  ${props => {
    const open = props.open || false;
    if (open) {
      return css`
        display: block;
      `;
    }
  }}
`;

export const ModalContent = styled.div`
  max-width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  background: #000;
  height: 44px;
  & > div {
    display: flex;
  }
`;

export const ModalHeaderAction = styled.div`
  display: flex;
  width: 44px;
  align-items: center;
  justify-content: center;
  button {
    width: 44px;
    height: 44px;
    background: var(--black-dark-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: var(--white-color);
    &:hover {
      background: var(--black-color);
    }
  }
`;

export const ModalHeaderTitle = styled.div`
  padding: 0 15px;
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;

  h2 {
    padding: 0;
    margin: 0;
    display: block;
    font-size: 20px;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;