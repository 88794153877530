import React, { useState } from "react";
import MetaTags from "react-meta-tags";

import TagsSchema from "./TagsSchema";
import { mergeObject } from "./../../utils/Object";

const SEO = ({
  options = { tags: {} }
}) => {
  const {
    tags: __tags = {},
  } = options;

  const [tags] = useState(mergeObject(JSON.parse(JSON.stringify(TagsSchema)), __tags));

  return (
    <MetaTags>
      <title>{tags.title}</title>
      <link rel="canonical" href={tags.canonical} />
      <meta name="description" content={tags.description} />
      <meta property="og:locale" content={tags.ogLocale}></meta>
      <meta property="og:type" content={tags.ogType}></meta>
      <meta property="og:title" content={tags.ogTitle} />
      <meta property="og:description" content={tags.ogDescription}></meta>
      <meta property="og:url" content={tags.ogUrl}></meta>
      <meta property="og:site_name" content={tags.ogSiteName}></meta>
      <meta property="og:image" content={tags.ogImage} />
    </MetaTags>
  );
};

export default SEO;