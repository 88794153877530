import React from "react";
import { Link } from "react-router-dom";
import SEO from "./../../SEO";
import {
  Container
} from "react-bootstrap";
import {
  NavList,
  Row,
  RowBox,
  Col,
  ColTitle,
  Title,
  SubDescription,
  Description
} from "./styles";

import TitleRow from "./../../TitleRow";

const Legal = () => {
  return (
    <Container style={{ background: "#f5f5f7" }} fluid>
      <SEO options={{
        tags: {
          title: `Lei 17.082/2017 | Niple Eletrônico - ${process.env.REACT_APP_APP_TITLE}`
        }
      }} />

      <TitleRow
        title={
          <>
            Lei 17.082/2017
          </>
        }
        description={
          <>
            Desde 2017, além da validação e autorização aos veículos limpa-fossa que prestam serviço em Santa Catarina, é necessário também,
            que suas atividades sejam monitoradas por sistema de geoposicionamento, obrigação imposta pela Lei 17.082/2017 e
            regulamentada pelo Decreto 469/2020.
          </>
        }
      />

      <Container>
        <Row className="row">
          <ColTitle className="col">
            <Title>Integrado ao Sistema de Monitoramento do IMA - Instituto do Meio Ambiente de Santa Catarina.</Title>
          </ColTitle>
        </Row>
        <RowBox>
          <Row className="row">
            <Col className="col">
              <Description>
                Art. 1º Os caminhões limpa fossa que prestam serviço em Santa Catarina, mesmo que registrado em outro estado da federação,
                deverão contar com dispositivo de geoposicionamento (GPS) que possa identificar a hora e o local aonde foi feito o despejo dos
                dejetos recolhidos, bem como produzir relatório dessa atividade.
              </Description>
              <Description>
                A validação e autorização são necessárias aos veículos limpa-fossa que prestam serviço em Santa Catarina e que, desde 2017,
                devem possuir suas atividades monitoradas por sistema de geoposicionamento, obrigação imposta pela Lei 17.082/2017 e
                regulamentada pelo Decreto 469/2020.
              </Description>
              <SubDescription>Fonte: <a href="https://www.ima.sc.gov.br/index.php/noticias/1502-prorrogado-prazo-para-regularizacao-de-veiculos-limpa-fossa-em-santa-catarina" target="_blank" rel="noopener noreferrer">https://www.ima.sc.gov.br/index.php/noticias/1502-prorrogado-prazo-para-regularizacao-de-veiculos-limpa-fossa-em-santa-catarina</a></SubDescription>
            </Col>
          </Row>
        </RowBox>
      </Container>

      <Container>
        <Row className="row">
          <Col className="col">
            <NavList>
              <li>
                <Link className="hp-button" to={"/pdf/autorizacao.pdf"} target="_blank" rel="noopener noreferrer">Autorização da Three Pixels Sistemas</Link>
              </li>
            </NavList>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="row">
          <ColTitle className="col">
            <Title>Autorizada.</Title>
          </ColTitle>
        </Row>
        <RowBox>
          <Row className="row">
            <Col className="col">
              <Description>
                A empresa <strong>THREE PIXELS SISTEMAS LTDA</strong> está <span>AUTORIZADA</span> a fornecer dispositivos de geoposicionamento em caminhões limpa-fossa que prestam
                serviço em Santa Catarina.
              </Description>
            </Col>
          </Row>
        </RowBox>
      </Container>

      <Container>
        <Row className="row">
          <ColTitle className="col">
            <Title>Processo IMA 14507/2020.</Title>
          </ColTitle>
        </Row>
        <RowBox>
          <Row className="row">
            <Col className="col">
              <Description>
                Os pré-requisitos estabelecidos pela Portaria IMA nº 67/2020 foram considerados cumpridos pela Comissão de Avaliação de Solução de Sensores
                Remotos de Caminhões Limpa-fossa, conforme processo IMA 14507/2020.
              </Description>
            </Col>
          </Row>
        </RowBox>
      </Container>

    </Container>
  );
};

export default Legal;