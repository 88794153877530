import React from "react";

const Video = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <video ref={ref} {...props}>
      {children}
    </video>
  );
});

export default Video;