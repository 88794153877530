import styled from "styled-components";

import { Sizes } from "./../../Styles/config";

export const NavList = styled.ul`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  li {
    margin: 0 0 0 15px;
  }

  li,
  li > a {
    display: flex;
    align-items: center;
    text-align: center;
  }
  li > a {
    padding: 20px;
    font-size: 25px;
    letter-spacing: 0.250px;
    opacity: .8;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    transition: background .36s cubic-bezier(0.32, 0.08, 0.24, 1), opacity .56s cubic-bezier(0.52, 0.16, 0.24, 1);
  }
  @media screen and (max-width: ${Sizes.lg}px) {
    li > a {
      font-size: 20px;
    }
  }
  @media screen and (max-width: ${Sizes.md}px) {
    li > a {
      font-size: 15px;
    }
  }

  li > a.hp-button {
    background: #0071e3;
    border: 1px solid rgba(0,0,0,.16);
    border-radius: 30px;
    color: #fff;
    font-weight: 900;
  }
  li > a:hover {
    opacity: 1;
  }
`;

export const Row = styled.div`
  padding: 0;
`;

export const RowBox = styled.div`
  padding: 30px;
  margin: 25px 0;
  background: #fff;
  border-radius: 30px;
`;

export const Col = styled.div`
  padding: 0;
  margin: auto;
  text-align: center;
  @media screen and (max-width: ${Sizes.md}px) {
    margin-bottom: 15px;
  }
`;

export const ColTitle = styled.div`
  padding: 100px 0 0 0;
  margin:  0 0 10px 0;
  @media screen and (max-width: ${Sizes.lg}px) {
    padding: 80px 0 0 0;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    padding: 64px 0 0 0;
  }
`;

export const Title = styled.h2`
  font-size: 45px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: -.009em;
  color: ${props => props.color || "#1d1d1f"};
  text-align: ${props => props.align || "left"};
  @media screen and (max-width: ${Sizes.lg}px) {
    font-size: 40px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    font-size: 35px;
  }
`;

export const SubTitle = styled.h3`
  font-size: 35px;
  line-height: 1.0625;
  font-weight: 600;
  letter-spacing: -.009em;
  color: ${props => props.color || "#1d1d1f"};
  text-align: ${props => props.align || "left"};
  @media screen and (max-width: ${Sizes.lg}px) {
    font-size: 30px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    font-size: 25px;
  }
`;

export const Description = styled.p`
  font-size: 25px;
  line-height: 1.28583;
  font-weight: 400;
  letter-spacing: .007em;
  color: ${props => props.color || "#1d1d1f"};
  text-align: ${props => props.align || "justify"};
  &:not(:last-child) {
    margin: 0 0 20px 0;
  }
  @media screen and (max-width: ${Sizes.lg}px) {
    font-size: 20px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    font-size: 15px;
  }

  & > strong {
    font-weight: 900;
  }
  & > span {
    color: #1f7c1a;
    font-weight: 900;
    text-decoration: underline;
  }
`;

export const SubDescription = styled.p`
  font-size: 17px;
  line-height: 1.28583;
  font-weight: 400;
  letter-spacing: .007em;
  color: ${props => props.color || "#1d1d1f"};
  text-align: ${props => props.align || "left"};
  &:not(:last-child) {
    margin: 0 0 10px 0;
  }
  @media screen and (max-width: ${Sizes.lg}px) {
    font-size: 16px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    font-size: 15px;
  }
`;