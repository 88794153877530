import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import history from "../../history";

import eletronicNipleInstallation from "./eletronicNipleInstallation";

const appReducer = combineReducers({
  router: connectRouter(history),
  eletronicNipleInstallation
});

const rootReducer = (state, action) => appReducer(state, action);

export default rootReducer;
