import React from "react";
import {
  Box,
  Icon,
  IconFree,
  Title,
  Description
} from "./styles";

const FeatureIcon = ({
  icon,
  iconFree,
  title,
  description,
  paddingTitle = "0",
  paddingDescription = "0 15px 0 0",
  align = "left"
}) => {
  return (
    <Box>
      {
        (icon && (
          <Icon>{icon}</Icon>
        ))
      }
      {
        (iconFree && (
          <IconFree>{iconFree}</IconFree>
        ))
      }
      <Title align={align} padding={paddingTitle}>{title}</Title>
      <Description align={align} padding={paddingDescription}>{description}</Description>
    </Box>
  );
};

export default FeatureIcon;