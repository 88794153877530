import { replaceColor } from "lottie-colorify";

import Animation from "./animation.json";
import {
  TransmissionBox as TransmissionBoxHTML,
  Transmission as TransmissionHTML
} from "./style";

export const Transmission = (props) => {
  let animationData = Animation;
  if (props.replaceColors) {
    props.replaceColors.forEach(({ originalColor, newColor }) => {
      animationData = replaceColor(originalColor, newColor, animationData);
    });
  }
  return (
    <TransmissionBoxHTML className={props.className} height={props.height} width={props.width}>
      <TransmissionHTML
        height={props.height}
        isClickToPauseDisabled={true}
        options={{
          autoplay: props.autoplay ?? true,
          animationData,
          loop: props.loop ?? true,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
          }
        }}
        speed={props.speed ?? 1}
        width={props.width}
      />
    </TransmissionBoxHTML>
  );
};

export default Transmission;
