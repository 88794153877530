import styled from "styled-components";

import { Sizes } from "../../Styles/config";

export const Row = styled.div`
  padding: 0;
`;

export const RowBox = styled.div`
  padding: 15px;
  margin-bottom: 15px;
  @media screen and (max-width: ${Sizes.md}px) {
    padding: 0;
  }

  & > div {
    padding: 30px 15px;
    border: 1px solid #eee;
    background: #eee;
    border-radius: 25px;
    @media screen and (max-width: ${Sizes.md}px) {
      padding: 15px;
    }
  }
`;

export const Col = styled.div`
  padding: 0 15px;
  @media screen and (max-width: ${Sizes.md}px) {
    padding: 0;
  }
`;

export const ColBox = styled.div`
  padding: 30px 15px;
  margin: 25px 0;
  background: #fff;
  border-radius: 25px;
  @media screen and (max-width: ${Sizes.md}px) {
    padding: 15px;
  }

  .btn,
  .form-label {
    font-size: 17px;
    line-height: 1.28583;
    font-weight: 400;
    letter-spacing: .007em;
    color: ${props => props.color || "#1d1d1f"};
    text-align: ${props => props.align || "left"};
    cursor: pointer;

    &:not(:last-child) {
      margin: 0 0 10px 0;
    }
    @media screen and (max-width: ${Sizes.lg}px) {
      font-size: 16px;
    }
    @media screen and (max-width: ${Sizes.md}px) {
      font-size: 15px;
    }
  }
`;

export const SubTitle = styled.p`
  margin: ${props => props.margin || "0 0 15px 0"};
  font-size: 16px;
  line-height: 1.28583;
  font-weight: 900;
  letter-spacing: .007em;
  color: ${props => props.color || "#1d1d1f"};
  text-align: ${props => props.align || "left"};

  &:not(:last-child) {
    margin: 0 0 10px 0;
  }
  @media screen and (max-width: ${Sizes.lg}px) {
    font-size: 15px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    font-size: 14px;
  }
`;