import React from "react";
import SEO from "../SEO";
import {
  Row,
  Container
} from "react-bootstrap";
import { Section, SectionInfo } from "./styles";

const NotFound = () => {
  return (
    <Container className="max-w-3000" fluid>
      <SEO options={{
        tags: {
          title: `Página não encontrada - ${process.env.REACT_APP_APP_TITLE}`
        }
      }} />
      <Row>
        <Section className="col">
          <Container>
            <SectionInfo>
              <h2>Esta página não está disponível.</h2>
              <h3>A página que você está procurando não pode ser encontrada.</h3>
            </SectionInfo>
          </Container>
        </Section>
      </Row>
    </Container>
  );
};

export default NotFound;