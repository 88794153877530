import styled, { css } from "styled-components";

import { Sizes } from "../Styles/config";

const PUBLIC_URL = process.env.PUBLIC_URL;

const IphoneSizes = {
  large: {
    alignCenter: 0,
    alignLeft: 235,
    alignRight: -235,
    scale: 1
  },
  medium: {
    alignCenter: 0,
    alignLeft: 142,
    alignRight: -142,
    scale: 1
  },
  small: {
    alignCenter: 0,
    alignLeft: 0,
    alignRight: 0,
    scale: 1
  }
};

export const Row = styled.div`
  padding: 0;

  ${props => {
    const align = props.align || "left";
    if (align === "left") {
      return css`
        flex-direction: row;
      `;
    }
    else if (align === "right") {
      return css`
        flex-direction: row-reverse;
      `;
    }
    else if (align === "center") {
      return css`
        flex-direction: row;
      `;
    }
  }}

  @media screen and (max-width: ${Sizes.md}px) {
    padding: 15px 0;
    flex-direction: column-reverse;
  }
`;

export const Col = styled.div`
  padding: 0;
  margin: auto;
  @media screen and (max-width: ${Sizes.md}px) {
    margin-bottom: 15px;
  }
`;

export const Iphone = styled.div`
  width: 262px;
  height: 528px;
  pointer-events: none;
  will-change: transform;
  display: block;
  z-index: 1;

  ${props => {
    const align = props.align || "left";
    if (align === "left") {
      return css`
        float: right;
        transform: translateX(${IphoneSizes.large.alignRight}px) scale(${IphoneSizes.large.scale});
      `;
    }
    else if (align === "right") {
      return css`
        float: left;
        transform: translateX(${IphoneSizes.large.alignLeft}px) scale(${IphoneSizes.large.scale});
      `;
    }
    else if (align === "center") {
      return css`
        float: none;
        transform: translateX(${IphoneSizes.large.alignCenter}px) scale(${IphoneSizes.large.scale});
      `;
    }
  }}

  & div,
  &:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &:before {
    content: "";
    width: 612px;
    height: 682px;
    background-image: url("${PUBLIC_URL}/images/mac/iphone/large/shadow.png");
    background-size: 612px 682px;
    background-repeat: no-repeat;
    top: -23px;
    left: -175px;
  }

  @media screen and (max-width: ${Sizes.lg}px) {
    width: 209.6px;
    height: 422.4px;

    ${props => {
      const align = props.align || "left";
      if (align === "left") {
        return css`
          transform: translateX(${IphoneSizes.medium.alignRight}px) scale(${IphoneSizes.medium.scale});
        `;
      }
      else if (align === "right") {
        return css`
          transform: translateX(${IphoneSizes.medium.alignLeft}px) scale(${IphoneSizes.medium.scale});
        `;
      }
      else if (align === "center") {
        return css`
          transform: translateX(${IphoneSizes.medium.alignCenter}px) scale(${IphoneSizes.medium.scale});
        `;
      }
    }}

    &:before {
      width: 452px;
      height: 530px;
      background-image: url("${PUBLIC_URL}/images/mac/iphone/medium/shadow.png");
      background-size: 452px 530px;
      left: -128px;
      top: -18px;
    }
  }

  @media screen and (max-width: ${Sizes.md}px) {
    width: 222px;
    height: 446px;
    float: none;
    margin: auto;

    ${props => {
      const align = props.align || "left";
      if (align === "left") {
        return css`
          transform: translateX(${IphoneSizes.small.alignRight}px) scale(${IphoneSizes.small.scale});
        `;
      }
      else if (align === "right") {
        return css`
          transform: translateX(${IphoneSizes.small.alignLeft}px) scale(${IphoneSizes.small.scale});
        `;
      }
      else if (align === "center") {
        return css`
          transform: translateX(${IphoneSizes.small.alignCenter}px) scale(${IphoneSizes.small.scale});
        `;
      }
    }}

    &:before {
      width: 512px;
      height: 574px;
      background-image: url("${PUBLIC_URL}/images/mac/iphone/small/shadow.png");
      background-size: 512px 574px;
      left: -145px;
      top: -20px;
    }
  }
`;

export const IphoneBackground = styled.div`
  pointer-events: none;
  z-index: 3 !important;
  width: 262px;
  height: 528px;
  background-image: url("${PUBLIC_URL}/images/mac/iphone/large/bg.png");
  background-size: 262px 528px;
  background-repeat: no-repeat;
  background-position: center;

  @media screen and (max-width: ${Sizes.lg}px) {
    width: 209.6px;
    height: 422.4px;
    background-image: url("${PUBLIC_URL}/images/mac/iphone/medium/bg.png");
    background-size: 209.6px 422.4px;
  }

  @media screen and (max-width: ${Sizes.md}px) {
    width: 222px;
    height: 446px;
    background-image: url("${PUBLIC_URL}/images/mac/iphone/small/bg.png");
    background-size: 222px 446px;
  };
`;

export const IphoneWindow = styled.div`
  width: 232px;
  height: 504px;
  background-image: ${props => props.background.lg || "transparent"};
  background-size: 232px 504px;
  background-repeat: no-repeat;
  z-index: 2 !important;
  margin-left: 15px;
  margin-top: 10px;
  border-radius: 5px;

  @media screen and (max-width: ${Sizes.lg}px) {
    width: 188.6px;
    height: 402.4px;
    background-image: ${props => props.background.md || "transparent"};
    background-size: 188.6px 402.4px;
    margin-left: 10px;
    margin-top: 10px;
  }

  @media screen and (max-width: ${Sizes.md}px) {
    width: 200px;
    height: 423px;
    background-image: ${props => props.background.xs || "transparent"};
    background-size: 200px 423px;
    margin-left: 11px;
    margin-top: 10px;
  }
`;

export const IphoneWindowVideo = styled.div`
  width: 233px;
  height: 505px;
  z-index: 2 !important;
  margin-left: 14px;
  margin-top: 12px;
  border-radius: 5px;
  overflow: hidden;

  video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  @media screen and (max-width: ${Sizes.lg}px) {
    width: 188.6px;
    height: 402.4px;
    margin-left: 10px;
    margin-top: 10px;
  }

  @media screen and (max-width: ${Sizes.md}px) {
    width: 200px;
    height: 424px;
    margin-left: 11px;
    margin-top: 10px;
  }
`;

export const Title = styled.h2`
  padding: ${props => props.padding || "0"};
  font-size: 64px;
  line-height: 1.0625;
  font-weight: 600;
  letter-spacing: -.009em;
  color: ${props => props.color || "#3078e3"};
  text-align: ${props => props.align || "left"};
  @media screen and (max-width: ${Sizes.lg}px) {
    font-size: 51.2px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    padding: 0;
    font-size: 40.96px;
  }
`;

export const Description = styled.p`
  padding: ${props => props.padding || "0"};
  font-size: 28px;
  line-height: 1.28583;
  font-weight: 400;
  letter-spacing: .007em;
  color: ${props => props.color || "#1d1d1f"};
  text-align: ${props => props.align || "left"};
  @media screen and (max-width: ${Sizes.lg}px) {
    font-size: 22.4px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    padding: 0;
    font-size: 17.92px;
  }
`;