import styled, { css } from "styled-components";
import Lottie from "react-lottie";

export const Streaming = styled(Lottie)`
`;

export const StreamingBox = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  ${(props) => StreamingBoxHeight(props)};
  ${(props) => StreamingBoxWidth(props)};
`;

export const StreamingBoxHeight = (props) => {
  const height = props.height || false;
  if (height) {
    return css`
      height: ${height};
    `;
  }
};

export const StreamingBoxWidth = (props) => {
  const width = props.width || false;
  if (width) {
    return css`
      width: ${width};
    `;
  }
};
