import styled, { css } from "styled-components";

import { Sizes } from "../Styles/config";

const PUBLIC_URL = process.env.PUBLIC_URL;

const scale = {
  large: 0.35,
  medium: 0.4,
  small: 0.715
};

const EletronicNiple = {
  large: {
    width: (1861 * scale.large),
    height: (1520 * scale.large)
  },
  medium: {
    width: (1465 * scale.medium),
    height: (1197 * scale.medium)
  },
  small: {
    width: (616 * scale.small),
    height: (459 * scale.small)
  }
};

const EletronicNipleShadow = {
  large: {
    width: (2140 * scale.large),
    height: (586 * scale.large)
  },
  medium: {
    width: (1846 * scale.medium),
    height: (505 * scale.medium)
  }
};

const EletronicNipleSizes = {
  large: {
    alignLeft: 0,
    alignRight: 0
  },
  medium: {
    alignLeft: 0,
    alignRight: -100
  },
  small: {
    alignLeft: 0,
    alignRight: 50
  }
};

export const Box = styled.div`
  width: ${EletronicNiple.large.width}px;
  height: ${EletronicNiple.large.height}px;
  display: block;
  z-index: 1;
  position: relative;

  ${props => {
    const align = props.align || "left";
    if (align === "left") {
      return css`
        float: right;
        transform: translateX(${EletronicNipleSizes.large.alignRight}px);
      `;
    }
    else if (align === "right") {
      return css`
        float: left;
        transform: translateX(${EletronicNipleSizes.large.alignLeft}px);
      `;
    }
    else if (align === "center") {
      return css`
        float: none;
        transform: translateX(${EletronicNipleSizes.large.alignCenter}px);
      `;
    }
  }}

  & div,
  &:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &:before {
    content: "";
    width: ${EletronicNipleShadow.large.width}px;
    height: ${EletronicNipleShadow.large.height}px;
    background-image: url("${PUBLIC_URL}/images/eletronic-niple/large/shadow.png");
    background-size: ${EletronicNipleShadow.large.width}px ${EletronicNipleShadow.large.height}px;
    background-repeat: no-repeat;
    top: calc(${EletronicNiple.large.height}px - 100px);
    left: 120px;
  }

  @media only screen and (max-width: ${Sizes.lg}px) {
    margin-top: 80px;
    width: ${EletronicNiple.medium.width}px;
    height: ${EletronicNiple.medium.height}px;

    ${props => {
      const align = props.align || "left";
      if (align === "left") {
        return css`
          transform: translateX(${EletronicNipleSizes.medium.alignRight}px);
        `;
      }
      else if (align === "right") {
        return css`
          transform: translateX(${EletronicNipleSizes.medium.alignLeft}px);
        `;
      }
      else if (align === "center") {
        return css`
          transform: translateX(${EletronicNipleSizes.medium.alignCenter}px);
        `;
      }
    }}

    &:before {
      width: ${EletronicNipleShadow.medium.width}px;
      height: ${EletronicNipleShadow.medium.height}px;
      background-image: url("${PUBLIC_URL}/images/eletronic-niple/large/shadow.png");
      background-size: ${EletronicNipleShadow.medium.width}px ${EletronicNipleShadow.medium.height}px;
      top: calc(${EletronicNiple.medium.height}px - 90px);
      left: 90px;
    }
  }

  @media only screen and (max-width: ${Sizes.md}px) {
    margin-top: 45px;
    width: ${EletronicNiple.small.width}px;
    height: ${EletronicNiple.small.height}px;

    ${props => {
      const align = props.align || "left";
      if (align === "left") {
        return css`
          transform: translateX(${EletronicNipleSizes.small.alignRight}px);
        `;
      }
      else if (align === "right") {
        return css`
          transform: translateX(${EletronicNipleSizes.small.alignLeft}px);
        `;
      }
      else if (align === "center") {
        return css`
          transform: translateX(${EletronicNipleSizes.small.alignCenter}px);
        `;
      }
    }}
  }
`;

export const Background = styled.div`
  z-index: 3 !important;
  width: ${EletronicNiple.large.width}px;
  height: ${EletronicNiple.large.height}px;
  background-image: url("${PUBLIC_URL}/images/eletronic-niple/large/bg.jpg");
  background-size: ${EletronicNiple.large.width}px ${EletronicNiple.large.height}px;
  background-repeat: no-repeat;
  background-position: center;
  mask-image: url("${PUBLIC_URL}/images/eletronic-niple/large/mask.svg");
  mask-size: ${EletronicNiple.large.width}px ${EletronicNiple.large.height}px;
  mask-repeat: no-repeat;
  mask-position: center;

  @media only screen and (max-width: ${Sizes.lg}px) {
    width: ${EletronicNiple.medium.width}px;
    height: ${EletronicNiple.medium.height}px;
    background-image: url("${PUBLIC_URL}/images/eletronic-niple/medium/bg.jpg");
    background-size: ${EletronicNiple.medium.width}px ${EletronicNiple.medium.height}px;
    mask-image: url("${PUBLIC_URL}/images/eletronic-niple/medium/mask.svg");
    mask-size: ${EletronicNiple.medium.width}px ${EletronicNiple.medium.height}px;
    mask-repeat: no-repeat;
    mask-position: center;
  }

  @media only screen and (max-width: ${Sizes.md}px) {
    width: ${EletronicNiple.small.width}px;
    height: ${EletronicNiple.small.height}px;
    background-image: url("${PUBLIC_URL}/images/eletronic-niple/small/bg.jpg");
    background-size: ${EletronicNiple.small.width}px ${EletronicNiple.small.height}px;
    mask-image: none !important;
  };
`;