import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { registerServiceWorker } from "./serviceWorker";

const renderApp = () => ReactDOM.render(<App />, document.getElementById("root"));

window.onload = () => {
  setTimeout(() => {
    document.body.setAttribute("data-load", "true");
  }, 5000);
};
window.onbeforeunload = () => {
  //On Before Unload
};
window.onunload = () => {
  document.body.setAttribute("data-load", "false");
};

renderApp();
registerServiceWorker();
