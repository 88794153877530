import { replaceColor } from "lottie-colorify";

import Animation from "./animation.json";
import {
  StreamingBox as StreamingBoxHTML,
  Streaming as StreamingHTML
} from "./style";

export const Streaming = (props) => {
  let animationData = Animation;
  if (props.replaceColors) {
    props.replaceColors.forEach(({ originalColor, newColor }) => {
      animationData = replaceColor(originalColor, newColor, animationData);
    });
  }
  return (
    <StreamingBoxHTML className={props.className} height={props.height} width={props.width}>
      <StreamingHTML
        height={props.height}
        isClickToPauseDisabled={true}
        options={{
          autoplay: props.autoplay ?? true,
          animationData,
          loop: props.loop ?? true,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
          }
        }}
        speed={props.speed ?? 1}
        width={props.width}
      />
    </StreamingBoxHTML>
  );
};

export default Streaming;
