import styled, { css } from "styled-components";
import { Sizes } from "./../Styles/config";

export const VideoRowContainer = styled.div`
  padding: 0;
  position: relative;
  max-height: 624px;
  z-index: 1;
  overflow: hidden;
  video {
    position: relative;
    z-index: 1;
    display: block;
  }
`;

export const VideoRowContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  z-index: 2;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  ${props => {
    const effect = props.effect || "gray";
    if (effect === "blue") {
      return css`
        background: rgba(48,120,227,0.3) radial-gradient(circle, rgba(48,120,227,0.3) 0%, rgba(38,86,163,0.5) 80%);
      `;
    }
    else if (effect === "gray") {
      return css`
        background: rgba(51,51,51,0.3) radial-gradient(circle, rgba(51,51,51,0.3) 0%, rgba(34,34,34,0.5) 80%);
      `;
    }
  }}
`;