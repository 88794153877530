import React from "react";
import { Link } from "react-router-dom";
import SEO from "./../SEO";
import {
  Container
} from "react-bootstrap";
import {
  Row,
  RowBox,
  Col,
  ColSketchfab,
  Title,
  SubTitle,
  Description
} from "./styles";

import EletronicNipleInstallation from "../Viewer/EletronicNipleInstallation";
import FeatureIcon from "./../FeatureIcon";
import MockupMacbook from "./../MockupMacbook";
import TitleRow from "./../TitleRow";

const EletronicNiple = () => {
  return (
    <Container fluid>
      <SEO options={{
        tags: {
          title: `Niple Eletrônico - ${process.env.REACT_APP_APP_TITLE}`,
          description: `Sistema para rastreamento de ativos combinado com Niple Eletrônico para controle de carga
          e descarga, abertura e fechamento de escotilhas de caminhão tanque auto vácuo. Rastreamento integrado ao sistema do IMA.`,
          ogDescription: `Sistema para rastreamento de ativos combinado com Niple Eletrônico para controle de carga
          e descarga, abertura e fechamento de escotilhas de caminhão tanque auto vácuo. Rastreamento integrado ao sistema do IMA.`
        }
      }} />

      <Row className="row">
        <ColSketchfab className="col">
          <EletronicNipleInstallation />
        </ColSketchfab>
      </Row>

      <TitleRow
        title={
          <>
            Niple Eletrônico
          </>
        }
        description={
          <>
            Consiste em um equipamento de alta resistência capaz de indicar momento e local exato de carga ou descarga,
            suas ações são autônomas checando a passagem do fluxo, resistência testada e aprovada nos ambientes mais agressivos.
            <br /><br />
            <Link to={"/niple-eletronico/legal"}>Lei 17.082/2017</Link> |
            <Link to={"/pdf/autorizacao.pdf"} target="_blank" rel="noopener noreferrer">Autorização</Link> |
            <Link to={"/niple-eletronico/orcamento"}>Orçamento</Link>
          </>
        }
      />

      <Container>
        <RowBox>
          <Row className="row">
            <Col className="col">
              <Title>Aplicabilidade e robustez<br />a toda prova.</Title>
              <Description>
                Possui diversos pontos de segurança anti-fraude; fabricado em alumínio usinado com certificação de alta pureza;
                cabeçote eletrônico injetado em ABS com alta resistência e vedação.
              </Description>
              <Description>
                Sua instalação não interfere nas atividades diárias do veículo e não requer nenhuma ação humana para seu funcionamento.
              </Description>
            </Col>
          </Row>
        </RowBox>
      </Container>

      <Container>
        <MockupMacbook
          align="left"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/niple/ntracker/macbook/large/1.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/niple/ntracker/macbook/medium/1.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/niple/ntracker/macbook/small/1.jpg)`
          }}
          title={
            <>
              N-Tracker +<br />Niple-N3a.
            </>
          }
          description={
            <>
              Sistema para rastreamento de ativos combinado com Niple Eletrônico para controle de carga
              e descarga, abertura e fechamento de escotilhas de caminhão tanque auto vácuo.
            </>
          }
        />
      </Container>

      <Container>
        <MockupMacbook
          align="right"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/niple/ntracker/macbook/large/6.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/niple/ntracker/macbook/medium/6.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/niple/ntracker/macbook/small/6.jpg)`
          }}
          title={
            <>
              Instantâneo.
            </>
          }
          description={
            <>
              Atualização instântanea dos ativos rastreados sem necessidade de um operador
              ou de aguardar o período da próxima atualização.
            </>
          }
        />
      </Container>

      <Container>
        <MockupMacbook
          align="left"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/niple/ntracker/macbook/large/14.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/niple/ntracker/macbook/medium/14.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/niple/ntracker/macbook/small/14.jpg)`
          }}
          title={
            <>
              Completo.
            </>
          }
          description={
            <>
              Visualização de uma rota perfeita que é gerada pelo período do tempo e também pelos eventos
              que acontecem no decorrer do deslocamento. Tudo que é feito é armazenado.
            </>
          }
        />
      </Container>

      <Container>
        <MockupMacbook
          align="right"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/niple/ntracker/macbook/large/8.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/niple/ntracker/macbook/medium/8.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/niple/ntracker/macbook/small/8.jpg)`
          }}
          title={
            <>
              Integrado.
            </>
          }
          description={
            <>
              Integrado ao sistema do IMA - Instituto do Meio Ambiente de Santa Catarina, com envio das informações colectadas de deslocamento e das operações
              registradas, incluindo manutenções.
            </>
          }
        />
      </Container>

      <Container>
        <MockupMacbook
          align="left"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/niple/ntracker/macbook/large/17.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/niple/ntracker/macbook/medium/17.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/niple/ntracker/macbook/small/17.jpg)`
          }}
          title={
            <>
              Preciso.
            </>
          }
          description={
            <>
              Registra as cargas e descargas com alta precisão, informando o local, data e hora.
            </>
          }
        />
      </Container>

      <Container>
        <RowBox>
          <Row className="row">
            <Col className="col">
              <Title>Sistema de segurança.</Title>
              <Description>
                Toda nossa tecnologia foi pensada em levar aos nossos clientes tranquilidade e controle absoluto em sua frota, os equipamentos
                possuem diversas metodologias de checagem eletrônica automatizada podendo ser também solicitada remotamente via sistema.
              </Description>
              <Description>
                Os métodos aplicados nas ferramentas de segurança nos garante uma entrega confiável na leitura de todos os dados sem que em momento algum não
                saibamos movimentos não autorizados ou não observados pelo usuário.
              </Description>
            </Col>
          </Row>
        </RowBox>
      </Container>

      <TitleRow
        title={
          <>
            E muito mais...
          </>
        }
        description={
          <>
            Sistema completo para um equipamento robusto.
          </>
        }
      />

      <Container>
        <RowBox>
          <Row className="row">
            <Col className="col">
              <SubTitle>Incluso no sistema.</SubTitle>
            </Col>
          </Row>
          <Row className="row">
            <Col className="col-12 col-md-6">
              <FeatureIcon
                icon={<i className="fas fa-route" />}
                title={"Rota."}
                description={
                  <>
                    Visualização da rota com período selecionável incluindo todas as informações
                    e eventos ocorridos.
                  </>
                }
              />
            </Col>
            <Col className="col-12 col-md-6">
              <FeatureIcon
                icon={<i className="fas fa-bell" />}
                title={"Notificações."}
                description={
                  <>
                    Notificações instantâneas por push e ou e-mail informando os eventos ocorridos.
                    Você pode configurar conforme as suas preferências.
                  </>
                }
              />
            </Col>
          </Row>
          <Row className="row">
            <Col className="col-12 col-md-6">
              <FeatureIcon
                icon={<i className="far fa-file-pdf" />}
                title={"Relatórios."}
                description={
                  <>
                    Relatórios são gerados pelo sistema em segundo plano e você recebe uma notificação quando é
                    concluída a geração. O mesmo fica armazenado para uma visualização futura.
                  </>
                }
              />
            </Col>
            <Col className="col-12 col-md-6">
              <FeatureIcon
                icon={<span>M</span>}
                title={"Manutenção."}
                description={
                  <>
                    Habilite e desabilite à manutenção diretamente no veículo de forma simples.
                    Caso esqueça de desabilitar à manutenção será desabilitada automaticamente com o deslocamento do veículo.
                  </>
                }
              />
            </Col>
          </Row>
        </RowBox>
      </Container>

      <Container>
        <RowBox>
          <Row className="row">
            <Col className="col">
              <SubTitle>Incluso no equipamento.</SubTitle>
            </Col>
          </Row>
          <Row className="row">
            <Col className="col-12 col-md-6">
              <FeatureIcon
                icon={<i className="fas fa-battery-empty" />}
                title={"Bateria."}
                description={
                  <>
                    Produto é acompanhado por baterias e identifica uma possível falha na alimentação externa.
                    Permite que as informações continuem sendo armazenadas e transmitidas.
                  </>
                }
              />
            </Col>
            <Col className="col-12 col-md-6">
              <FeatureIcon
                icon={<span>B</span>}
                title={"Anti-fraude."}
                description={
                  <>
                    Equipamento conta com um sistema anti-fraude que detecta possíveis tentativas de burla
                    tais como abertura do equipamento, rompimento dos cabos, abertura e fechamento de escotilhas entre outras.
                  </>
                }
              />
            </Col>
          </Row>
          <Row className="row">
            <Col className="col-12 col-md-6">
              <FeatureIcon
                icon={<span>D</span>}
                title={"Descarga."}
                description={
                  <>
                    Detecta a saída checando a passagem do fluxo.
                    A detecção acontece até mesmo sem o uso de pressão apenas com a força da gravidade.
                  </>
                }
              />
            </Col>
            <Col className="col-12 col-md-6">
              <FeatureIcon
                icon={<span>C</span>}
                title={"Carga."}
                description={
                  <>
                    Detecta a entrada checando a passagem do fluxo.
                    É necessário que o sistema compreenda o que é uma carga para saber diferenciar de uma descarga.
                  </>
                }
              />
            </Col>
          </Row>
        </RowBox>
      </Container>

      <Container>
        <RowBox>
          <Row className="row">
            <Col className="col">
              <SubTitle>Alguns de nossos clientes.</SubTitle>
            </Col>
          </Row>
          <Row className="row">
            <Col className="col-6 col-md-2">
              <img alt="Aubville" src={`${process.env.PUBLIC_URL}/images/eletronic-niple/clients/aubville.jpg`} />
             </Col>
             <Col className="col-6 col-md-2">
              <img alt="Ecofaq" src={`${process.env.PUBLIC_URL}/images/eletronic-niple/clients/ecofaq.jpg`} />
             </Col>
             <Col className="col-6 col-md-2">
              <img alt="Líder Ambiental" src={`${process.env.PUBLIC_URL}/images/eletronic-niple/clients/lider.jpg`} />
             </Col>
             <Col className="col-6 col-md-2">
              <img alt="Papa Tudo Transportes" src={`${process.env.PUBLIC_URL}/images/eletronic-niple/clients/papa.jpg`} />
             </Col>
             <Col className="col-6 col-md-2">
              <img alt="Pires" src={`${process.env.PUBLIC_URL}/images/eletronic-niple/clients/pires.jpg`} />
             </Col>
             <Col className="col-6 col-md-2">
              <img alt="Esgomil" src={`${process.env.PUBLIC_URL}/images/eletronic-niple/clients/esgomil.jpg`} />
             </Col>
          </Row>
        </RowBox>
      </Container>

    </Container>
  );
};

export default EletronicNiple;