import styled, { css } from "styled-components";
import { Sizes } from "./../Styles/config";

const NAV_HEIGHT = Sizes.Header.NAV_HEIGHT;

export const Row = styled.div`
  padding: 15px;

  ${props => {
    const effect = props.effect || "blue";
    if (effect === "blue") {
      return css`
        background: #3078e3 radial-gradient(circle, #3078e3 0%, #2656a3 80%);
      `;
    }
    else if (effect === "gray") {
      return css`
        background: #333 radial-gradient(circle, #333 0%, #222 80%);
      `;
    }
    else if (effect === "brown") {
      return css`
        background: #a87364 radial-gradient(circle, #a87364 0%, #915a4b 80%);
      `;
    }
  }}

  min-height: min(calc(75vh - ${NAV_HEIGHT}px), 624px);
`;

export const Col = styled.div`
  padding: 0;
  margin: auto;
`;

export const Title = styled.h1`
  margin-bottom: 30px;
  font-size: 80px;
  line-height: 1.05;
  font-weight: 600;
  color: ${props => props.color || "#fff"};
  text-align: ${props => props.align || "center"};
  @media screen and (max-width: ${Sizes.lg}px) {
    font-size: 64px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    font-size: 51.2px;
  }
`;

export const Description = styled.p`
  font-size: 28px;
  line-height: 1.28583;
  font-weight: 400;
  letter-spacing: .007em;
  color: ${props => props.color || "#fff"};
  text-align: ${props => props.align || "center"};
  @media screen and (max-width: ${Sizes.lg}px) {
    font-size: 22.4px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    font-size: 17.92px;
  }

  a {
    margin: 0 10px;
    color: ${props => props.color || "#fff"};
    text-decoration: underline;
  }
`;