import styled, { css } from "styled-components";
import Lottie from "react-lottie";

export const Bugdget = styled(Lottie)`
`;

export const BugdgetBox = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  ${(props) => BugdgetBoxHeight(props)};
  ${(props) => BugdgetBoxWidth(props)};
`;

export const BugdgetBoxHeight = (props) => {
  const height = props.height || false;
  if (height) {
    return css`
      height: ${height};
    `;
  }
};

export const BugdgetBoxWidth = (props) => {
  const width = props.width || false;
  if (width) {
    return css`
      width: ${width};
    `;
  }
};
