import React from "react";
import { Switch } from "react-router-dom";
import ScrollToTopRoute from "../../ScrollToTopRoute";
import { DfFooter, DfHeader, DfMain, GlobalStyle } from "../../components/Styles/default";

import MainFooter from "../../components/Footer";
import MainHeader from "../../components/Header";
import MainHeaderProduct from "../../components/HeaderProduct";
import MainNotFound from "../../components/NotFound";
import MainPasswordRemoval from "../../components/PasswordRemoval";
import MainPasswordRemovalBudget from "../../components/PasswordRemoval/Budget";

const PasswordRemoval = ({ match }) => {
  const path = match.path;
  return (
    <>
      <GlobalStyle />
      <DfHeader>
        <MainHeader />
      </DfHeader>
      <MainHeaderProduct
        navList={[
          {
            title: "Orçamento",
            href: "https://api.whatsapp.com/send?phone=5549989237375&text=Olá, tenho interesse no sistema para retirada de senhas e gerenciamento para autoatendimento.",
            isButton: true,
            isBlank: true
          }
        ]}
        title={{
          title: "Retirada de senhas",
          href: `${process.env.PUBLIC_URL}/retirada-de-senhas`
        }}
      />
      <DfMain>
        <Switch>
          <ScrollToTopRoute exact path={path} component={MainPasswordRemoval} />
          <ScrollToTopRoute exact path={`${path}/orcamento`} component={MainPasswordRemovalBudget} />
          <ScrollToTopRoute component={MainNotFound} />
        </Switch>
      </DfMain>
      <DfFooter>
        <MainFooter />
      </DfFooter>
    </>
  );
};

export default PasswordRemoval;
