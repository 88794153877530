import React from "react";
import SEO from "./../SEO";
import {
  Container
} from "react-bootstrap";
import {
  ButtonBudget,
  Row,
  RowBox,
  Col,
  ColFlex,
  SubTitle,
  Title,
  Description,
  DescriptionIcon
} from "./styles";

import AnimationBudget from "../Animation/Budget";
import AnimationClockCalendar from "../Animation/ClockCalendar";
import AnimationClockFast from "../Animation/ClockFast";
import ButtonLink from "../ButtonLink";
import FeatureIcon from "./../FeatureIcon";
import MockupIphone from "../MockupIphone";
import TitleRow from "./../TitleRow";

const PasswordRemoval = () => {
  return (
    <Container className="max-w-3000" fluid>
      <SEO options={{
        tags: {
          title: `Retirada de senhas - ${process.env.REACT_APP_APP_TITLE}`,
          description: `Sistema para retirada de senhas e gerenciamento para autoatendimento, esqueça as filas no seu estabelecimento, forneça a retirada das senhas online.`,
          ogDescription: `Sistema para retirada de senhas e gerenciamento para autoatendimento, esqueça as filas no seu estabelecimento, forneça a retirada das senhas online.`
        }
      }} />

      <TitleRow
        title={
          <>
            Retirada de senhas
          </>
        }
        description={
          <>
            Sistema para retirada de senhas e gerenciamento para autoatendimento, esqueça as filas no seu estabelecimento, forneça a retirada das senhas online.
          </>
        }
      />

      <Container>
        <RowBox>
          <Row className="row">
            <Col className="col">
              <Title>Integração e flexibilidade.</Title>
              <Description>
                O sistema de senhas online é integrado ao sistema SGA (Sistema de Gerenciamento de Autoatendimento) e possui um painel de configuração para múltiplas unidades, serviços
                e prioridades.
              </Description>
            </Col>
          </Row>
        </RowBox>
      </Container>

      <Container>
        <RowBox background="transparent">
          <Row className="row">
            <ColFlex col={4}>
              <DescriptionIcon>
                <AnimationClockCalendar />
              </DescriptionIcon>
            </ColFlex>
            <ColFlex col={8}>
              <Description>
                É possível configurar o usuário de atendimento específico para um ou mais serviços e identificar o Guichê/Mesa pelo número e pelo andar que está localizado.
              </Description>
            </ColFlex>
          </Row>
        </RowBox>
      </Container>

      <Container>
        <RowBox>
          <Row className="row">
            <ColFlex col={8}>
              <Description>
                Diminua o tempo de espera dos seus clientes com aplicativo personalizado com a identidade visual do seu estabelecimento! Oferecemos o sistema de senhas online em plataforma
                Web, iOS e Android.
              </Description>
            </ColFlex>
            <ColFlex col={4}>
              <DescriptionIcon>
                <AnimationClockFast height="180px" />
              </DescriptionIcon>
            </ColFlex>
          </Row>
        </RowBox>
      </Container>

      <Container>
        <Row>
          <ColFlex>
            <ButtonLink as={ButtonBudget} href="https://api.whatsapp.com/send?phone=5549989237375&text=Ol%C3%A1" target="_blank" rel="noopener noreferrer">Orçamento</ButtonLink>
          </ColFlex>
        </Row>
      </Container>

      <Container>
        <MockupIphone
          align="right"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/password-removal/iphone/large/1.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/password-removal/iphone/medium/1.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/password-removal/iphone/small/1.jpg)`
          }}
          title={
            <>
              Acompanhe as senhas.
            </>
          }
          description={
            <>
              É possível acompanhar o andamento das senhas em tempo real, verificar as próximas que estão em espera e também o histórico das últimas atendidas.
            </>
          }
        />
      </Container>

      <Container>
        <MockupIphone
          align="left"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/password-removal/iphone/large/3.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/password-removal/iphone/medium/3.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/password-removal/iphone/small/3.jpg)`
          }}
          title={
            <>
              Raio de ação.
            </>
          }
          description={
            <>
              É possível parametrizar um raio de ação que o usuário em uma determinada distância consiga emitir uma senha e assim evitar filas no seu estabelecimento.
            </>
          }
        />
      </Container>

      <Container>
        <MockupIphone
          align="right"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/password-removal/iphone/large/2.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/password-removal/iphone/medium/2.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/password-removal/iphone/small/2.jpg)`
          }}
          title={
            <>
              Tempo estimado.
            </>
          }
          description={
            <>
              No momento da retirada da senha é informado o tempo estimado de espera para o atendimento, baseado na quantidade de senhas emitidas e o
              tempo médio de atendimento para determinada prioridade.
            </>
          }
        />
      </Container>

      <Container>
        <RowBox>
          <Row className="row">
            <Col className="col">
              <Title>Atendimento prioritário.</Title>
              <Description>
                É possível configurar uma ou mais prioridades de atendimento para os serviços do seu estabelecimento.
              </Description>
              <Description>
                As senhas prioritárias tem um peso maior que as senhas convencionais e dessa forma são automaticamente encaminhadas para o começo da fila, porém após 15 minutos de espera
                as senhas convencionais ganham prioridade sobre as novas senhas emitidas.
              </Description>
            </Col>
          </Row>
        </RowBox>
      </Container>

      <TitleRow
        title={
          <>
            Customizações
          </>
        }
        description={
          <>
            Podemos incluir customizações no sistema de acordo com a sua necessidade, permitindo criar o painel de senhas personalizado e layout das plataformas.
          </>
        }
      />

      <Container>
        <RowBox>
          <Row className="row">
            <Col className="col">
              <SubTitle>Incluso no sistema.</SubTitle>
            </Col>
          </Row>
          <Row className="row">
            <Col className="col-12 col-md-6">
              <FeatureIcon
                icon={<i className="fas fa-building" />}
                title={"Unidades."}
                description={
                  <>
                    Gerencie de uma ou mais unidades de atendimento.
                  </>
                }
              />
            </Col>
            <Col className="col-12 col-md-6">
              <FeatureIcon
                icon={<i className="fa-solid fa-ticket" />}
                title={"Serviços e prioridades."}
                description={
                  <>
                    Disponibilize serviços para a uma ou mais unidades de atendimento e defina prioridades com pesos diferentes.
                  </>
                }
              />
            </Col>
          </Row>
          <Row className="row">
            <Col className="col-12 col-md-6">
              <FeatureIcon
                icon={<i className="fas fa-user-tag" />}
                title={"Cargos."}
                description={
                  <>
                    Defina nos cargos as permissões de cada usuário dentro do sistema.
                  </>
                }
              />
            </Col>
            <Col className="col-12 col-md-6">
              <FeatureIcon
                icon={<i className="fa-solid fa-users" />}
                title={"Usuários."}
                description={
                  <>
                    Crie usuários ilimitados e defina suas permissões baseada no cargo.
                  </>
                }
              />
            </Col>
          </Row>
          <Row className="row">
            <Col className="col-12 col-md-6">
              <FeatureIcon
                icon={<i className="fa-solid fa-gears" />}
                title={"Administração."}
                description={
                  <>
                    Reinicie as senhas de forma manual ou automática no momento que quiser.
                  </>
                }
              />
            </Col>
            <Col className="col-12 col-md-6">
              <FeatureIcon
                icon={<i className="fas fa-chart-pie" />}
                title={"Estatísticas."}
                description={
                  <>
                    Relatórios para a visualização do tempo médio de espera (TME), tempo médio de deslocamento (TMD), tempo médio de atendimento (TMA),
                    atendimentos concluídos, entre outros que podem ser desenvolvidos conforme sua demanda.
                  </>
                }
              />
            </Col>
          </Row>
        </RowBox>
      </Container>

      <Container>
        <RowBox>
          <Row>
            <Col style={{ margin: "0 0 auto 0" }}>
              <FeatureIcon
                align="center"
                paddingDescription="0 15px"
                title={
                  <>
                    Temos a solução para facilitar a gestão do atendimento do seu estabelecimento.
                  </>
                }
              />
              <Row>
                <Col>
                  <AnimationBudget height="500px" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <ButtonLink as={ButtonBudget} href="https://api.whatsapp.com/send?phone=5549989237375&text=Olá, tenho interesse no sistema para retirada de senhas e gerenciamento para autoatendimento." target="_blank" rel="noopener noreferrer">Orçamento</ButtonLink>
                </Col>
              </Row>
            </Col>
          </Row>
        </RowBox>
      </Container>


    </Container>
  );
};

export default PasswordRemoval;
