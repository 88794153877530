import React, { useRef } from "react";
import {
  Container
} from "react-bootstrap";
import {
  Col,
  Description,
  Iphone,
  IphoneBackground,
  IphoneWindow,
  IphoneWindowVideo,
  Row,
  Title
} from "./styles";

import Video from "../Video";
import VideoSource from "../VideoSource";

const MockupIphone = ({
  align = "left",
  color = "#3078e3",
  background = false,
  backgroundVideo = false,
  description = "Teste",
  title = "Teste"
}) => {
  const videoRef = useRef(null);

  const IphoneRender = () => (
    <Iphone align={align}>
      <IphoneBackground />
      {
        (background && (
          <IphoneWindow
            background={background}
          />
        ))
      }
      {
        (backgroundVideo && (
          <IphoneWindowVideo>
            <Video autoPlay={true} controls={false} loop={true} playsInline={true} muted={true} ref={videoRef}>
              <VideoSource src={backgroundVideo.lg.src} type={backgroundVideo.lg.type} />
            </Video>
          </IphoneWindowVideo>
        ))
      }
    </Iphone>
  );

  const DescriptionRender = ({ color, padding }) => (
    <>
      <Title color={color} padding={padding}>{title}</Title>
      <Description padding={padding}>{description}</Description>
    </>
  );

  return (
    <Container fluid>
      <Row className="row" align={align}>
        <Col className="col-12 col-md-6">
          <IphoneRender />
        </Col>
        <Col className="col-12 col-md-6">
          <DescriptionRender color={color} padding={"0 15px"} />
        </Col>
      </Row>
    </Container>
  );
};

export default MockupIphone;