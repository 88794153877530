import React from "react";
import {
  Background,
  Box
} from "./styles";

const Mockup3Pixels = ({
  align="left"
}) => {
  return (
    <Box align={align}>
      <Background />
    </Box>
  );
};

export default Mockup3Pixels;