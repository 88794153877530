import styled, { css } from "styled-components";

import { Sizes } from "./../Styles/config";

export const Box = styled.div`
  padding: 0;
  margin: 0;
  display: block;

  ${props => {
    const theme = props.theme || "dark";
    if (theme === "light") {
      return css`
        background: #f5f5f7;
      `;
    }
    else {
      return css`
        background: #000;
      `;
    }
  }}

  ${props => {
    const size = props.size || "half";
    if (size === "full") {
      return css`
        height: 624px;
      `;
    }
    else {
      return css`
        height: 580px;
      `;
    }
  }}

  @media screen and (max-width: ${Sizes.lg}px) {
    ${props => {
      const size = props.size || "half";
      if (size === "full") {
        return css`
          height: 617px;
        `;
      }
      else {
        return css`
          height: 490px;
        `;
      }
    }}
  }
  @media screen and (max-width: ${Sizes.md}px) {
    ${props => {
      const size = props.size || "half";
      if (size === "full") {
        return css`
          height: 548px;
        `;
      }
      else {
        return css`
          height: 548px;
        `;
      }
    }}
  }
  overflow: hidden;
  position: relative;
`;

export const Background = styled.div`
  background-image: ${props => props.background.lg || "transparent"};
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  position: absolute;
  border: 0;
  margin: 0;
  padding: 0;
  left: calc(50% + 0px);
  right: auto;
  bottom: 0px;
  top: auto;
  transform: translatex(-50%) translateY(0) scale(1);

  ${props => {
    const size = props.size || "half";
    if (size === "full") {
      return css`
        width: 3010px;
        height: 624px;
        background-size: 3010px 624px;
      `;
    }
    else {
      return css`
        width: 1262px;
        height: 580px;
        background-size: 1262px 580px;
      `;
    }
  }}

  @media screen and (max-width: ${Sizes.lg}px) {
    background-image: ${props => props.background.md || "transparent"};
    ${props => {
      const size = props.size || "half";
      if (size === "full") {
        return css`
          width: 1068px;
          height: 617px;
          background-size: 1068px 617px;
        `;
      }
      else {
        return css`
          width: 516px;
          height: 490px;
          background-size: 516px 490px;
        `;
      }
    }}
  }
  @media screen and (max-width: ${Sizes.md}px) {
    background-image: ${props => props.background.xs || "transparent"};
    ${props => {
      const size = props.size || "half";
      if (size === "full") {
        return css`
          width: 734px;
          height: 548px;
          background-size: 734px 548px;
        `;
      }
      else {
        return css`
          width: 734px;
          height: 548px;
          background-size: 734px 548px;
        `;
      }
    }}
  }
`;

export const BackgroundVideoBackfloor = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: rgba(0,0,0,.5);
  z-index: 2;
`;

export const BackgroundVideo = styled.video`
  z-index: 1;
  position: absolute;
  border: 0;
  margin: 0;
  padding: 0;
  left: calc(50% + 0px);
  right: auto;
  bottom: 0px;
  top: auto;
  transform: translatex(-50%) translateY(0) scale(1);

  ${props => {
    const size = props.size || "half";
    if (size === "full") {
      return css`
        width: 3010px;
        height: 624px;
      `;
    }
    else {
      return css`
        width: 1262px;
        height: 580px;
      `;
    }
  }}

  @media screen and (max-width: ${Sizes.lg}px) {
    ${props => {
      const size = props.size || "half";
      if (size === "full") {
        return css`
          width: 1068px;
          height: 617px;
        `;
      }
      else {
        return css`
          width: 516px;
          height: 490px;
        `;
      }
    }}
  }
  @media screen and (max-width: ${Sizes.md}px) {
    ${props => {
      const size = props.size || "half";
      if (size === "full") {
        return css`
          width: 734px;
          height: 548px;
        `;
      }
      else {
        return css`
          width: 734px;
          height: 548px;
        `;
      }
    }}
  }
`;

export const ButtonVideo = styled.button`
  position: absolute;
  right: 15px;
  bottom: 15px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 5;
  opacity: .8;
  transition: opacity .36s cubic-bezier(0.32, 0.08, 0.24, 1);

  ${props => {
    const theme = props.theme || "dark";
    if (theme === "light") {
      return css`
        border: 1px solid #1d1d1f;
      `;
    }
    else {
      return css`
        border: 1px solid #f2f2f5;
      `;
    }
  }}

  &:hover {
    opacity: 1;
  }

  i,
  img,
  span {
    padding: 0;
    margin: 0;
    display: flex;
    text-align: center;
    font-size: 17px;
    line-height: 1;
    position: relative;
    font-weight: 900;
    ${props => {
      const theme = props.theme || "dark";
      if (theme === "light") {
        return css`
          color: #1d1d1f;
        `;
      }
      else {
        return css`
          color: #f2f2f5;
        `;
      }
    }}
  }
`;

export const Info = styled.div`
  position: absolute;
  padding: 40px;
  width: auto;
  margin: auto;
  z-index: 3;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: visible;

  ${props => {
    const align = props.align || "top-center";
    let alignCss = "";
    if (align === "top-left" || align === "top-center" || align === "top-right") {
      alignCss +=`
        top: 0;
      `;
    }
    if (align === "top-left" || align === "center-left" || align === "bottom-left") {
      alignCss += `
        left: 0;
      `;
    }
    if (align === "top-right" || align === "center-right" || align === "bottom-right") {
      alignCss += `
        right: 0;
      `;
    }
    if (align === "bottom-left" || align === "bottom-center" || align === "bottom-right") {
      alignCss += `
        bottom: 0;
      `;
    }
    if (align === "top-center" || align === "center-center" || align === "bottom-center") {
      alignCss += `
        left: 0;
        right: 0;
      `;
    }
    if (align === "center-left" || align === "center-center" || align === "center-right") {
      alignCss += `
        top: 0;
        bottom: 0;
      `;
    }
    return css`${alignCss}`;
  }}

  @media only screen and (max-width: ${Sizes.md}px) {
    padding: 40px 15px;
  }

  h2,
  h3,
  div {
    padding: 0;
    margin: 0;
    display: flex;
  }

  h2,
  h3 {
    ${props => {
      const theme = props.theme || "dark";
      if (theme === "light") {
        return css`
          color: #1d1d1f;
        `;
      }
      else {
        return css`
          color: #f2f2f5;
        `;
      }
    }}
    margin: 0 0 10px 0;
  }

  h2 {
    line-height: 1.1;
    font-weight: 600;
    letter-spacing: 0;
    text-align: center;
    ${props => {
      const size = props.size || "half";
      if (size === "full") {
        return css`
          font-size: 45px;
        `;
      }
      else {
        return css`
          font-size: 40px;
        `;
      }
    }}
  }
  @media only screen and (max-width: ${Sizes.lg}px) {
    h2 {
      ${props => {
        const size = props.size || "half";
        if (size === "full") {
          return css`
            font-size: 40px;
          `;
        }
        else {
          return css`
            font-size: 35px;
          `;
        }
      }}
    }
  }
  @media only screen and (max-width: ${Sizes.md}px) {
    h2 {
      ${props => {
        const size = props.size || "half";
        if (size === "full") {
          return css`
            font-size: 35px;
          `;
        }
        else {
          return css`
            font-size: 35px;
          `;
        }
      }}
    }
  }

  h3 {
    line-height: 1.2;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
    ${props => {
      const size = props.size || "half";
      if (size === "full") {
        return css`
          font-size: 25px;
        `;
      }
      else {
        return css`
          font-size: 20px;
        `;
      }
    }}
  }
  @media only screen and (max-width: ${Sizes.lg}px) {
    h3 {
      ${props => {
        const size = props.size || "half";
        if (size === "full") {
          return css`
            font-size: 20px;
          `;
        }
        else {
          return css`
            font-size: 15px;
          `;
        }
      }}
    }
  }
  @media only screen and (max-width: ${Sizes.md}px) {
    h3 {
      ${props => {
        const size = props.size || "half";
        if (size === "full") {
          return css`
            font-size: 15px;
          `;
        }
        else {
          return css`
            font-size: 15px;
          `;
        }
      }}
    }
  }

  div > a {
    padding: 5px;
    margin: 0 5px;
    font-size: 20px;
    line-height: 1.10722;
    font-weight: 400;
    letter-spacing: .004em;
    color: #06c;
    text-align: center;
    z-index: 5;
    position: relative;
    ${props => {
      const size = props.size || "half";
      if (size === "full") {
        return css`
          font-size: 25px;
        `;
      }
      else {
        return css`
          font-size: 20px;
        `;
      }
    }}
  }
  @media only screen and (max-width: ${Sizes.lg}px) {
    div > a {
      padding: 4px;
      ${props => {
        const size = props.size || "half";
        if (size === "full") {
          return css`
            font-size: 20px;
          `;
        }
        else {
          return css`
            font-size: 15px;
          `;
        }
      }}
    }
  }

  @media only screen and (max-width: ${Sizes.md}px) {
    div > a {
      padding: 3.2px;
      ${props => {
        const size = props.size || "half";
        if (size === "full") {
          return css`
            font-size: 15px;
          `;
        }
        else {
          return css`
            font-size: 15px;
          `;
        }
      }}
    }
  }

  div > a:hover {
    text-decoration: underline;
  }
`;

export const BackgroundLink = styled.a`
  position: absolute;
  top: 0;
  margin: auto;
  z-index: 4;

  ${props => {
    const size = props.size || "half";
    if (size === "full") {
      return css`
        width: 3010px;
        height: 624px;
      `;
    }
    else {
      return css`
        width: 1262px;
        height: 580px;
      `;
    }
  }}

  @media screen and (max-width: ${Sizes.lg}px) {
    ${props => {
      const size = props.size || "half";
      if (size === "full") {
        return css`
          width: 1068px;
          height: 617px;
        `;
      }
      else {
        return css`
          width: 516px;
          height: 490px;
        `;
      }
    }}
  }
  @media screen and (max-width: ${Sizes.md}px) {
    ${props => {
      const size = props.size || "half";
      if (size === "full") {
        return css`
          width: 734px;
          height: 548px;
        `;
      }
      else {
        return css`
          width: 734px;
          height: 548px;
        `;
      }
    }}
  }
`;