import styled from "styled-components";

export const IframeBlock = styled.div`
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const IframeProgressBar = styled.div`
  width: calc(${props => props.width} - 20px) !important;
  height: 5px;
  position: absolute;
  top: 10px;
  left: 10px;
  margin: auto;
  background: rgba(0,0,0,.3);
  backdrop-filter: saturate(180%) blur(20px);
  border-radius: 4px;
  transition: width .100s ease-in-outs;
`;

export const IframePlayPause = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 20px;
  left: 10px;
  margin: auto;
  background: rgba(0,0,0,.3);
  backdrop-filter: saturate(180%) blur(20px);
  border-radius: 4px;
  transition: width .100s cubic-bezier(0.52, 0.16, 0.24, 1);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & > i {
    font-size: 25px;
    line-height: 1;
    display: flex;
    max-width: 25px;
    max-height: 25px;
  }
`;

export const Iframe = styled.iframe`
  width: calc(100% + 100px) !important;
  height: calc(100% + 200px) !important;
  position: absolute;
  top: -50px;
  left: -50px;
  margin: auto;
`;