import React from "react";
import SEO from "./../SEO";
import {
  Container
} from "react-bootstrap";
import {
  ButtonBudget,
  Col,
  ColFlex,
  Description,
  DescriptionIcon,
  Row,
  RowBox,
  SubTitle,
  Title,
  VideoRowDescription,
  VideoRowTitle
} from "./styles";

import ButtonLink from "../ButtonLink";
import FeatureIcon from "../FeatureIcon";
import MockupImac from "../MockupImac";
import MockupIphone from "../MockupIphone";
import VideoRow from "../VideoRow";

const Tracking = () => {
  return (
    <Container fluid>
      <SEO options={{
        tags: {
          title: `Rastreamento - ${process.env.REACT_APP_APP_TITLE}`,
          description: `Rastreamento veícular para você e para sua empresa, rastreamento em chapecó.`,
          ogDescription: `Rastreamento veícular para você e para sua empresa, rastreamento em chapecó.`
        }
      }} />

      <VideoRow
        autoPlay={true}
        controls={false}
        loop={true}
        playsInline={true}
        muted={true}
        src="/images/tracking/large/bg.mp4"
        type="video/mp4"
      >
        <Container>
          <VideoRowTitle>Rastreamento Veicular</VideoRowTitle>
          <VideoRowDescription>Para você e para sua empresa</VideoRowDescription>
        </Container>
      </VideoRow>

      <Container>
        <RowBox>
          <Row>
            <Col>
              <Title>A 3Pixels.</Title>
              <Description>
                É uma empresa de desenvolvimentos de soluções através de softwares modernos de Chapecó-SC. Atuamos no ramo de Rastreamento Veicular a 7 anos.
                Disponibilizamos Serviços de Rastreamento para todos os tipos de veículos, em tempo real e com segurança.
              </Description>
            </Col>
          </Row>
        </RowBox>
      </Container>

      <Container>
        <RowBox background="transparent">
          <Row>
            <ColFlex col={8}>
              <Description>
                O rastreador veicular é um dispositivo discreto que envia informações do seu veículo para nossa plataforma, a N-Tracker. Através da captura dos dados, é
                possível gerenciar e controlar facilmente sua frota de veículos. Você poderá utilizar um sistema completo com um registro de informações relevantes, que te
                ajudarão a acompanhar o desempenho da sua equipe e aumentar a produtividade da sua frota.
              </Description>
            </ColFlex>
            <ColFlex col={4}>
              <DescriptionIcon>
                <i className="fa fa-location-dot" />
              </DescriptionIcon>
            </ColFlex>
          </Row>
        </RowBox>
      </Container>

      <Container>
        <Row>
          <ColFlex>
            {/* <ButtonLink as={ButtonBudget} to="/rastreamento/orcamento">Orçamento</ButtonLink> */}
            <ButtonLink as={ButtonBudget} href="https://api.whatsapp.com/send?phone=5549989237375&text=Ol%C3%A1" target="_blank" rel="noopener noreferrer">Orçamento</ButtonLink>
          </ColFlex>
        </Row>
      </Container>

      <Container>
        <MockupImac
          align="left"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/niple/ntracker/macbook/large/1.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/niple/ntracker/macbook/medium/1.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/niple/ntracker/macbook/small/1.jpg)`
          }}
          title={
            <>
              N-Tracker +<br />Rastreamento.
            </>
          }
          description={
            <>
              A plataforma N-Tracker foi desenvolvida com o objetivo de facilitar o seu processo de gestão de frotas, seja ela particular ou empresarial.
              Uma plataforma simples e ágil que entrega de forma facilitada todas as principais informações sobre o seu veículo.
            </>
          }
        />
      </Container>

      <Container>
        <MockupIphone
          align="right"
          backgroundVideo={{
            lg: {
              src: `${process.env.PUBLIC_URL}/images/tracking/ntracker/iphone/large/route.mp4`,
              type: "video/mp4"
            },
            md: {
              src: `${process.env.PUBLIC_URL}/images/tracking/ntracker/iphone/large/route.mp4`,
              type: "video/mp4"
            },
            xs: {
              src: `${process.env.PUBLIC_URL}/images/tracking/ntracker/iphone/large/route.mp4`,
              type: "video/mp4"
            }
          }}
          title={
            <>
              Aplicativos <br />N-Tracker.
            </>
          }
          description={
            <>
              A N-Tracker está disponível para os smartphones e tablets que possuem o sistema operacional Android ou iOS, com as mesmas funções disponíveis na Web.
            </>
          }
        />
      </Container>

      <Container style={{ margin: "100px auto auto auto" }}>
        <RowBox>
          <Row>
            <Col>
              <SubTitle>Incluso no sistema.</SubTitle>
            </Col>
          </Row>
          <Row>
            <Col col={12} colMD={6}>
              <FeatureIcon
                icon={<i className="fas fa-route" />}
                title={"Rota."}
                description={
                  <>
                    Visualização da rota com período selecionável incluindo todas as informações
                    e eventos ocorridos.
                  </>
                }
              />
            </Col>
            <Col col={12} colMD={6}>
              <FeatureIcon
                icon={<i className="fas fa-bell" />}
                title={"Notificações."}
                description={
                  <>
                    Notificações instantâneas por push e ou e-mail informando os eventos ocorridos.
                    Você pode configurar conforme as suas preferências.
                  </>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col col={12} colMD={6}>
              <FeatureIcon
                icon={<i className="far fa-file-pdf" />}
                title={"Relatórios."}
                description={
                  <>
                    Relatórios são gerados pelo sistema em segundo plano e você recebe uma notificação quando é
                    concluída a geração. O mesmo fica armazenado para uma visualização futura.
                  </>
                }
              />
            </Col>
            <Col col={12} colMD={6}>
              <FeatureIcon
                icon={<span>M</span>}
                title={"Manutenção."}
                description={
                  <>
                    Habilite e desabilite à manutenção diretamente no veículo de forma simples.
                    Caso esqueça de desabilitar à manutenção será desabilitada automaticamente com o deslocamento do veículo.
                  </>
                }
              />
            </Col>
          </Row>
        </RowBox>
      </Container>

      <Container>
          <Row>
            <Col col={12} colMD={6}>
              <Title>+ Funções</Title>
              <Description>
                Bloqueie seus veículos de maneira imediata, receba alertas em situações de perigo e saiba sempre qual condutor está dirigindo qual veículo!
              </Description>
            </Col>
            <Col className="col-more-functions" col={12} colMD={6}>
              <Container style={{ padding: 0 }}>
                <RowBox>
                  <Row>
                    <Col col={12}>
                      <FeatureIcon
                        icon={<i className="fa-solid fa-lock" />}
                        title={"Bloqueio."}
                        description={
                          <>
                            Um dispositivo faz o bloqueio do veículo por meio do combustível ou pela ignição.
                            O gestor de frotas, ou o próprio condutor, pode fazer o bloqueio em situações de roubo e furto.
                          </>
                        }
                      />
                    </Col>
                    <Col col={12}>
                      <FeatureIcon
                        icon={<i className="fa-solid fa-skull-crossbones" />}
                        title={"Botão de pânico."}
                        description={
                          <>
                            Um dispositivo que ao ser acionado envia uma notificação PUSH para um usuário da sua escolha informando que você está em uma situação de risco.
                          </>
                        }
                      />
                    </Col>
                    <Col col={12}>
                      <FeatureIcon
                        icon={<i className="fa-solid fa-truck" />}
                        title={"Jornada de trabalho."}
                        description={
                          <>
                            Tenha controle da jornada de trabalho de seus motoristas através de um sensor que identifica o início da jornada através do crachá do funcionário.
                          </>
                        }
                      />
                    </Col>
                  </Row>
                </RowBox>
              </Container>
            </Col>
          </Row>
      </Container>

      <Container style={{ margin: "100px auto auto auto" }}>
        <RowBox>
          <Row>
            <Col>
              <SubTitle align="center">Veículos especiais.</SubTitle>
            </Col>
          </Row>
          <Row>
            <Col style={{ margin: "0 0 auto 0" }} col={12} colMD={4}>
              <FeatureIcon
                align="center"
                paddingDescription="0 15px"
                iconFree={<img src="/images/tracking/vessel.jpg" alt="Embarcações" />}
                title={"Embarcações."}
                description={
                  <>
                    O Rastreamento Náutico  é ideal para o monitoramento e rastreamento de seu jet-ski, barco ou de sua lancha, 24 horas por dia, onde eles estiverem
                  </>
                }
              />
            </Col>
            <Col style={{ margin: "0 0 auto 0" }} col={12} colMD={4}>
              <FeatureIcon
                align="center"
                paddingDescription="0 15px"
                iconFree={<img src="/images/tracking/heavy.jpg" alt="Máquinas pesadas" />}
                title={"Máquinas pesadas."}
                description={
                  <>
                    O Rastreamento de Máquinas Agrícolas e Máquinas Pesadas é um investimento essencial para quem quer proteger o patrimônio e melhorar a produtividade.
                  </>
                }
              />
            </Col>
            <Col style={{ margin: "0 0 auto 0" }} col={12} colMD={4}>
              <FeatureIcon
                align="center"
                paddingDescription="0 15px"
                iconFree={<img src="/images/tracking/autovaccum.jpg" alt="Veículo Auto-Vácuo" />}
                title={"Veículos Auto-Vácuo."}
                description={
                  <>
                    A 3Pixels é empresa Homologada pelo IMA-SC para fornecer o sistema de rastreamento para veículos Auto-Vácuo que fazem limpeza de fossa no estado.
                    <ButtonLink as={ButtonBudget} to="/niple-eletronico">Saiba mais</ButtonLink>
                  </>
                }
              />
            </Col>
          </Row>
        </RowBox>
      </Container>

      <Container>
        <RowBox background="transparent">
          <Row>
            <Col style={{ margin: "0 0 auto 0" }} col={12} colMD={6}>
              <FeatureIcon
                align="center"
                paddingDescription="0 15px"
                description={
                  <>
                    Temos a solução para facilitar a gestão da sua frota. Para todos os tipos de negócio.
                  </>
                }
              />
              <Row>
                <Col col={12} colMD={6}>
                  <ButtonLink as={ButtonBudget} href="https://api.whatsapp.com/send?phone=5549989237375&text=Olá, tenho interesse no rastreamento veicular." target="_blank" rel="noopener noreferrer">Orçamento</ButtonLink>
                </Col>
              </Row>
            </Col>
            <Col style={{ margin: "0 0 auto 0" }} col={12} colMD={6}>
              <FeatureIcon
                align="center"
                paddingDescription="0 15px"
                description={
                  <>
                    Quer receber dicas GRÁTIS de como reduzir os custos com a sua frota?
                  </>
                }
              />
              <Row>
                <Col col={12} colMD={6}>
                  <ButtonLink as={ButtonBudget} href="https://api.whatsapp.com/send?phone=5549989237375&text=Olá, tenho interesse em receber dicas grátis de como reduzir os custos da frota." target="_blank" rel="noopener noreferrer">Dicas GRÁTIS</ButtonLink>
                </Col>
              </Row>
            </Col>
          </Row>
        </RowBox>
      </Container>

      <Container>
        <RowBox>
          <Row className="row">
            <Col className="col">
              <SubTitle>Alguns de nossos clientes.</SubTitle>
            </Col>
          </Row>
          <Row className="row">
            <Col className="col-6 col-md-2">
              <img alt="DR Encomendas" src={`${process.env.PUBLIC_URL}/images/tracking/clients/drencomendas.jpg`} />
             </Col>
             <Col className="col-6 col-md-2">
              <img alt="Plasmetal" src={`${process.env.PUBLIC_URL}/images/tracking/clients/plasmetal.jpg`} />
             </Col>
             <Col className="col-6 col-md-2">
              <img alt="Motopoint" src={`${process.env.PUBLIC_URL}/images/tracking/clients/motopoint.jpg`} />
             </Col>
             <Col className="col-6 col-md-2">
              <img alt="Só Água Potável" src={`${process.env.PUBLIC_URL}/images/tracking/clients/soaguapotavel.jpg`} />
             </Col>
          </Row>
        </RowBox>
      </Container>

    </Container>
  );
};

export default Tracking;
