import React, { useState } from "react";
import SEO from "../SEO";
import {
  Alert,
  Button,
  Form,
  Container
} from "react-bootstrap";
import InputMask from "react-input-mask";
import {
  Row,
  Col,
  ColBox
} from "./styles";

import { isCellphone } from "./../../utils/Cellphone";
import { isEmail } from "./../../utils/Email";
import { isValidForm } from "./../../utils/Form";
import { isEmpty } from "./../../utils/String";

import { send } from "./../../services/Email";

import TitleRow from "../TitleRow";

const Contact = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const [running, setRunning] = useState(false);
  const [response, setResponse] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");

  const buttonDisabled = () => {
    if (!running) {
      return false;
    }
    return true;
  };

  const inputDisabled = () => {
    if (!running) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!running) {
      setRunning(true);
      let { valid, message: messageForm } = isValidForm(e);
      let responseForm = false;
      if (!valid) {
        if (!messageForm) {
          messageForm = "Preencha todos os campos destacados, são obrigatórios.";
        }
      }
      else {
        const response = await send({
          data: {
            content: [
              {
                title: "Qual seu nome?",
                message: name,
              },
              {
                title: "Qual seu e-mail?",
                message: email,
              },
              {
                title: "Qual seu celular?",
                message: phone,
              },
              {
                title: "Qual a sua necessidade?",
                message: message
              }
            ],
            from: email,
            name,
            subject: `Contato | ${name}`
          }
        });
        if (response) {
          messageForm = "Os dados foram enviados com sucesso, entraremos em contato assim que possível.";
          responseForm = true;
          setTimeout(() => {
            resetForm();
          }, 1000);
        }
        else {
         messageForm = "Preencha todos os campos destacados, são obrigatórios.";
         responseForm = false;
        }
      }
      setResponse(responseForm);
      setResponseMessage(messageForm);
      if (responseForm === false) {
        setRunning(false);
      }
    }
  };

  const resetForm = () => {
    setResponse(null);
    setResponseMessage("");
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
    setRunning(false);
  };

  return (
    <Container fluid>
      <SEO options={{
        tags: {
          title: `Contato - ${process.env.REACT_APP_APP_TITLE}`
        }
      }} />

      <TitleRow
        title={
          <>
            Contato
          </>
        }
        description={
          <>
            Em que podemos ser úteis?<br />Entre em contato que nós desenvolvemos as soluções para você!
          </>
        }
        effect="gray"
      />

      <Container>
        <Row className="row">
          <ColBox className="col" padding={"15px 0"}>
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="row">
                <Col className="col-12">
                  <Form.Group controlId="form-name">
                    <Form.Label>Qual seu nome?</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Preencha com o seu nome..."
                      onChange={e => setName(e.target.value)}
                      autoComplete="off"
                      value={name}
                      isValid={!isEmpty(name)}
                      isInvalid={isEmpty(name)}
                      disabled={inputDisabled()}
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">Preencha com o seu nome. Exemplo: Pedro Henrique.</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="row">
                <Col className="col-12">
                  <Form.Group controlId="form-email">
                    <Form.Label>Qual seu e-mail?</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Preencha com o seu e-mail..."
                      onChange={e => setEmail(e.target.value)}
                      autoComplete="off"
                      value={email}
                      isValid={!isEmpty(email) && isEmail(email)}
                      isInvalid={isEmpty(email) || !isEmail(email)}
                      disabled={inputDisabled()}
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">Preencha com o seu e-mail. Exemplo: exemplo@email.com.br.</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="row">
                <Col className="col">
                  <Form.Group controlId="form-phone">
                    <Form.Label>Qual seu celular?</Form.Label>
                    <InputMask
                      id="form-phone"
                      className={`form-control ${!isEmpty(phone) && isCellphone(phone) ? "is-valid" : "is-invalid"}`}
                      type="text"
                      placeholder="Preencha com o seu celular..."
                      onChange={e => setPhone(e.target.value)}
                      mask="+99 (99) 99999-9999"
                      autoComplete="off"
                      value={phone}
                      disabled={inputDisabled()}
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">Preencha com o seu celular. Exemplo: +55 (49) 99999-9999.</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="row">
                <Col className="col">
                  <Form.Group controlId="forms-contact-message">
                    <Form.Label>Qual a sua necessidade?</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="10"
                      placeholder="Preencha com a sua necessidade."
                      onChange={e => setMessage(e.target.value)}
                      value={message}
                      isValid={!isEmpty(message)}
                      isInvalid={isEmpty(message)}
                      disabled={inputDisabled()}
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">Preencha com a sua necessidade. Em que podemos ser úteis?</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="row">
                <Col className="col">
                  {
                    response !== null ? (
                      <Alert variant={response ? "success" : "danger"}>{responseMessage}</Alert>
                    ) : null
                  }
                </Col>
              </Row>
              <Row className="row">
                <Col className="col">
                  <Form.Group className="default-form-button">
                    <Button
                      variant="dark"
                      type="submit"
                      disabled={buttonDisabled()}
                    >
                      {running ? "Aguarde..." : "Enviar"}
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </ColBox>
        </Row>
      </Container>

    </Container>
  );
};

export default Contact;
