import React from "react";
import { Switch } from "react-router-dom";
import ScrollToTopRoute from "../../ScrollToTopRoute";
import { DfFooter, DfHeader, DfMain, GlobalStyle } from "../../components/Styles/default";

import MainFooter from "../../components/Footer";
import MainHeader from "../../components/Header";
import MainNotFound from "../../components/NotFound";

const NotFound = () => {
  return (
    <>
      <GlobalStyle />
      <DfHeader>
        <MainHeader position="fixed" />
      </DfHeader>
      <DfMain>
        <Switch>
          <ScrollToTopRoute component={MainNotFound} />
        </Switch>
      </DfMain>
      <DfFooter>
        <MainFooter />
      </DfFooter>
    </>
  );
};

export default NotFound;
