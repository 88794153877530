import styled, { css } from "styled-components";

import { Sizes } from "./../Styles/config";

const PUBLIC_URL = process.env.PUBLIC_URL;

const MacbookSizes = {
  large: {
    alignCenter: 0,
    alignLeft: -380,
    alignRight: 380,
    scale: 0.7165
  },
  medium: {
    alignCenter: 0,
    alignLeft: -300,
    alignRight: 300,
    scale: 0.7165
  },
  small: {
    alignCenter: 0,
    alignLeft: 0,
    alignRight: 150,
    scale: 1
  }
};

export const Row = styled.div`
  padding: 0;

  ${props => {
    const align = props.align || "left";
    if (align === "left") {
      return css`
        flex-direction: row;
      `;
    }
    else if (align === "right") {
      return css`
        flex-direction: row-reverse;
      `;
    }
    else if (align === "center") {
      return css`
        flex-direction: row;
      `;
    }
  }}

  @media screen and (max-width: ${Sizes.md}px) {
    padding: 15px 0;
    flex-direction: column-reverse;
  }
`;

export const Col = styled.div`
  padding: 0;
  margin: auto;
  @media screen and (max-width: ${Sizes.md}px) {
    margin-bottom: 15px;
  }
`;

export const Macbook = styled.div`
  width: 1892px;
  height: 1084px;
  pointer-events: none;
  will-change: transform;
  display: block;
  z-index: 1;

  ${props => {
    const align = props.align || "left";
    if (align === "left") {
      return css`
        float: right;
        transform: translateX(${MacbookSizes.large.alignRight}px) scale(${MacbookSizes.large.scale});
      `;
    }
    else if (align === "right") {
      return css`
        float: left;
        transform: translateX(${MacbookSizes.large.alignLeft}px) scale(${MacbookSizes.large.scale});
      `;
    }
    else if (align === "center") {
      return css`
        float: none;
        transform: translateX(${MacbookSizes.large.alignCenter}px) scale(${MacbookSizes.large.scale});
      `;
    }
  }}

  & div,
  &:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &:before {
    content: "";
    width: 2338px;
    height: 1584px;
    background-image: url("${PUBLIC_URL}/images/mac/macbook/large/shadow.png");
    background-size: 2338px 1584px;
    background-repeat: no-repeat;
    top: -5px;
    left: -3px;
  }

  @media screen and (max-width: ${Sizes.lg}px) {
    width: 1496px;
    height: 857px;

    ${props => {
      const align = props.align || "left";
      if (align === "left") {
        return css`
          transform: translateX(${MacbookSizes.medium.alignRight}px) scale(${MacbookSizes.medium.scale});
        `;
      }
      else if (align === "right") {
        return css`
          transform: translateX(${MacbookSizes.medium.alignLeft}px) scale(${MacbookSizes.medium.scale});
        `;
      }
      else if (align === "center") {
        return css`
          transform: translateX(${MacbookSizes.medium.alignCenter}px) scale(${MacbookSizes.medium.scale});
        `;
      }
    }}

    &:before {
      width: 1846px;
      height: 1248px;
      background-image: url("${PUBLIC_URL}/images/mac/macbook/medium/shadow.png");
      background-size: 1846px 1248px;
    }
  }

  @media screen and (max-width: ${Sizes.md}px) {
    width: 770px;
    height: 524px;

    ${props => {
      const align = props.align || "left";
      if (align === "left") {
        return css`
          transform: translateX(${MacbookSizes.small.alignRight}px) scale(${MacbookSizes.small.scale});
        `;
      }
      else if (align === "right") {
        return css`
          transform: translateX(${MacbookSizes.small.alignLeft}px) scale(${MacbookSizes.small.scale});
        `;
      }
      else if (align === "center") {
        return css`
          transform: translateX(${MacbookSizes.small.alignCenter}px) scale(${MacbookSizes.small.scale});
        `;
      }
    }}

    &:before {
      background-image: none !important;
    }
  }
`;

export const MacbookBackground = styled.div`
  pointer-events: none;
  z-index: 3 !important;
  width: 1892px;
  height: 1084px;
  background-image: url("${PUBLIC_URL}/images/mac/macbook/large/bg.jpg");
  background-size: 1892px 1084px;
  background-repeat: no-repeat;
  background-position: center;
  mask-image: url("${PUBLIC_URL}/images/mac/macbook/large/mask.svg");
  mask-size: 1892px 1084px;
  mask-repeat: no-repeat;
  mask-position: center;

  @media screen and (max-width: ${Sizes.lg}px) {
    width: 1496px;
    height: 857px;
    background-image: url("${PUBLIC_URL}/images/mac/macbook/medium/bg.jpg");
    background-size: 1496px 857px;
    mask-image: url("${PUBLIC_URL}/images/mac/macbook/medium/mask.svg");
    mask-size: 1496px 857px;
  }

  @media screen and (max-width: ${Sizes.md}px) {
    width: 770px;
    height: 524px;
    background-image: url("${PUBLIC_URL}/images/mac/macbook/small/bg.jpg");
    background-size: 770px 524px;
    mask-image: none !important;
  };
`;

export const MacbookWindow = styled.div`
  width: 1482px;
  height: 928px;
  background-image: ${props => props.background.lg || "transparent"};
  background-size: 1482px 928px;
  background-repeat: no-repeat;
  z-index: 4 !important;
  margin-left: 206px;
  margin-top: 50px;

  @media screen and (max-width: ${Sizes.lg}px) {
    width: 1170px;
    height: 733px;
    background-image: ${props => props.background.md || "transparent"};
    background-size: 1170px 733px;
    margin-left: 164px;
    margin-top: 40px;
  }

  @media screen and (max-width: ${Sizes.md}px) {
    width: 488px;
    height: 306px;
    background-image: ${props => props.background.xs || "transparent"};
    background-size: 488px 306px;
    margin-left: 68px;
    margin-top: 16px;
  }
`;

export const Title = styled.h2`
  padding: ${props => props.padding || "0"};
  font-size: 64px;
  line-height: 1.0625;
  font-weight: 600;
  letter-spacing: -.009em;
  color: ${props => props.color || "#3078e3"};
  text-align: ${props => props.align || "left"};
  @media screen and (max-width: ${Sizes.lg}px) {
    font-size: 51.2px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    padding: 0;
    font-size: 40.96px;
  }
`;

export const Description = styled.p`
  padding: ${props => props.padding || "0"};
  font-size: 28px;
  line-height: 1.28583;
  font-weight: 400;
  letter-spacing: .007em;
  color: ${props => props.color || "#1d1d1f"};
  text-align: ${props => props.align || "left"};
  @media screen and (max-width: ${Sizes.lg}px) {
    font-size: 22.4px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    padding: 0;
    font-size: 17.92px;
  }
`;