import React from "react";
import { VideoRowContainer, VideoRowContent } from "./styles";

import Video from "../Video";
import VideoSource from "../VideoSource";

const VideoRow = React.forwardRef(({ children, src, type, ...props }, ref) => {
  return (
    <VideoRowContainer>
      <VideoRowContent>
        {children}
      </VideoRowContent>
      <Video ref={ref} {...props}>
        <VideoSource src={src} type={type} />
      </Video>
    </VideoRowContainer>
  );
});

export default VideoRow;