import React from "react";
import SEO from "./../SEO";
import {
  Container
} from "react-bootstrap";
import {
  ButtonBudget,
  Col,
  ColTitle,
  Description,
  Row,
  RowBox,
  // SubTitle,
  Title
} from "./styles";

import ButtonLink from "../ButtonLink";
import FeatureIcon from "../FeatureIcon";
import TitleRow from "../TitleRow";

const ThreePixels = () => {
  return (
    <Container className="max-w-3000" fluid>
      <SEO options={{
        tags: {
          title: `3Pixels - ${process.env.REACT_APP_APP_TITLE}`
        }
      }} />

      <TitleRow
        title={
          <>
            3Pixels
          </>
        }
        description={
          <>
            Desenvolvimento de sistemas para as mais diversas soluções.
          </>
        }
        effect="gray"
      />

      <Container>
        <Row className="row">
          <ColTitle className="col">
            <Title>Three Pixels.</Title>
          </ColTitle>
        </Row>
        <RowBox>
          <Row className="row">
            <Col className="col">
              <Description>
                Fundada em 2017, tem como objetivo desenvolvimento de sistemas para as mais diversas soluções com
                abrangência nacional e internacional. Com perfil proativo, a Three Pixels está sempre atenta às necessidades do
                mercado e com a experiência de mais de uma década na área de tecnologia.
              </Description>
              <Description>
                Empresa homologada pelo Instituto do Meio Ambiente para fornecer dispositivos de geoposicionamento para caminhões limpa-fossa
                que prestam serviço em Santa Catarina, de acordo com os pré-requisitos estabelecidos pela Portaria IMA no 67/2020.
              </Description>
              <Description>
                Atualmente a THREE PIXELS SISTEMAS está presente nos maiores Cartórios de Santa Catarina com a API de integração para retirada de senhas.
                Em nosso portfólio também temos o Aplicativo para Agendamento! Com seu próprio aplicativo você consegue muito mais agendamentos.
                Seus clientes podem consultar sua agenda e horários disponíveis e marcar um atendimento pra realizar um serviço.
              </Description>
              <Description>
                Tudo muito rápido e prático!
              </Description>
            </Col>
          </Row>
        </RowBox>
      </Container>

      <Container style={{ margin: "100px auto auto auto" }}>
        <Row>
          <Col col={12} colMD={6}>
            <Title>+ Rastreamento</Title>
            <Description>
              Desenvolvemos sistemas para rastreamento e telemetria veicular!
            </Description>
          </Col>
          <Col className="col-more-functions" col={12} colMD={6}>
            <Container style={{ padding: 0 }}>
              <RowBox>
                <Row>
                  <Col col={12}>
                    <FeatureIcon
                      title={"N-Tracker + Niple Eletrônico."}
                      description={
                        <>
                          A 3Pixels é empresa Homologada pelo IMA-SC para fornecer o sistema de rastreamento para veículos Auto-Vácuo que fazem limpeza de fossa no estado.
                          <ButtonLink as={ButtonBudget} to="/niple-eletronico">Saiba mais</ButtonLink>
                        </>
                      }
                      align="center"
                    />
                  </Col>
                  <Col col={12}>
                    <FeatureIcon
                      title={"N-Tracker + Rastreamento."}
                      description={
                        <>
                          Uma plataforma simples e ágil que entrega de forma facilitada todas as principais informações sobre o seu veiculo.
                          <ButtonLink as={ButtonBudget} to="/rastreamento">Saiba mais</ButtonLink>
                        </>
                      }
                      align="center"
                    />
                  </Col>
                </Row>
              </RowBox>
            </Container>
          </Col>
        </Row>
      </Container>

      <Container style={{ margin: "100px auto auto auto" }}>
        <Row>
          <Col className="col-more-functions right" col={12} colMD={6}>
            <Container style={{ padding: 0 }}>
              <RowBox>
                <Row>
                  <Col col={12}>
                    <FeatureIcon
                      title={"Retirada de senhas."}
                      description={
                        <>
                          Com o sistema de controle de senhas é possível fazer a retirada de senhas online sem a necessidade de estar dentro do cartório!
                          Através de aplicativo com nome e identidade visual personalizada!
                          <ButtonLink as={ButtonBudget} to="/contato">Saiba mais</ButtonLink>
                        </>
                      }
                      align="center"
                    />
                  </Col>
                  <Col col={12}>
                    <FeatureIcon
                      title={"Agendamentos."}
                      description={
                        <>
                          Com o sistema de agendamentos é possível fazer o controle da agenda dinâmica do seu estabelecimento, permitindo que seus clientes reservem
                          o horário do serviço e com o profissional que desejam!
                          <ButtonLink as={ButtonBudget} to="/contato">Saiba mais</ButtonLink>
                        </>
                      }
                      align="center"
                    />
                  </Col>
                </Row>
              </RowBox>
            </Container>
          </Col>
          <Col col={12} colMD={6}>
            <Title>+ Organização</Title>
            <Description>
              Desenvolvemos sistemas para organizar a sua agenda e diminuir as filas!
            </Description>
          </Col>
        </Row>
      </Container>

      {/* <Container style={{ margin: "100px auto auto auto" }}>
        <RowBox>
          <Row>
            <Col>
              <SubTitle align="center">+ Conteúdo</SubTitle>
            </Col>
          </Row>
          <Row>
            <Col style={{ margin: "0 0 auto 0" }} col={12} colMD={6}>
              <FeatureIcon
                align="center"
                paddingDescription="0 15px"
                iconFree={<img src="/images/tracking/vessel.jpg" alt="Embarcações" />}
                title={"WebTV."}
                description={
                  <>
                    ...
                  </>
                }
              />
            </Col>
            <Col style={{ margin: "0 0 auto 0" }} col={12} colMD={6}>
              <FeatureIcon
                align="center"
                paddingDescription="0 15px"
                iconFree={<img src="/images/tracking/heavy.jpg" alt="Máquinas pesadas" />}
                title={"Cursos."}
                description={
                  <>
                    ...
                  </>
                }
              />
            </Col>
          </Row>
        </RowBox>
      </Container> */}

      <Container>
        <Row className="row">
          <ColTitle className="col">
            <Title>Sob medida.</Title>
          </ColTitle>
        </Row>
        <RowBox>
          <Row className="row">
            <Col className="col">
              <Description>
                Desenvolvemos softwares sob medida para resolver problemas reais e agregar valor ao seu negócio. Incremente
                o potencial do seu negócio utilizando soluções personalizadas e que atendam as suas necessidades.
                Entre em contato conosco para mais detalhes.
              </Description>
            </Col>
          </Row>
        </RowBox>
      </Container>

    </Container>
  );
};

export default ThreePixels;