export function isEmpty(value) {
  if(trim(value) === "") {
    return true;
  }
  return false;
}

export function  trim(string) {
  if(string === null) {
    string = "";
  }
  if(typeof string === "undefined") {
    string = "";
  }
  if(typeof string !== "string") {
    string = ` ${string} `;
  }
  return string.replace(/^\s+|\s+$/gm, "");
}

export function ucFirstAll(string) {
  try {
    let arrStr = string.split(" ");
    let arrWords = [];
    arrStr.map(item => {
      return arrWords.push((item.charAt(0).toUpperCase() + item.slice(1)));
    });
    return arrWords.join(" ");
  } catch (e) {
    return string;
  }
}
