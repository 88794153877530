import styled from "styled-components";

import { Sizes } from "./../Styles/config";

export const RowSection = styled.div`
  @media screen and (min-width: ${Sizes.xl}px) {
    padding: 0 15px;
  }
`;

export const Section = styled.div`
  padding: 100px 15px;
  background: ${props => props.bg || "#f5f5f7"};
  margin: auto;
  margin-bottom: 15px;
`;

export const SectionContainer = styled.div`
  display: block;
  @media screen and (min-width: 3000px) {
    & > div {
      padding: 0;
      width: 100%;
      max-width: ${props => props.maxWidth || "50%"};
      float: ${props => props.float || "right"};
    }
  }
`;

export const SectionInfo = styled.div`
  padding: 50px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h2,
  h3,
  .details {
    padding: 0;
    margin: 0 0 15px 0;
    display: flex;
    flex: 1;
  }

  h2,
  h3 {
    color: ${props => props.color || "#1d1d1f"};
  }

  h2 {
    font-size: 56px;
    line-height: 1.07143;
    font-weight: 600;
    letter-spacing: -.005em;
  }
  h3 {
    font-size: 28px;
    line-height: 1.10722;
    font-weight: 400;
    letter-spacing: .004em;
  }
  a {
    padding: 5px;
    margin: 0 5px;
    font-size: 25px;
    line-height: 1.10722;
    font-weight: 400;
    letter-spacing: .004em;
    color: #06c;
  }
  a:hover {
    text-decoration: underline;
  }
`;

export const SectionImage = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    display: flex;
  }
  .image {
    padding: 0;
  }
  .description {
    padding: 15px 30px;
    flex: 1;
    font-size: 25px;
    line-height: 1.5;
    font-weight: 400;
    letter-spacing: -0.374px;
    color: #86868b;
    text-align: justify;
  }
`;