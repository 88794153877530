import styled from "styled-components";
import { Sizes } from "../Styles/config";
import {
  Col as DefaultCol,
  Row as DefaultRow
} from "../Styles/default";

export const ButtonBudget = styled.div`
  width: 100%;
  margin: auto;
  padding: 30px 15px;
  border-radius: 5px;
  a,
  button {
    width: 100% !important;
    font-size: 28px !important;
    font-weight: bold !important;
  }
`;

export const Col = styled(DefaultCol)`
  padding: 0;
  margin: auto;
  @media screen and (max-width: ${Sizes.md}px) {
    margin-bottom: 15px;
  }

  &.col-more-functions {
    padding: 0 0 0 30px;
    @media screen and (max-width: ${Sizes.md}px) {
      padding: 0;
    }
  }
`;

export const ColFlex = styled(DefaultCol)`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100%;
  @media screen and (max-width: ${Sizes.md}px) {
    margin-bottom: 15px;
  }
`;

export const ColBox = styled.div`
  padding: 15px;
  margin: auto;
  @media screen and (max-width: ${Sizes.md}px) {
    margin-bottom: 15px;
  }
`;

export const ColTitle = styled.div`
  padding: 100px 0 0 0;
  margin:  0 0 15px 0;
  @media screen and (max-width: ${Sizes.lg}px) {
    padding: 80px 0 0 0;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    padding: 64px 0 0 0;
  }
`;

export const Description = styled.p`
  font-size: 28px;
  line-height: 1.28583;
  font-weight: 400;
  letter-spacing: .007em;
  color: ${props => props.color || "#1d1d1f"};
  text-align: ${props => props.align || "left"};
  &:not(:last-child) {
    margin: 0 0 20px 0;
  }
  @media screen and (max-width: ${Sizes.lg}px) {
    font-size: 22.4px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    font-size: 17.92px;
  }
`;

export const DescriptionIcon = styled.div`
  font-size: 120px;
  line-height: 1.0625;
  font-weight: 600;
  letter-spacing: -.009em;
  color: ${props => props.color || "#3078e3"};
  text-align: ${props => props.align || "center"};
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: ${Sizes.lg}px) {
    font-size: 80px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    font-size: 60px;
  }
`;

export const Row = styled(DefaultRow)`
  padding: 0;
`;

export const RowBox = styled.div`
  padding: 30px;
  margin: 25px 0;
  background: ${props => props.background || "#fff"};
  border-radius: 30px;
`;

export const SubTitle = styled.h3`
  font-size: 32px;
  line-height: 1.0625;
  font-weight: 600;
  letter-spacing: -.009em;
  color: ${props => props.color || "#3078e3"};
  text-align: ${props => props.align || "left"};
  @media screen and (max-width: ${Sizes.lg}px) {
    font-size: 25.6px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    font-size: 20.48px;
  }
`;

export const Title = styled.h2`
  font-size: 64px;
  line-height: 1.0625;
  font-weight: 600;
  letter-spacing: -.009em;
  color: ${props => props.color || "#3078e3"};
  text-align: ${props => props.align || "left"};
  @media screen and (max-width: ${Sizes.lg}px) {
    font-size: 51.2px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    font-size: 40.96px;
  }
`;

export const VideoRowDescription = styled.p`
  font-size: 28px;
  line-height: 1.28583;
  font-weight: 400;
  letter-spacing: .007em;
  color: ${props => props.color || "#fff"};
  text-align: ${props => props.align || "center"};
  text-shadow: 1px 1px 10px rgba(48,48,48,1);
  @media screen and (max-width: ${Sizes.lg}px) {
    font-size: 22.4px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    font-size: 17.92px;
  }
  a {
    margin: 0 10px;
    color: ${props => props.color || "#fff"};
    text-decoration: underline;
  }
`;

export const VideoRowTitle = styled.h1`
  margin-bottom: 30px;
  font-size: 80px;
  line-height: 1.05;
  font-weight: 600;
  color: ${props => props.color || "#fff"};
  text-align: ${props => props.align || "center"};
  text-shadow: 1px 1px 10px rgba(48,48,48,1);
  @media screen and (max-width: ${Sizes.lg}px) {
    font-size: 32px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    font-size: 26px;
  }
`;