import styled from "styled-components";

import { Sizes } from "./../Styles/config";

export const Box = styled.div`
  padding: 15px 0;
  margin: 0;
  display: block;
`;

export const Icon = styled.div`
  margin-bottom: 15px;
  width: 42px;
  height: 42px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0,0,0,.16);
  overflow: hidden;

  i,
  img,
  span {
    padding: 0;
    flex: 1;
    text-align: center;
    font-size: 17px;
    line-height: 1;
    position: relative;
    font-weight: 900;
  }
`;

export const IconFree = styled.div`
  /* width: 120px; */
  height: 120px;
  margin: auto;
  margin-bottom: 15px;
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  i,
  img,
  span {
    padding: 0;
    text-align: center;
    font-size: 17px;
    line-height: 1;
    position: relative;
    font-weight: 900;
    height: 100%;
  }
`;

export const Title = styled.h3`
  padding: ${props => props.padding || "0"};
  font-size: 28px;
  line-height: 1.0625;
  font-weight: 600;
  letter-spacing: -.009em;
  color: ${props => props.color || "#1d1d1f"};
  text-align: ${props => props.align || "center"};
  @media screen and (max-width: ${Sizes.lg}px) {
    font-size: 22.4px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    font-size: 17.92px;
  }
`;

export const Description = styled.div`
  padding: ${props => props.padding || "0 15px 0 0"};
  font-size: 17px;
  line-height: 1.28583;
  font-weight: 400;
  letter-spacing: .007em;
  color: ${props => props.color || "#1d1d1f"};
  text-align: ${props => props.align || "center"};
  @media screen and (max-width: ${Sizes.lg}px) {
    font-size: 16px;
  }
  @media screen and (max-width: ${Sizes.md}px) {
    font-size: 15px;
  }
`;