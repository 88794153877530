import React from "react";
import SEO from "./../SEO";
import {
  Container
} from "react-bootstrap";
import {
  ButtonBudget,
  Row,
  RowBox,
  RowFlex,
  Col,
  ColFlex,
  SubTitle,
  Title,
  Description,
  DescriptionIcon
} from "./styles";

import AnimationBudget from "../Animation/Budget";
import AnimationStreaming from "../Animation/Streaming";
import AnimationTransmission from "../Animation/Transmission";
import ButtonLink from "../ButtonLink";
import FeatureIcon from "./../FeatureIcon";
import MockupImac from "../MockupImac";
import MockupIphone from "../MockupIphone";
import MockupMacbook from "../MockupMacbook";
import TitleRow from "./../TitleRow";

const Streaming = () => {
  return (
    <Container className="max-w-3000" fluid>
      <SEO options={{
        tags: {
          title: `Streaming - ${process.env.REACT_APP_APP_TITLE}`,
          description: `Plataforma de streaming de vídeos on demand e ao vivo.`,
          ogDescription: `Plataforma de streaming de vídeos on demand e ao vivo.`
        }
      }} />

      <TitleRow
        title={
          <>
            Streaming
          </>
        }
        description={
          <>
            Plataforma de streaming de vídeos on demand e ao vivo.
          </>
        }
      />

      <Container>
        <RowBox background="transparent">
          <Row className="row">
            <ColFlex col={8}>
              <Title>Plataforma completa.</Title>
              <Description>
                A plataforma de streaming de vídeos conta com um sistema robusto para processamento de vídeos e transmissões ao vivo, compatível com
                retransmissão para outras plataformas e com um painel para organização dos vídeos e suas categorias.
              </Description>
            </ColFlex>
            <ColFlex col={4}>
              <DescriptionIcon style={{ padding: "30px" }}>
                <AnimationTransmission />
              </DescriptionIcon>
            </ColFlex>
          </Row>
        </RowBox>
      </Container>

      <Container>
        <RowBox background="transparent">
          <Row className="row">
            <ColFlex col={4}>
              <DescriptionIcon style={{ padding: "30px" }}>
                <AnimationStreaming />
              </DescriptionIcon>
            </ColFlex>
            <ColFlex col={8}>
              <Title>Aplicativos para Smart TV`s.</Title>
              <Description>
                Aplicativo próprio para Smart Tv's traz praticidade aos seus telespectadores, prevenindo o mesmo de ser bombardeado de informações e canais que não são do seu interesse, e
                muitas vezes acabam fazendo com que o telespectador perca o foco da busca.
              </Description>
            </ColFlex>
          </Row>
        </RowBox>
      </Container>

      <Container>
        <RowBox>
          <Row className="row">
            <Col>
              <Title>Aplicativos para Smartphones.</Title>
              <Description>
                Criar um aplicativo permite que seu telespectador tenha maior contato com a marca, ele sempre saberá onde pode encontrar o conteúdo desejado.
                Essa disponibilidade, além de agregar valor e evoluir a marca, cria um laço mais firme com seu público.
              </Description>
            </Col>
          </Row>
        </RowBox>
      </Container>

      <Container>
        <Row>
          <ColFlex>
            <ButtonLink as={ButtonBudget} href="https://api.whatsapp.com/send?phone=5549989237375&text=Ol%C3%A1" target="_blank" rel="noopener noreferrer">Orçamento</ButtonLink>
          </ColFlex>
        </Row>
      </Container>

      <Container>
        <MockupIphone
          align="right"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/streaming/iphone/large/1.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/streaming/iphone/medium/1.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/streaming/iphone/small/1.jpg)`
          }}
          title={
            <>
              Login para acesso!
            </>
          }
          description={
            <>
              Solicitando informações do telespectador para o acesso, pode ser criado um banco de dados com as informações dos usuários dos App's.
            </>
          }
        />
      </Container>

      <Container>
        <MockupMacbook
          align="left"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/streaming/macbook/large/1.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/streaming/macbook/medium/1.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/streaming/macbook/small/1.jpg)`
          }}
          title={
            <>
              Programação de TV.
            </>
          }
          description={
            <>
              Além do conteúdo ao vivo, é possível organizar os conteúdos à sua escolha e disponibilize playlists de conteúdos On Demand para seus usuários.
            </>
          }
        />
      </Container>

      <Container>
        <MockupImac
          align="right"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/streaming/macbook/large/2.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/streaming/macbook/medium/2.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/streaming/macbook/small/2.jpg)`
          }}
          title={
            <>
              Comentários,<br />likes e<br />compartilhamento.
            </>
          }
          description={
            <>
              O usuário poderá compartilhar os vídeos através do WhatsApp, Telegram, Messenger... Os telespectadores podem comentar e exprimir suas opiniões sobre seus conteúdos preferidos.
            </>
          }
        />
      </Container>

      <Container style={{ marginBottom: "100px" }}>
        <MockupIphone
          align="left"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/streaming/iphone/large/2.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/streaming/iphone/medium/2.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/streaming/iphone/small/2.jpg)`
          }}
          title={
            <>
              Conteúdos.
            </>
          }
          description={
            <>
              O usuários poderá pesquisar por conteúdos, programas, pesquisa geral, ou buscar diretamente por categorias específicas, também podendo consultar quais vídeos ele
              já assistiu e criar listas de favoritos com o que mais gostaram de assistir mais na plataforma.
            </>
          }
        />
      </Container>

      <Container style={{ marginBottom: "100px" }}>
        <MockupIphone
          align="right"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/streaming/iphone/large/2.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/streaming/iphone/medium/2.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/streaming/iphone/small/2.jpg)`
          }}
          title={
            <>
              Notificações PUSH.
            </>
          }
          description={
            <>
              O Aplicativo Mobile enviará notificações via PUSH, será possível informar a todos usuários quando alguma programação estiver ao vivo, a
              inserção de novos vídeos, entre outras mensagens ao usuário.
            </>
          }
        />
      </Container>

      <Container>
        <MockupMacbook
          align="left"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/streaming/macbook/large/3.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/streaming/macbook/medium/3.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/streaming/macbook/small/3.jpg)`
          }}
          title={
            <>
              Painel de Gestão Administrativa.
            </>
          }
          description={
            <>
              Por meio de um painel de Gestão Administrativa, ocorrerá o Upload de vídeos ou indexação de um vídeo já postado no Youtube,
              organização dos mesmos em categorias, envio de notificações via PUSH, visualização de índices, entre outras funções escolhidas para suprimento dos Aplicativos Mobile e para Smart TV's.
            </>
          }
        />
      </Container>

      <Container style={{ margin: "100px auto auto auto" }}>
        <RowBox>
          <RowFlex>
            <ColFlex>
              <SubTitle align="center">Facilidade para sua plataforma de streaming.</SubTitle>
            </ColFlex>
          </RowFlex>
          <RowFlex>
            <ColFlex style={{ margin: "0 0 auto 0" }} col={12} colMD={4}>
              <FeatureIcon
                align="center"
                paddingDescription="0 15px"
                iconFree={<img src="/images/streaming/1.png" alt="Personalizado" />}
                title={"Personalizado."}
                description={
                  <>
                    Os aplicativos terão as cores, logomarca e a sua identidade visual.
                  </>
                }
              />
            </ColFlex>
            <ColFlex style={{ margin: "0 0 auto 0" }} col={12} colMD={4}>
              <FeatureIcon
                align="center"
                paddingDescription="0 15px"
                iconFree={<img src="/images/streaming/2.png" alt="Multiplataformas" />}
                title={"Multiplataformas."}
                description={
                  <>
                    Desenvolvemos aplicativos Mobile para iOS e Android, e para Smart TV's Samsung, LG e Apple TV. Encontre seus telespectadores onde eles estiverem.
                  </>
                }
              />
            </ColFlex>
            <ColFlex style={{ margin: "0 0 auto 0" }} col={12} colMD={4}>
              <FeatureIcon
                align="center"
                paddingDescription="0 15px"
                iconFree={<img src="/images/streaming/3.png" alt="Suporte" />}
                title={"Suporte."}
                description={
                  <>
                    Nosso suporte é rápido e eficiente, atendemos através de telefone, e-mail ou WhatsApp.
                  </>
                }
              />
            </ColFlex>
          </RowFlex>
        </RowBox>
      </Container>

      <Container>
        <RowBox>
          <Row>
            <Col style={{ margin: "0 0 auto 0" }}>
             <FeatureIcon
                align="center"
                paddingDescription="0 15px"
                title={
                  <>
                    Temos a solução para facilitar a sua plataforma de streaming de vídeos on demand e ao vivo.
                  </>
                }
              />
              <Row>
                <Col>
                  <AnimationBudget height="500px" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <ButtonLink as={ButtonBudget} href="https://api.whatsapp.com/send?phone=5549989237375&text=Olá, tenho interesse na plataforma de streaming de vídeos on demand e ao vivo." target="_blank" rel="noopener noreferrer">Orçamento</ButtonLink>
                </Col>
              </Row>
            </Col>
          </Row>
        </RowBox>
      </Container>

    </Container>
  );
};

export default Streaming;
