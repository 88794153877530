import React, { Suspense } from "react";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import "./i18n";
import "./App.css";

import store from "./store";
import Routes from "./Routes";

const appHeight = () => document.documentElement.style.setProperty("--app-height", `${window.innerHeight}px`);
window.addEventListener("resize", appHeight);
appHeight();

if (process.env.NODE_ENV === "production") {
  ReactGA.initialize(process.env.REACT_APP_APP_ANALYTICS);
  ReactGA.pageview(window.location.pathname + window.location.search);
}

function App() {
  return (
    <Suspense fallback={"Aguarde..."}>
      <Provider store={store}>
        <Routes />
      </Provider>
    </Suspense>
  );
}

export default App;