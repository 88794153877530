import React from "react";
import { Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";

import history from "./history";
import ScrollToTopRoute from "./ScrollToTopRoute";

import Contact from "./pages/Contact";
import EletronicNiple from "./pages/EletronicNiple";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import PasswordRemoval from "./pages/PasswordRemoval";
import Rdv from "./pages/Rdv";
import Schedules from "./pages/Schedules";
import Streaming from "./pages/Streaming";
import ThreePixels from "./pages/ThreePixels";
import Tracking from "./pages/Tracking";

const Routes = () => {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <ScrollToTopRoute exact path="/" component={Home} />
        <ScrollToTopRoute path="/agendamentos" component={Schedules} />
        <ScrollToTopRoute path="/3pixels" component={ThreePixels} />
        <ScrollToTopRoute path="/contato" component={Contact} />
        <ScrollToTopRoute path="/niple-eletronico" component={EletronicNiple} />
        <ScrollToTopRoute path="/rastreamento" component={Tracking} />
        <ScrollToTopRoute path="/retirada-de-senhas" component={PasswordRemoval} />
        <ScrollToTopRoute path="/rdv" component={Rdv} />
        <ScrollToTopRoute path="/streaming" component={Streaming} />
        <ScrollToTopRoute path="*" component={NotFound} />
      </Switch>
    </ConnectedRouter>
  );
};

export default Routes;
