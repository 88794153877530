import { applyMiddleware, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";

import history from "../history";
import reducers from "./ducks";

const middlewares = [routerMiddleware(history), thunk];
const store = createStore(reducers, applyMiddleware(...middlewares));

export default store;
