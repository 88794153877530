import React from "react";
import { Switch } from "react-router-dom";
import ScrollToTopRoute from "../../ScrollToTopRoute";
import { DfFooter, DfHeader, DfMain, GlobalStyle } from "../../components/Styles/default";

import MainContact from "../../components/Contact";
import MainFooter from "../../components/Footer";
import MainHeader from "../../components/Header";
import MainNotFound from "../../components/NotFound";

const Contact = ({ match }) => {
  const path = match.path;
  return (
    <>
      <GlobalStyle />
      <DfHeader>
        <MainHeader position="fixed" />
      </DfHeader>
      <DfMain padding={"44px 0 0 0"}>
        <Switch>
          <ScrollToTopRoute exact path={path} component={MainContact} />
          <ScrollToTopRoute component={MainNotFound} />
        </Switch>
      </DfMain>
      <DfFooter>
        <MainFooter />
      </DfFooter>
    </>
  );
};

export default Contact;