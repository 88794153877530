import styled, { css } from "styled-components";

import { Sizes } from "../Styles/config";

const PUBLIC_URL = process.env.PUBLIC_URL;

const scale = {
  large: 0.35,
  medium: 0.4,
  small: 0.715
};

const ThreePixels = {
  large: {
    width: (1536 * scale.large),
    height: (532 * scale.large)
  },
  medium: {
    width: (800 * scale.medium),
    height: (277 * scale.medium)
  },
  small: {
    width: (512 * scale.small),
    height: (177 * scale.small)
  }
};

const ThreePixelsSizes = {
  large: {
    alignLeft: 0,
    alignRight: 0
  },
  medium: {
    alignLeft: 0,
    alignRight: -100
  },
  small: {
    alignLeft: 0,
    alignRight: 0
  }
};

export const Box = styled.div`
  width: ${ThreePixels.large.width}px;
  height: ${ThreePixels.large.height}px;
  display: block;
  z-index: 1;
  position: relative;

  ${props => {
    const align = props.align || "left";
    if (align === "left") {
      return css`
        float: right;
        transform: translateX(${ThreePixelsSizes.large.alignRight}px);
      `;
    }
    else if (align === "right") {
      return css`
        float: left;
        transform: translateX(${ThreePixelsSizes.large.alignLeft}px);
      `;
    }
    else if (align === "center") {
      return css`
        float: none;
        transform: translateX(${ThreePixelsSizes.large.alignCenter}px);
      `;
    }
  }}

  & div,
  &:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  @media only screen and (max-width: ${Sizes.lg}px) {
    margin-top: 80px;
    width: ${ThreePixels.medium.width}px;
    height: ${ThreePixels.medium.height}px;

    ${props => {
      const align = props.align || "left";
      if (align === "left") {
        return css`
          transform: translateX(${ThreePixelsSizes.medium.alignRight}px);
        `;
      }
      else if (align === "right") {
        return css`
          transform: translateX(${ThreePixelsSizes.medium.alignLeft}px);
        `;
      }
      else if (align === "center") {
        return css`
          transform: translateX(${ThreePixelsSizes.medium.alignCenter}px);
        `;
      }
    }}
  }

  @media only screen and (max-width: ${Sizes.md}px) {
    margin-top: 45px;
    width: ${ThreePixels.small.width}px;
    height: ${ThreePixels.small.height}px;

    ${props => {
      const align = props.align || "left";
      if (align === "left") {
        return css`
          transform: translateX(${ThreePixelsSizes.small.alignRight}px);
        `;
      }
      else if (align === "right") {
        return css`
          transform: translateX(${ThreePixelsSizes.small.alignLeft}px);
        `;
      }
      else if (align === "center") {
        return css`
          transform: translateX(${ThreePixelsSizes.small.alignCenter}px);
        `;
      }
    }}
  }
`;

export const Background = styled.div`
  z-index: 3 !important;
  width: ${ThreePixels.large.width}px;
  height: ${ThreePixels.large.height}px;
  background-image: url("${PUBLIC_URL}/images/logo-1536.png");
  background-size: ${ThreePixels.large.width}px ${ThreePixels.large.height}px;
  background-repeat: no-repeat;
  background-position: center;

  @media only screen and (max-width: ${Sizes.lg}px) {
    width: ${ThreePixels.medium.width}px;
    height: ${ThreePixels.medium.height}px;
    background-image: url("${PUBLIC_URL}/images/logo-800.png");
    background-size: ${ThreePixels.medium.width}px ${ThreePixels.medium.height}px;
  }

  @media only screen and (max-width: ${Sizes.md}px) {
    width: ${ThreePixels.small.width}px;
    height: ${ThreePixels.small.height}px;
    background-image: url("${PUBLIC_URL}/images/logo-800.png");
    background-size: ${ThreePixels.small.width}px ${ThreePixels.small.height}px;
    mask-image: none !important;
  };
`;