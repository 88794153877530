import React, { useEffect, useState } from "react";
import SEO from "../../SEO";
import {
  Alert,
  Button,
  Form,
  Container
} from "react-bootstrap";
import InputMask from "react-input-mask";
import {
  Row,
  RowBox,
  Col,
  ColBox,
  SubTitle
} from "./styles";

import { isCellphone } from "./../../../utils/Cellphone";
import { isEmail } from "./../../../utils/Email";
import { isValidForm } from "./../../../utils/Form";
import { isEmpty } from "./../../../utils/String";

import { send } from "./../../../services/Email";

import TitleRow from "../../TitleRow";

const Budget = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const [vehicles, setVehicles] = useState(1);
  const [vehiclesHatchs, setVehiclesHatchs] = useState(0);
  const [vehiclesValves, setVehiclesValves] = useState(0);
  const [vehiclesBackdoors, setVehiclesBackdoors] = useState(0);

  const [vehicleZeroHatch, setVehicleZeroHatch] = useState(0);
  const [vehicleOneHatch, setVehicleOneHatch] = useState(0);
  const [vehicleTwoHatch, setVehicleTwoHatch] = useState(0);
  const [vehicleThreeHatch, setVehicleThreeHatch] = useState(0);

  const [vehicleValve3p, setVehicleValve3p] = useState(0);
  const [vehicleValve4p, setVehicleValve4p] = useState(0);

  const [vehicleBackdoorHorizontal, setVehicleBackdoorHorizontal] = useState(0);
  const [vehicleBackdoorVertical, setVehicleBackdoorVertical] = useState(0);

  const [running, setRunning] = useState(false);
  const [response, setResponse] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");

  useEffect(() => {
    checkVehicle(vehicles, (vehicles) => {
      setVehicles(vehicles);
    }, 1);
  }, [vehicles]); //eslint-disable-line

  useEffect(() => {
    checkVehicle(vehicleZeroHatch, (vehicles) => {
      setVehicleZeroHatch(vehicles);
      setVehiclesHatchs((vehicles + vehicleOneHatch + vehicleTwoHatch + vehicleThreeHatch));
    });
  }, [vehicleZeroHatch]); //eslint-disable-line

  useEffect(() => {
    checkVehicle(vehicleOneHatch, (vehicles) => {
      setVehicleOneHatch(vehicles);
      setVehiclesHatchs((vehicleZeroHatch + vehicles + vehicleTwoHatch + vehicleThreeHatch));
    });
  }, [vehicleOneHatch]); //eslint-disable-line

  useEffect(() => {
    checkVehicle(vehicleTwoHatch, (vehicles) => {
      setVehicleTwoHatch(vehicles);
      setVehiclesHatchs((vehicleZeroHatch + vehicleOneHatch + vehicles + vehicleThreeHatch));
    });
  }, [vehicleTwoHatch]); //eslint-disable-line

  useEffect(() => {
    checkVehicle(vehicleThreeHatch, (vehicles) => {
      setVehicleThreeHatch(vehicles);
      setVehiclesHatchs((vehicleZeroHatch + vehicleOneHatch + vehicleTwoHatch + vehicles));
    });
  }, [vehicleThreeHatch]); //eslint-disable-line

  useEffect(() => {
    checkVehicle(vehicleValve3p, (vehicles) => {
      setVehicleValve3p(vehicles);
      setVehiclesValves((vehicles + vehicleValve4p));
    });
  }, [vehicleValve3p]); //eslint-disable-line

  useEffect(() => {
    checkVehicle(vehicleValve4p, (vehicles) => {
      setVehicleValve4p(vehicles);
      setVehiclesValves((vehicleValve3p + vehicles));
    });
  }, [vehicleValve4p]); //eslint-disable-line

  useEffect(() => {
    checkVehicle(vehicleBackdoorHorizontal, (vehicles) => {
      setVehicleBackdoorHorizontal(vehicles);
      setVehiclesBackdoors((vehicles + vehicleBackdoorVertical));
    });
  }, [vehicleBackdoorHorizontal]); //eslint-disable-line

  useEffect(() => {
    checkVehicle(vehicleBackdoorVertical, (vehicles) => {
      setVehicleBackdoorVertical(vehicles);
      setVehiclesBackdoors((vehicleBackdoorHorizontal + vehicles));
    });
  }, [vehicleBackdoorVertical]); //eslint-disable-line

  const checkVehicle = (vehicle, callback, min = 0) => {
    let vehicles = parseInt(vehicle) || min;
    if (vehicles === "" || vehicles === null || isNaN(vehicles) || vehicles < min) {
      vehicles = min;
    }
    callback(vehicles);
  };

  const buttonDisabled = () => {
    if (!running) {
      return false;
    }
    return true;
  };

  const inputDisabled = () => {
    if (!running) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!running) {
      setRunning(true);
      let { valid, message: messageForm } = isValidForm(e);
      let responseForm = false;
      if (!valid) {
        if (!messageForm) {
          messageForm = "Preencha todos os campos destacados, são obrigatórios.";
        }
      }
      else if (vehicles !== vehiclesHatchs) {
        messageForm = "Preencha todas as informações de escotilha superior.";
      }
      else if (vehicles !== vehiclesValves) {
        messageForm = "Preencha todas as informações de válvula de descarga.";
      }
      else if (vehicles !== vehiclesBackdoors) {
        messageForm = "Preencha todas as informações de tampa traseira.";
      }
      else {
        const response = await send({
          data: {
            content: [
              {
                title: "Qual seu nome?",
                message: name,
              },
              {
                title: "Qual seu e-mail?",
                message: email,
              },
              {
                title: "Qual seu celular?",
                message: phone,
              },
              {
                title: "Qual a quantidade total de veículos?",
                message: vehicles
              },
              {
                title: "Quantos veículos não possuem escotilha superior?",
                message: vehicleZeroHatch
              },
              {
                title: "Quantos veículos possuem apenas uma (1) escotilha superior?",
                message: vehicleOneHatch
              },
              {
                title: "Quantos veículos possuem duas (2) escotilhas superiores?",
                message: vehicleTwoHatch
              },
              {
                title: "Quantos veículos possuem três (3) escotilhas superiores?",
                message: vehicleThreeHatch
              },
              {
                title: "Quantos veículos possuem a válvula de descarga com 3 polegadas?",
                message: vehicleValve3p
              },
              {
                title: "Quantos veículos possuem a válvula de descarga com 4 polegadas?",
                message: vehicleValve4p
              },
              {
                title: "Quantos veículos possuem abertura de tampa traseira horizontal?",
                message: vehicleBackdoorHorizontal
              },
              {
                title: "Quantos veículos possuem abertura de tampa traseira vertical?",
                message: vehicleBackdoorVertical
              },
              {
                title: "Mensagem",
                message: message
              }
            ],
            from: email,
            subject: `Orçamento | Niple Eletrônico | ${name}`
          }
        });
        if (response) {
          messageForm = "Os dados foram enviados com sucesso, entraremos em contato assim que possível.";
          responseForm = true;
          setTimeout(() => {
            resetForm();
          }, 1000);
        }
        else {
          messageForm = "Preencha todos os campos destacados, são obrigatórios.";
          responseForm = false;
        }
      }
      setResponse(responseForm);
      setResponseMessage(messageForm);
      if (responseForm === false) {
        setRunning(false);
      }
    }
  };

  const resetForm = () => {
    setResponse(null);
    setResponseMessage("");
    setName("");
    setEmail("");
    setPhone("");
    setVehicles(1);
    setVehicleZeroHatch(0);
    setVehicleOneHatch(0);
    setVehicleTwoHatch(0);
    setVehicleThreeHatch(0);
    setVehicleValve3p(0);
    setVehicleValve4p(0);
    setVehicleBackdoorHorizontal(0);
    setVehicleBackdoorVertical(0);
    setMessage("");
    setRunning(false);
  };

  return (
    <Container fluid>
      <SEO options={{
        tags: {
          title: `Orçamento | Niple Eletrônico - ${process.env.REACT_APP_APP_TITLE}`
        }
      }} />

      <TitleRow
        title={
          <>
            Orçamento
          </>
        }
        description={
          <>
            N-Tracker + Niple-N3a.
          </>
        }
      />

      <Container>
        <Row className="row">
          <ColBox className="col" padding={"15px 0"}>
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="row">
                <Col className="col-12">
                  <Form.Group controlId="form-name">
                    <Form.Label>Qual seu nome?</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Preencha com o seu nome..."
                      onChange={e => setName(e.target.value)}
                      autoComplete="off"
                      value={name}
                      isValid={!isEmpty(name)}
                      isInvalid={isEmpty(name)}
                      disabled={inputDisabled()}
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">Preencha com o seu nome. Exemplo: Pedro Henrique.</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="row">
                <Col className="col-12">
                  <Form.Group controlId="form-email">
                    <Form.Label>Qual seu e-mail?</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Preencha com o seu e-mail..."
                      onChange={e => setEmail(e.target.value)}
                      autoComplete="off"
                      value={email}
                      isValid={!isEmpty(email) && isEmail(email)}
                      isInvalid={isEmpty(email) || !isEmail(email)}
                      disabled={inputDisabled()}
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">Preencha com o seu e-mail. Exemplo: exemplo@email.com.br.</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="row">
                <Col className="col">
                  <Form.Group controlId="form-phone">
                    <Form.Label>Qual seu celular?</Form.Label>
                    <InputMask
                      id="form-phone"
                      className={`form-control ${!isEmpty(phone) && isCellphone(phone) ? "is-valid" : "is-invalid"}`}
                      type="text"
                      placeholder="Preencha com o seu celular..."
                      onChange={e => setPhone(e.target.value)}
                      mask="+99 (99) 99999-9999"
                      autoComplete="off"
                      value={phone}
                      disabled={inputDisabled()}
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">Preencha com o seu celular. Exemplo: +55 (49) 99999-9999.</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="row">
                <Col className="col">
                  <Form.Group controlId="form-vehicle">
                    <Form.Label>Qual a quantidade total de veículos?</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={"Preencha com a quantidade de veículos..."}
                      onChange={e => setVehicles(e.target.value)}
                      autoComplete="off"
                      value={vehicles}
                      disabled={inputDisabled()}
                      required={true}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <RowBox>
                <Row className="row" style={{ border: vehiclesHatchs === vehicles ? "1px solid #1f7c1a" : "1px solid #ff0000" }}>
                  <Col className="col-12">
                    <SubTitle style={{ color: vehiclesHatchs === vehicles ? "#1f7c1a" : "#ff0000" }}>Você preencheu {vehiclesHatchs} de {vehicles} das informações de escotilha superior.</SubTitle>
                  </Col>

                  <Col className="col-12">
                    <Form.Group controlId="form-vehicle-zero-hatch">
                      <Form.Label>Quantos veículos não possuem escotilha superior?</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={"Preencha com a quantidade de veículos que não possuem escotilha superior..."}
                        onChange={e => setVehicleZeroHatch(e.target.value)}
                        autoComplete="off"
                        value={vehicleZeroHatch}
                        disabled={inputDisabled()}
                        required={true}
                      />
                    </Form.Group>
                  </Col>

                  <Col className="col-12">
                    <Form.Group controlId="form-vehicle-one-hatch">
                      <Form.Label>Quantos veículos possuem apenas uma (1) escotilha superior?</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={"Preencha com a quantidade de veículos que possuem apenas uma (1) escotilha superior..."}
                        onChange={e => setVehicleOneHatch(e.target.value)}
                        autoComplete="off"
                        value={vehicleOneHatch}
                        disabled={inputDisabled()}
                        required={true}
                      />
                    </Form.Group>
                  </Col>

                  <Col className="col-12">
                    <Form.Group controlId="form-vehicle-two-hatch">
                      <Form.Label>Quantos veículos possuem duas (2) escotilhas superiores?</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={"Preencha com a quantidade de veículos que possuem duas (2) escotilhas superiores..."}
                        onChange={e => setVehicleTwoHatch(e.target.value)}
                        autoComplete="off"
                        value={vehicleTwoHatch}
                        disabled={inputDisabled()}
                        required={true}
                      />
                    </Form.Group>
                  </Col>

                  <Col className="col-12">
                    <Form.Group controlId="form-vehicle-three-hatch">
                      <Form.Label>Quantos veículos possuem três (3) escotilhas superiores?</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={"Preencha com a quantidade de veículos que possuem três (3) escotilhas superiores..."}
                        onChange={e => setVehicleThreeHatch(e.target.value)}
                        autoComplete="off"
                        value={vehicleThreeHatch}
                        disabled={inputDisabled()}
                        required={true}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </RowBox>

              <RowBox>
                <Row className="row" style={{ border: vehiclesValves === vehicles ? "1px solid #1f7c1a" : "1px solid #ff0000" }}>
                  <Col className="col-12">
                    <SubTitle style={{ color: vehiclesValves === vehicles ? "#1f7c1a" : "#ff0000" }}>Você preencheu {vehiclesValves} de {vehicles} das informações de válvula de descarga.</SubTitle>
                  </Col>

                  <Col className="col-12">
                    <Form.Group controlId="form-vehicle-valve-3p">
                      <Form.Label>Quantos veículos possuem a válvula de descarga com 3 polegadas?</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={"Preencha com a quantidade de veículos que possuem a válvula de descarga com 3 polegadas..."}
                        onChange={e => setVehicleValve3p(e.target.value)}
                        autoComplete="off"
                        value={vehicleValve3p}
                        disabled={inputDisabled()}
                        required={true}
                      />
                    </Form.Group>
                  </Col>

                  <Col className="col-12">
                    <Form.Group controlId="form-vehicle-valve-4p">
                      <Form.Label>Quantos veículos possuem a válvula de descarga com 4 polegadas?</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={"Preencha com a quantidade de veículos que possuem a válvula de descarga com 4 polegadas..."}
                        onChange={e => setVehicleValve4p(e.target.value)}
                        autoComplete="off"
                        value={vehicleValve4p}
                        disabled={inputDisabled()}
                        required={true}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </RowBox>

              <RowBox>
                <Row className="row" style={{ border: vehiclesBackdoors === vehicles ? "1px solid #1f7c1a" : "1px solid #ff0000" }}>
                  <Col className="col-12">
                    <SubTitle style={{ color: vehiclesBackdoors === vehicles ? "#1f7c1a" : "#ff0000" }}>Você preencheu {vehiclesBackdoors} de {vehicles} das informações de tampa traseira.</SubTitle>
                  </Col>

                  <Col className="col-12">
                    <Form.Group controlId="form-vehicle-backdoor-horizontal">
                      <Form.Label>Quantos veículos possuem abertura de tampa traseira horizontal?</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={"Preencha com a quantidade de veículos que possuem abertura de tampa traseira horizontal..."}
                        onChange={e => setVehicleBackdoorHorizontal(e.target.value)}
                        autoComplete="off"
                        value={vehicleBackdoorHorizontal}
                        disabled={inputDisabled()}
                        required={true}
                      />
                    </Form.Group>
                  </Col>

                  <Col className="col-12">
                    <Form.Group controlId="form-vehicle-backdoor-vertical">
                      <Form.Label>Quantos veículos possuem abertura de tampa traseira vertical?</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={"Preencha com a quantidade de veículos que possuem abertura de tampa traseira vertical..."}
                        onChange={e => setVehicleBackdoorVertical(e.target.value)}
                        autoComplete="off"
                        value={vehicleBackdoorVertical}
                        disabled={inputDisabled()}
                        required={true}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </RowBox>

              <Row className="row">
                <Col className="col">
                  <Form.Group controlId="forms-contact-message">
                    <Form.Label>Deseja deixar alguma observação?</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="10"
                      placeholder="Preencha com a observação que deseja deixar..."
                      onChange={e => setMessage(e.target.value)}
                      value={message}
                      disabled={inputDisabled()}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="row">
                <Col className="col">
                  {
                    response !== null ? (
                      <Alert variant={response ? "success" : "danger"}>{responseMessage}</Alert>
                    ) : null
                  }
                </Col>
              </Row>
              <Row className="row">
                <Col className="col">
                  <Form.Group className="default-form-button">
                    <Button
                      variant="dark"
                      type="submit"
                      disabled={buttonDisabled()}
                    >
                      {running ? "Aguarde..." : "Enviar"}
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </ColBox>
        </Row>
      </Container>

    </Container>
  );
};

export default Budget;