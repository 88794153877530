import React from "react";
import SEO from "./../SEO";
import {
  Container
} from "react-bootstrap";
import {
  ButtonBudget,
  Row,
  RowBox,
  Col,
  ColFlex,
  SubTitle,
  Title,
  Description,
  DescriptionIcon
} from "./styles";

import AnimationBudget from "../Animation/Budget";
import AnimationClock from "../Animation/Clock";
import AnimationScissor from "../Animation/Scissor";
import ButtonLink from "../ButtonLink";
import FeatureIcon from "./../FeatureIcon";
import MockupImac from "../MockupImac";
import MockupIphone from "../MockupIphone";
import MockupMacbook from "../MockupMacbook";
import TitleRow from "./../TitleRow";

const Schedules = () => {
  return (
    <Container className="max-w-3000" fluid>
      <SEO options={{
        tags: {
          title: `Agendamentos - ${process.env.REACT_APP_APP_TITLE}`,
          description: `Sistema para agendamentos e gestão de barbearias e salões de beleza.`,
          ogDescription: `Sistema para agendamentos e gestão de barbearias e salões de beleza.`
        }
      }} />

      <TitleRow
        title={
          <>
            Agendamentos
          </>
        }
        description={
          <>
            Sistema para agendamentos e gestão de barbearias e salões de beleza.
          </>
        }
      />

      <Container>
        <RowBox>
          <Row className="row">
            <Col>
              <Title>Gestão completa.</Title>
              <Description>
                O sistema inclui uma completa plataforma Web para gestão do seu estabelecimento e um APP exclusivo com o nome e a logomarca do seu salão ou barbearia que ficará
                disponível para as plataformas iOS e Android, para conquistar clientes de ambos sistemas operacionais!.
              </Description>
            </Col>
          </Row>
        </RowBox>
      </Container>

      <Container>
        <RowBox background="transparent">
          <Row className="row">
            <ColFlex col={4}>
              <DescriptionIcon>
                <AnimationScissor />
              </DescriptionIcon>
            </ColFlex>
            <ColFlex col={8}>
              <Description>
                O aplicativo de agendamentos e a plataforma web para gerenciamento de salões e barbearias da Three Pixels Sistemas é personalizado conforme nome, cores, fotos
                e identidade visual do seu negócio.
              </Description>
            </ColFlex>
          </Row>
        </RowBox>
      </Container>

      <Container>
        <RowBox>
          <Row className="row">
            <ColFlex col={8}>
              <Description>
                Nele você cadastra todos os profissionais do seu estabelecimento, os serviços e seus valores. Desta forma seu tempo com atendimento ao cliente é otimizado, diminuindo o número
                de contatos via WhatsApp e tempo perdido consultando agenda e informando valores.
              </Description>
            </ColFlex>
            <ColFlex col={4}>
              <DescriptionIcon>
                <AnimationClock />
              </DescriptionIcon>
            </ColFlex>
          </Row>
        </RowBox>
      </Container>

      <Container>
        <Row>
          <ColFlex>
            <ButtonLink as={ButtonBudget} href="https://api.whatsapp.com/send?phone=5549989237375&text=Ol%C3%A1" target="_blank" rel="noopener noreferrer">Orçamento</ButtonLink>
          </ColFlex>
        </Row>
      </Container>

      <Container>
        <MockupIphone
          align="left"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/schedules/iphone/large/1.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/schedules/iphone/medium/1.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/schedules/iphone/small/1.jpg)`
          }}
          title={
            <>
              Clientes.
            </>
          }
          description={
            <>
              Os clientes podem ser registrados no momento do agendamento por um perfil administrativo incluindo apenas o seu nome, ou ele mesmo
              pode fazer o seu cadastro utilizando os aplicativos.<br /><br />
              O registro pode ser feito pelo cliente preenchendo um formulário ou utilizando o login social por AppleID, Facebook ou Google.<br /><br />
              Caso este cliente tenha comandas a pagar ou créditos de pacotes, estas informações ficarão disponíveis para ele no seu perfil e também para os administradores.
            </>
          }
        />
      </Container>

      <Container>
        <MockupMacbook
          align="right"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/schedules/macbook/large/1.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/schedules/macbook/medium/1.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/schedules/macbook/small/1.jpg)`
          }}
          title={
            <>
              Profissionais.
            </>
          }
          description={
            <>
              Cada profissional pode ser registrado no sistema WEB com seus dados e serviços que realiza, também é possível escolher se este profissional vai estar disponível para agendamento direto pelos aplicativos ou somente internamente pelo perfil administrador.<br /><br />
              Para cada profissional é feito um cadastro de expediente, com os dias da semana que ele trabalha e também com o horário de entrada e saída, incluindo horário de intervalo, se houver.
            </>
          }
        />
      </Container>

      <TitleRow
        title={
          <>
            <i className="fa-solid fa-calendar-days" /> Agenda
          </>
        }
        description={
          <>
            Cada profissional possui sua agenda e sua programação, podendo trabalhar em horários diferentes durante cada dia da semana, incluindo horário de entrada, saída e tempo de intervalo.
          </>
        }
      />

      <Container>
        <MockupImac
          align="right"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/schedules/macbook/large/2.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/schedules/macbook/medium/2.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/schedules/macbook/small/2.jpg)`
          }}
          title={
            <>
            </>
          }
          description={
            <>
              <strong>Agendamentos ajustáveis:</strong> Não perca tempo entre seus serviços! Nosso algoritmo otimiza os horários dos agendamentos para sempre procurar um horário livre disponível.<br /><br />
              <strong>Agendamentos flexíveis:</strong> Nós sabemos que todo cliente é único, alguns serviços demoram menos e outros mais, conforme o cliente, por isso você pode estar tanto aumentando como diminuindo o tempo de serviço para um atendimento em específico.
            </>
          }
        />
      </Container>

      <Container style={{ marginBottom: "100px" }}>
        <MockupIphone
          align="left"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/schedules/iphone/large/2.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/schedules/iphone/medium/2.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/schedules/iphone/small/2.jpg)`
          }}
          title={
            <>
            </>
          }
          description={
            <>
              <strong>Alteração de status:</strong> Seu cliente e o profissional responsável serão notificados sempre que houver um novo agendamento, troca de horário (não disponível para o cliente), cancelamento, conclusão, entre outros.<br /><br />
              <strong>Bloqueios:</strong> Além de cada profissional ter seu horário individual, também possuímos a ferramenta de bloqueio de horários, no caso de eventos extraordinários, férias ou recessos. O profissional pode efetuar o bloqueio do seu horário por um período e repetir ele em outros dias da semana caso necessário de uma forma simples e prática.
            </>
          }
        />
      </Container>

      <TitleRow
        title={
          <>
            <i className="fa-solid fa-scissors" /> Categorias e Serviços
          </>
        }
        description={
          <>
            Autonomia total para criar categorias e serviços conforme sua forma de trabalhar.
          </>
        }
      />

      <Container>
        <MockupMacbook
          align="right"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/schedules/macbook/large/3.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/schedules/macbook/medium/3.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/schedules/macbook/small/3.jpg)`
          }}
          title={
            <>
            </>
          }
          description={
            <>
              Cada serviço criado deve se enquadrar a uma categoria, para que o usuário tenha mais facilidade na busca do que está procurando.<br /><br />
              <strong>Tempo de atendimento:</strong> É necessário um tempo estipulado para cada atendimento, para que nosso algoritmo possa ajustar sua agenda conforme os agendamentos são realizados, mas esse tempo pode ser alterado no momento do agendamento conforme a necessidade.
            </>
          }
        />
      </Container>

      <Container style={{ marginBottom: "100px" }}>
        <MockupIphone
          align="left"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/schedules/iphone/large/3.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/schedules/iphone/medium/3.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/schedules/iphone/small/3.jpg)`
          }}
          title={
            <>
            </>
          }
          description={
            <>
              <strong>Range de preços:</strong> É necessário informar o valor de cada serviço, entretanto nós sabemos que alguns serviços variam de acordo com o cliente, então liberamos também um preço mínimo e máximo, isso deixa o cliente ciente de que o valor final deverá estar entre esses dois valores e será decidido no atendimento e também é possível ocultar o valor para os aplicativos.<br /><br />
              Você pode ter serviços não disponíveis aos clientes pelo app, serviços esses de acesso somente ao perfil administrativo, caso isso seja necessário.<br /><br />
              <strong>Comissões:</strong> Cada serviço possui seu percentual ou valor fixo de comissão.
            </>
          }
        />
      </Container>

      <TitleRow
        title={
          <>
            <i className="fa-solid fa-box-open" /> Pacotes
          </>
        }
        description={
          <>
            Sabemos que alguns clientes preferem adquirir pacotes de serviços, sendo assim disponibilizamos a criação dos mesmos pelo sistema WEB, os pacotes incluem a possibilidade de múltiplos serviços e com quantidades e valores customizáveis.
          </>
        }
      />

      <Container>
        <MockupImac
          align="right"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/schedules/macbook/large/4.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/schedules/macbook/medium/4.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/schedules/macbook/small/4.jpg)`
          }}
          title={
            <>
            </>
          }
          description={
            <>
              Os pacotes são lançados para os clientes por meio de comandas de crédito de pacote, são faturados apenas no momento que são lançados como crédito. No momento da criação de um novo agendamento para um cliente que possui algum crédito de pacote, os serviços ficam destacados com a quantidade de crédito e são debitados quando o atendimento é finalizado e faturado por meio de uma comanda normal.
            </>
          }
        />
      </Container>

      <TitleRow
        title={
          <>
            <i className="fa-solid fa-store" /> Produtos
          </>
        }
        description={
          <>
            Muito clientes preferem comprar os produtos diretamente no local, então é possível incluir os produtos juntamente com os serviços na mesma comanda e também pré-definir uma comissão para a venda desses produtos.<br /><br />
          </>
        }
      />

      <Container>
        <MockupMacbook
          align="right"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/schedules/macbook/large/5.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/schedules/macbook/medium/5.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/schedules/macbook/small/5.jpg)`
          }}
          title={
            <>
            </>
          }
          description={
            <>
              Cadastro de produtos disponíveis para venda:<br /><br />
              Inserir o valor dos produtos e a comissão, cada produto possui seu percentual ou valor fixo de comissão para quem vende-lo.
            </>
          }
        />
      </Container>

      <TitleRow
        title={
          <>
            <i className="fa-solid fa-brazilian-real-sign" /> Financeiro
          </>
        }
        description={
          <>
            Todo o calculo dos valores relacionados a serviços por agendamento e vendas de produtos é gerado automaticamente pelo nosso sistema financeiro.
          </>
        }
      />

      <Container>
        <MockupMacbook
          align="left"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/schedules/macbook/large/6.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/schedules/macbook/medium/6.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/schedules/macbook/small/6.jpg)`
          }}
          title={
            <>
            </>
          }
          description={
            <>
              O sistema faz o cálculo dos seus recebimentos totais, também separando entre serviços e produtos.<br /><br />
              <strong>Vales e abatimentos:</strong> É de costume, no decorrer do mês, os profissionais solicitarem vales para resgatarem antecipadamente o valor da sua renda mensal. Pensando nisso, você pode gerar vales para abatimento no valor da comissão de um ou mais profissionais durante o mês, esses vales serão descontados no valor de recebimento de suas comissões no fechamento do mês.
            </>
          }
        />
      </Container>

      <Container>
        <MockupMacbook
          align="right"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/schedules/macbook/large/7.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/schedules/macbook/medium/7.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/schedules/macbook/small/7.jpg)`
          }}
          title={
            <>
            </>
          }
          description={
            <>
              <strong>Calculo de comissões por profissional:</strong> Nosso algoritmo faz o cálculo de todas as comissões de serviço realizado ou venda de produto individualmente para cada profissional. Mostrando detalhadamente o valor do serviço/produto, percentual de comissão e valor a ser pago. Em caso de vales e abatimentos, o desconto é feito automaticamente e também entra no detalhamento. Existe também a possibilidade de dividir as comissões entre dois ou mais colaboradores em serviços que são realizados com ajudantes.
            </>
          }
        />
      </Container>

      <Container>
        <MockupMacbook
          align="left"
          background={{
            lg: `url(${process.env.PUBLIC_URL}/images/schedules/macbook/large/8.jpg)`,
            md: `url(${process.env.PUBLIC_URL}/images/schedules/macbook/medium/8.jpg)`,
            xs: `url(${process.env.PUBLIC_URL}/images/schedules/macbook/small/8.jpg)`
          }}
          title={
            <>
            </>
          }
          description={
            <>
              <strong>Cálculo de saldo do cliente:</strong> O sistema financeiro faz um cálculo de todo o histórico do cliente com as comandas fechadas e disponibiliza o saldo do cliente, em caso do cliente ter pago a mais o valor de alguma comanda, essa diferença fica em crédito no saldo, em caso do cliente ter pago a menos o valor fica em débito.
            </>
          }
        />
      </Container>

      <Container>
        <RowBox>
          <Row className="row">
            <Col className="col">
              <SubTitle>Incluso no sistema.</SubTitle>
            </Col>
          </Row>
          <Row className="row">
            <Col className="col-12 col-md-6">
              <FeatureIcon
                icon={<i className="fa-solid fa-calendar-days" />}
                title={"Agenda."}
                description={
                  <>
                    Agenda completa de todos os profissionais.
                  </>
                }
              />
            </Col>
            <Col className="col-12 col-md-6">
              <FeatureIcon
                icon={<i className="fa-solid fa-brazilian-real-sign" />}
                title={"Financeiro."}
                description={
                  <>
                    Controle financeiro das comandas e comissões.
                  </>
                }
              />
            </Col>
          </Row>
          <Row className="row">
            <Col className="col-12 col-md-6">
              <FeatureIcon
                icon={<i className="fa-solid fa-users" />}
                title={"Clientes."}
                description={
                  <>
                    Acesso a todos os clientes, seu histórico de agendamentos e controle financeiro.
                  </>
                }
              />
            </Col>
            <Col className="col-12 col-md-6">
              <FeatureIcon
                icon={<i className="fa-solid fa-scissors" />}
                title={"Serviços e Pacotes."}
                description={
                  <>
                    Gerenciamento dos serviços disponíveis, preços, comissões, categorias e pacotes.
                  </>
                }
              />
            </Col>
          </Row>
          <Row className="row">
            <Col className="col-12 col-md-6">
              <FeatureIcon
                icon={<i className="fa-solid fa-store" />}
                title={"Produtos."}
                description={
                  <>
                    Gerenciamento dos produtos disponíveis, preços, comissões e categorias.
                  </>
                }
              />
            </Col>
            <Col className="col-12 col-md-6">
              <FeatureIcon
                icon={<i className="fa-solid fa-person" />}
                title={"Profissionais e Expedientes."}
                description={
                  <>
                    Gerenciamento dos profissionais, seus expedientes e os serviços que atendem.
                  </>
                }
              />
            </Col>
          </Row>
          <Row className="row">
            <Col className="col-12 col-md-6">
              <FeatureIcon
                icon={<i className="fa-solid fa-bell" />}
                title={"Notificações."}
                description={
                  <>
                    Notificações de lembrete enviadas para os clientes com agendamento.
                  </>
                }
              />
            </Col>
            <Col className="col-12 col-md-6">
              <FeatureIcon
                icon={<i className="fa-solid fa-location-dot" />}
                title={"Localização."}
                description={
                  <>
                    Mapa com localização até o estabelecimento com exportação para Google, Apple e Waze.
                  </>
                }
              />
            </Col>
          </Row>
        </RowBox>
      </Container>

      <Container>
        <RowBox>
          <Row>
            <Col style={{ margin: "0 0 auto 0" }}>
             <FeatureIcon
                align="center"
                paddingDescription="0 15px"
                title={
                  <>
                    Temos a solução para facilitar a gestão do seu salão de beleza ou sua barbearia.
                  </>
                }
              />
              <Row>
                <Col>
                  <AnimationBudget height="500px" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <ButtonLink as={ButtonBudget} href="https://api.whatsapp.com/send?phone=5549989237375&text=Olá, tenho interesse no sistema de agendamentos para salão de beleza ou barbearia." target="_blank" rel="noopener noreferrer">Orçamento</ButtonLink>
                </Col>
              </Row>
            </Col>
          </Row>
        </RowBox>
      </Container>

    </Container>
  );
};

export default Schedules;
